import React from "react";
import "../style/contactPage.css";
import { Link } from "react-router-dom";

export default function ContactPage(props) {
  return (
    <div className="m-contact">
      <img className="m-logo" src="images/logo.png" alt="" />
      <div className="m-subtitle">
        Estamos aquí para ayudarte
        <br />
        Si tienes cualquier duda, ¡contáctanos!
      </div>
      <a href="mailto:rewrite@gmail.com" className="m-mail">
        rewrite@gmail.com
      </a>
      <div className="m-text">
        <p>Esta aplicación móvil ha sido financiada por el Instituto Universitario de Educación a
           Distancia (IUED) en el marco de los Proyectos de Innovación Docente. Ha 
           sido diseñada por los siguientes miembros, en colaboración con <Link to="https://www.dabasystem.com/" target="_blank" rel="noopener noreferrer">Dabasystem</Link>:
        </p>
        <ul>
          <li>Imelda Katherine Brady</li>
          <li>Aurelia Carranza Márquez</li>
          <li>Iria da Cunha Fanego</li>
          <li>Marina del Olmo Gómez</li>
          <li>María Ángeles Escobar Álvarez</li>
          <li>Sara Pistola Grille</li>
          <li>Elena Romea Parente</li>
          <li>Antonio Rosso Ponce</li>
          <li>Silvia Sánchez Calderón</li>
        </ul>
        <p>©</p>
      </div>
    </div>
  );
}
