import React, { Component } from "react";
import { Link } from "react-router-dom";
import Result from "./Result.jsx";
import "../style/exercices.css";
import { success, fail } from "../utils/coursesUtils.js";

class Ex1popup extends Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();

    this.state = {
      text: this.props.el.text,
      formattedText: null,
      correctOptions: this.props.el.correctOptions,
      activeOptionId: null,
      showPopup: false,
      result: false,
      finished: false,
      showButton: false,
      soundType: "",
    };
  }

  componentDidMount() {
    //! no tienen nombre declarativo los ejercicios
    /* ReactGA.event({
      category: 'Usuario',
      action: `Accede a Ejercicio: Géneros textuales`,
    }); */

    this.convertText(this.state.text);
  }

  convertText = (text) => {
    let idCounter = 1;
    const formattedText = text?.replace(/_______/g, () => {
      const spanId = `missingWord${idCounter}`;
      idCounter++;
      return `<span
        id="${spanId}"
        class="m-missingWord ${this.state.result ? "m-correct" : ""}"
      >
        _______
      </span>`;
    });

    this.setState({ formattedText: formattedText });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showPopup !== this.props.showPopup) {
      this.addSpanOnClicks();
    }

    // if (prevState.soundType !== this.state.soundType) {
    //   // Actualiza la referencia al elemento de audio cuando cambia soundType
    //   this.audioRef.current.src = `/sounds/${this.state.soundType}`;
    // }
  }

  addSpanOnClicks = () => {
    const missingArray = document.getElementsByClassName("m-missingWord");
    Array.from(missingArray).forEach((span, i) => {
      span.addEventListener("click", this.showPopupMissingWord);
    });
  };

  showPopupMissingWord = () => {
    this.setState({ showPopup: true });
  };

  hidePopupMissingWord = (selectedOption) => {
    this.setState({ activeOptionId: parseInt(selectedOption.id) });
    const optionsArray = selectedOption.options.split(", ");
    optionsArray.forEach((option, i) => {
      const missingWordElement = document.getElementById(`missingWord${i + 1}`);

      if (missingWordElement) {
        missingWordElement.textContent = option;
      }
    });

    this.setState({
      showPopup: false,
      showButton: true,
    });
  };

  checkResults = () => {
    const { correctOptions } = this.props.el;
    const correctArray = correctOptions.split(", ");

    const result = correctArray.every((option, i) => {
      const missingWordElement = document.getElementById(`missingWord${i + 1}`);

      if (option === missingWordElement.textContent) {
        missingWordElement.classList.add("m-correct");
        return true;
      } else {
        missingWordElement.classList.add("m-incorrect");
        return false;
      }
    });

    this.setState({ result, finished: true }, () => {
      // Después de actualizar el estado, llama a la función en función de result
      if (result) {
        success(this);
      } else {
        fail(this);
      }

      const body = document.querySelector("#content");
      body.scrollTo(0, 0);
    });
  };

  render() {
    const { el, genre } = this.props;
    const { result, finished, showPopup, showButton } = this.state;

    return (
      <div>
        <div className="m-breadcrumb">
          <div className="m-genre">{genre}</div>
          <div className="m-exTitle">{el.typeName}</div>
        </div>
        <div className={`m-exercice ${finished ? "finished" : ""}`} data-type="completeText">
          {finished && <Result result={result} />}
          {el.title && <div className="m-title" dangerouslySetInnerHTML={{ __html: el.title }}></div>} {el.subtitle && <div className="m-subtitle" dangerouslySetInnerHTML={{ __html: el.subtitle }}></div>}
          {el.title2 && <div className="m-title" dangerouslySetInnerHTML={{ __html: el.title2 }}></div>}
          <div className="m-text m-completeText" dangerouslySetInnerHTML={{ __html: this.state.formattedText }}></div>
          {el.font && (
            <div className="m-font">
              FUENTE DEL TEXTO{el.adaptedSRC && <span> (adaptado)</span>}:{" "}
              <a href={el.font} target="_blank" rel="noreferrer">
                {el.font}
              </a>
            </div>
          )}
          <div className="m-buttons">
            {finished ? (
              <Link to="/exercices">
                <button className="m-button" id="buttonBack">
                  <span>vuelve a las actividades</span>
                </button>
              </Link>
            ) : (
              <button className={`m-button ${!showButton && "disabled"}`} onClick={this.checkResults}>
                <span>Continúa</span>
              </button>
            )}
          </div>
          {showPopup && (
            <div className="m-popupOptions">
              <div className="m-modal">
                <ol className="m-modalOptions" type="a">
                  {el.list.map((item, index) => (
                    <li key={index} onClick={() => this.hidePopupMissingWord(item)}>
                      {item.options}
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          )}
          <audio ref={this.audioRef} src=""></audio>
        </div>
      </div>
    );
  }
}

export default Ex1popup;
