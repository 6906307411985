import axios from "axios";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const authApi = axios.create({
  baseURL: `${apiEndpoint}api-user`,
  /* withCredentials: true, */
});

export const login = (user) => authApi.post("/login", user);

export const signup = (user) => authApi.post("/register-user", user);

export const logout = () => authApi.post("/logout");

export const getUser = () => authApi.get("/");
