export const data = [
  {
    id: "1",
    menu: 1,
    title: "Artículo periodístico",
    options: [
      { tutorial: "643fe33d3056d537986eb6a1" },
      { goldText: "articulo_periodistico" },
    ],
    exercices: [
      {
        adaptedSRC: false,
        title:
          "Ordena de manera lógica las imágenes correspondientes a la estructura prototípica de un artículo periodístico. Para ello, deberás arrastrar cada párrafo según el orden lógico del texto para su correcta lectura.",
        subtitle: null,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",
        list: [
          {
            id: 1,
            order: 3,
            text: "<p>The birth rate in 2022 was also down from 7.52 in 2021, according to China's National Bureau of Statistics, which released the figures on Tuesday.</p><br/><br/><p>In comparison, in 2021, the United States recorded 11.06 births per 1,000 people, and the United Kingdom, 10.08 births. The birth rate for the same year in India, which is poised to overtake China as the world's most populous country, was 16.42.</p>",
          },
          {
            id: 2,
            order: 5,
            text: `<p>"This trend is going to continue and perhaps worsen after Covid," says Yue Su, principal economist at the Economist Intelligence Unit. Ms Su is among experts who expect China's population to shrink further through 2023.</p>`,
          },
          {
            id: 3,
            order: 2,
            text: `<p>China's birth rate has been declining for years, prompting a slew of policies to try to slow the trend.</p><br/><p>But seven years after scrapping the one-child policy, it has entered what one official described as an "era of negative population growth".</p>`,
          },
          {
            id: 4,
            order: 4,
            text: `<p>Earlier government data had heralded a demographic crisis, which would in the long run shrink China's labour force and increase the burden on healthcare and other social security costs.</p>`,
          },
          {
            id: 5,
            order: 1,
            text: "<h3>China's population has fallen for the first time in 60 years, with the national birth rate hitting a record low - 6.77 births per 1,000 people.</h3>",
          },
          {
            id: 6,
            order: 0,
            text: "<h1>China's population falls for first time since 1961</h1>",
          },
          {
            id: 7,
            order: 6,
            text: "<p>China's population trends over the years have been largely shaped by the controversial one-child policy, which was introduced in 1979 to slow population growth. Families that violated the rules were fined and, in some cases, even lost jobs. In a culture that historically favours boys over girls, the policy had also led to forced abortions and a reportedly skewed gender ratio from the 1980s.</p>",
          },
          {
            id: 8,
            order: 7,
            text: `<p>Observers also say merely raising birth rates will not resolve the problems behind China's slowing growth. </p>
        <br/><p>"Boosting fertility is not going to improve productivity or increase domestic consumption in the medium term," said Stuart Gietel-Basten, a public policy professor at The Hong Kong University of Science and Technology.</p><br/><p>"How China will respond to these structural issues would be more crucial."</p>`,
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "https://english.elpais.com/international/2023-01-15/68-confirmed-dead-after-plane-crashes-in-nepal-resort-town.html",
        title:
          "Selecciona la respuesta correcta (a, b, c, d) haciendo clic en los huecos que faltan en este artículo periodístico. Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<h1>68 confirmed dead, 4 _______ after plane crashes in Nepal resort town</h1>
        <h3>&ldquo;The _______ were so hot that we couldn't go near the _______. I heard a man crying for help, but because of the flames and smoke we couldn't help him,'' a resident said.</h3>
        <p>As search efforts ended for the day, 68 people were _______ dead after a regional passenger plane with 72 aboard crashed into a gorge while landing at a newly opened airport in the resort town of Pokhara Sunday, according to Nepal's Civil Aviation Authority. It's the country's deadliest airplane accident in three decades.</p>
        <p>Scores of rescue workers and onlookers crowded near a steep gorge hours after dark Sunday, as rescuers combed the wreckage on the _______ of the cliff and in the ravine below. Officials suspended the search for the four missing people overnight and will resume looking Monday.</p>
        <p>Local resident Bishnu Tiwari, who rushed to the _______ near the Seti River to help search for bodies, said the rescue efforts were _______ by thick smoke and a raging fire. &ldquo;The flames were so hot that we</p>`,
        title2: null,
        type: "popups",
        typeName: "Léxico",
        list: [
          {
            id: 1,
            options: "flames, missing, hampered, edge",
            correct: "missing",
            selected: "",
          },
          {
            id: 2,
            options: "confirmed, flames, wreckage, missing",
            correct: "flames",
            selected: "",
          },
          {
            id: 3,
            options: "crash site, wreckage, edge, flames",
            correct: "wreckage",
            selected: "",
          },
          {
            id: 4,
            options: "missing, confirmed, edge, flames",
            correct: "confirmed",
            selected: "",
          },
          {
            id: 5,
            options: "hampered, edge, crash site, missing",
            correct: "edge",
            selected: "",
          },
          {
            id: 6,
            options: "wreckage, crash site, flames, hampered",
            correct: "crash site",
            selected: "",
          },
          {
            id: 7,
            options: "edge, hampered, confirmed, missing",
            correct: "hampered",
            selected: "",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.washingtonpost.com/weather/2023/01/14/california-flooding-atmospheric-river/",
        title:
          "Selecciona la respuesta correcta (a, b, c, d). En base al texto introductorio de este artículo periodístico donde los verbos de cada oración están construidos en voz activa, ¿cómo se podrían reescribir las oraciones de este texto en voz pasiva?",
        subtitle:
          "A flood watch Saturday covered nearly 26 million people in California, mostly between San Francisco and Los Angeles, As fresh rain and storms move through the state, bringing fresh flooding, mudslides and traffic snarls to areas where severe weather exhausts in recent weeks.The authorities ordered new emergency evacuations in some low-lying parts of Santa Cruz County, where the waves of rains have hit particularly hard.",
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 2,
        list: [
          {
            id: 0,
            text: "a) A flood watch Saturday has been covered by nearly 26 million people in California, mostly between San Francisco and Los Angeles, as fresh rain and storms have been moved through the state, bringing fresh flooding, mudslides and traffic snarls to areas where severe weather will be exhausted in recent weeks. The authorities ordered new emergency evacuations in some low-lying parts of Santa Cruz County, where the waves of rains have hit particularly hard.",
          },
          {
            id: 1,
            text: "b) Nearly 26 million people in California are covered by a flood watch Saturday, mostly between San Francisco and Los Angeles, as fresh rain and storms were moved through the state, bringing fresh flooding, mudslides and traffic snarls to areas exhausted by severe weather in recent weeks. New emergency evacuations are ordered in some low-lying parts of Santa Cruz County, which has been hit particularly hard by the waves of rains",
          },
          {
            id: 2,
            text: "c) Nearly 26 million people in California were covered by a flood watch Saturday, mostly between San Francisco and Los Angeles, as fresh rain and storms move through the state, bringing fresh flooding, mudslides and traffic snarls to areas exhausted by severe weather in recent weeks. New emergency evacuations were ordered in some low-lying parts of Santa Cruz County, which has been hit particularly hard by the waves of rains.",
          },
          {
            id: 3,
            text: "d) A flood watch Saturday is covered by nearly 26 million people in California, mostly between San Francisco and Los Angeles,as fresh rain and storms move through the state, bringing fresh flooding, mudslides and traffic snarls to areas where severe weather were exhausted in recent weeks. The authorities will order new emergency evacuations in some low-lying parts of Santa Cruz County, where the waves of rains have been hit particularly hard.",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://english.elpais.com/culture/2023-01-16/human-skulls-tudor-shoes-a-history-of-london-through-objects-found-in-the-mud-of-the-thames.html",
        title:
          "Selecciona la respuesta correcta (a, b, c, d) haciendo clic en los huecos que faltan en este artículo periodístico. Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<p>
          Mudlarking has historically been an activity performed by the lower classes of society. “It has existed since there were people so poor as to go looking for
          what other people throw away. The term began to be used at the end of the 18th century, _______ people who lived on the fringes of society. It was a way
          of survival, _______ it has become a hobby,” says the author, who has popularized the activity on social media under the name The London Mudlark. Her
          book is also a reflection on the history of the city _______ “a love letter” to the river: “It’s a beautiful ugly place, like all of London, which is a bit of a
          badass,” she jokes.
        </p>
        <p>
          Roman bottle stoppers, 19th-century movable type made from lead (the bookbinder T. J. Cobden-Sanderson dumped 500,000 pieces into the river in the
          Hammersmith area), Tudor-era bricks, a medieval pilgrim’s band, a 16th-century sword, a container from the Iron Age, the Thames is the longest
          archaeological bed in England: thousands of objects kept in museums come from its banks. _______, the famous Battersea Shield, a Celtic bronze piece
          dated between 350 and 50 BC, now kept in the British Museum. _______, would-be mudlarks are advised to consult old maps and go where there is, or has
          at some point been, human activity: warehouses, docks, workshops, bridges or wharves. _______it is also common to find items of little value associated
          with everyday life: iron chains, wooden bowls, the handle of a copper frying pan, beads, keys, nails, pieces of string or the pegs of a musical instrument.
          “Personally, this list transports me to other times, and at the same time it is very familiar to me,” writes Maiklem, who in 2022 was elected a fellow of the
          Society of Antiquaries of London. _______, in the mud of the Thames, the mudlarkers uncover the history of average people – not of kings, dynasties or
          great military campaigns, but of the ordinary citizens trying to survive by the river in an increasingly crazy city.
        </p>`,
        title2: null,
        type: "popups",
        typeName: "Recursos discursivos",
        list: [
          {
            id: 1,
            options: "for example, to start looking, thus, alluding to",
            correct: "alluding to",
            selected: "",
          },
          {
            id: 2,
            options: "but now, alluding to, for example, but now",
            correct: "but now",
            selected: "",
          },
          {
            id: 3,
            options: "that is why, as well as, but now, as well as",
            correct: "as well as",
            selected: "",
          },
          {
            id: 4,
            options: "alluding to, but now, as, for example",
            correct: "for example",
            selected: "",
          },
          {
            id: 5,
            options: "thus, that is why, as well as, to start looking",
            correct: "to start looking",
            selected: "",
          },
          {
            id: 6,
            options: "as well as, thus, to start looking, that is why",
            correct: "that is why",
            selected: "",
          },
          {
            id: 7,
            options: "to start looking, for example, that is why, thus",
            correct: "thus",
            selected: "",
          },
        ],
      },
    ],
  },
  {
    id: "2",
    menu: 1,
    title: "Carta de queja",
    options: [
      { tutorial: "643fe33d3056d537986eb6ab" },
      { goldText: "carta_de_queja" },
    ],
    exercices: [
      {
        adaptedSRC: false,
        font: "https://learnenglish.britishcouncil.org/skills/writing/b2-writing/letter-complaint",
        title:
          "Ordena de manera lógica las imágenes correspondientes a una carta de queja para su correcta lectura. Para ello, deberás arrastrar cada párrafo según el orden lógico de la estructura de una carta de queja prototípica.",
        subtitle: null,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",
        list: [
          {
            id: 1,
            order: 2,
            text: "<p>I often collect prescriptions from the pharmacy on behalf of my grandmother, Mrs Elaine Bingham. On this occasion there were two prescriptions: one for 10 x 50 mg Kendomol and one for 50 x 100 mg Leoprone. I was served quickly even though there appeared to be only one pharmacist on duty. However, as I was leaving I saw that I had been given 500 mg tablets of Kendomol. This is ten times stronger than the prescription called for.</p>",
          },
          {
            id: 2,
            order: 4,
            text: `<p>The pharmacist apologised and corrected the mistake but I wanted to bring it to your attention. I think it happened because there were not enough staff on duty. I understand that mistakes happen but there needs to be a minimum of two pharmacists at all times so all prescriptions can be checked.</p>`,
          },
          {
            id: 3,
            order: 5,
            text: `<p>I hope you can take steps to make sure this mistake does not happen again.</p>`,
          },
          {
            id: 4,
            order: 3,
            text: `<p>If I hadn't noticed the difference between the prescription and the actual tablets, my grandmother could have taken a dangerous overdose of Kendomol. I would be worried about getting any future prescriptions at Eden Hill.</p>`,
          },
          {
            id: 5,
            order: 6,
            text: "<p>Yours faithfully,</p></br/><p>Roger Bingham</p>",
          },
          {
            id: 6,
            order: 0,
            text: "<p>Dear Sir/Madam,</p>",
          },
          {
            id: 7,
            order: 1,
            text: "<p>I am writing to express my dismay at the service at your Eden Hill branch on Saturday 14 January.</p>",
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "https://shorturl.at/lBKO4",
        title:
          "Selecciona la respuesta correcta (a, b, c, d) haciendo clic en los huecos que faltan en esta carta de queja. Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<p>To Whom It May Concern,</p><br/>
        <p>I am writing today to complain of the poor service I received from your company on June 12, 2020. I _______ by a representative of That Awful Company, Mr. Madman, at my home that day.</p><br/>
        <p>Mr. Madman was one hour late for his appointment and offered nothing by way of apology when he arrived at noon. Your representative did not remove his muddy shoes upon entering my house and consequently left a trail of dirt in the hallway. Mr. Madman then proceeded to present a range of products to me that I had specifically told his assistant by telephone that I was not interested in. I _______ tried to ask your representative about the products that were of interest to me, but he refused to deal with my questions. We ended our meeting after 25 minutes without either of us having accomplished anything.</p><br/>
        <p>I am most annoyed that I _______ a morning (and half a day&apos;s vacation) waiting for Mr. Madman to show up. My impression of That Awful Company has been tarnished, and I am now concerned about how my existing business is being managed by your firm. Furthermore, Mr. Madman&apos;s inability to remove his muddy shoes has meant that I have had to engage the services, and incur the expense, of a professional carpet cleaner.</p><br/>
        <p>I trust this is not the way That Awful Company wishes to conduct business with _______ customers &mdash;I have been with you since the company was founded and have never encountered such treatment before. I would welcome the opportunity to discuss matters further and to learn how you propose to prevent a similar situation from recurring. I look forward to hearing from you.</p><br/>
        <p>Yours faithfully,</p><br/>
        <p>Deborah Payne</p>`,
        title2: null,
        type: "popups",
        typeName: "Léxico",
        list: [
          {
            id: 1,
            options: "visited, was visited, was called, was seen",
            correct: "was visited",
            selected: "",
          },
          {
            id: 2,
            options: "consequently, repeatedly, almost, only",
            correct: "repeatedly",
            selected: "",
          },
          {
            id: 3,
            options: "spent, wasted, lost, will waste",
            correct: "wasted",
            selected: "",
          },
          {
            id: 4,
            options: "your, valued, important, future",
            correct: "valued",
            selected: "",
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "https://shorturl.at/cdAK4",
        title:
          "Selecciona la respuesta correcta (a, b, c, d). En el siguiente fragmento extraído de una carta de queja, hay cuatro verbos en voz activa que originalmente estaban en voz pasiva. Identifica el fragmento original.",
        subtitle: null,
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 3,
        list: [
          {
            id: 0,
            text: "a) Secondly, although the brochure stated that all meals were included, I was shocked by the food that we served at the hotel restaurant. For lunch, we were given a small, squashed sandwich and a carton of juice. To make matters worse, when we complained to the tour guide, he told us to buy more food at the supermarket.",
          },
          {
            id: 1,
            text: " b) Secondly, although the brochure stated that all meals were included, I was shocked by the food that we were served at the hotel restaurant. For lunch, they gave us a small, squashed sandwich and a carton of juice. To make matters worse, when we complained to the tour guide, we were told to buy more food at the supermarket.",
          },
          {
            id: 2,
            text: " c) Secondly, although the brochure stated that all meals were included, I was shocked by the food that they served us at the hotel restaurant. For lunch, we were given a small, squashed sandwich and a carton of juice. To make matters worse, when we complained to the tour guide, he told us to buy more food at the supermarket.",
          },
          {
            id: 3,
            text: " d) Secondly, although the brochure stated that all meals were included, I was shocked by the food that we were served at the hotel restaurant. For lunch, we were given a small, squashed sandwich and a carton of juice. To make matters worse, when we complained to the tour guide, we were told to buy more food at the supermarket.",
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "https://www.lawebdelingles.com/nivel-b1/letteremail-of-complaint/",
        title:
          "Selecciona la respuesta correcta (a, b, c, d) haciendo clic en los huecos que faltan en esta carta de queja. Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<p>Dear Sir/ Madam,</p>
      <br/>
        <p>I am writing to express my strong dissatisfaction at the disgraceful treatment I received at the Walford Branco of Stimpson's Electronics yesterday afternoon.</p>  <br/>
        <p>_______, the product I was given was not the model I had asked for. The new X-401 calculator was demonstrated to me by the sales assistant, and I agreed to buy it. _______, on unpacking my purchase, I saw that I had been given the smaller X-201 model instead.</p>  <br/>
        <p>_______, this calculator was much cheaper than the model I requested and paid for. It did not have many of the features I needed and was much more basic than the one I was shown to begin with.</p>  <br/>
        <p>_______, I was deeply offended by the behaviour of the sales assistant when I went back to the shop to complain. He was _______ impolite, _______ unhelpful. He refused to contact the manager when I asked to speak to him about the incident.</p>  <br/>
        <p>As you can imagine, I am extremely upset, I must insist on a full refund, in addition to a written apology from the local manager, or else I shall be forced to take further action.</p>  <br/>
        <p>I expect to hear from you as soon as possible.</p>  <br/>
        <p>Yours faithfully,</p>  <br/>
        <p>Caroline Adams</p>`,
        title2: null,
        type: "popups",
        typeName: "Recursos discursivos",
        list: [
          {
            id: 1,
            options: "On the one hand, Firstly, In addition, First of all",
            correct: "Firstly",
            selected: "",
          },
          {
            id: 2,
            options: "On the other hand, However, Although, In addition",
            correct: "However",
            selected: "",
          },
          {
            id: 3,
            options: "Besides, Furthermore, However, On top of that",
            correct: "Furthermore",
            selected: "",
          },
          {
            id: 4,
            options: "However, To make matters worse, Also, Nevertheless",
            correct: "To make matters worse",
            selected: "",
          },
          {
            id: 5,
            options: "also very, not only, very much, not",
            correct: "not only",
            selected: "",
          },
          {
            id: 6,
            options: "and also, but also, also, and",
            correct: "but also",
            selected: "",
          },
        ],
      },
    ],
  },
  {
    id: "3",
    menu: 1,
    title: "Carta de solicitud de trabajo",
    options: [
      { tutorial: "643fe33c3056d537986eb69c" },
      { goldText: "solicitud_trabajo" },
    ],
    exercices: [
      {
        adaptedSRC: true,
        font: "https://english.washington.edu/writing-cover-letter",

        title:
          "Ordena de manera lógica los siguientes fragmentos de una carta de solicitud de trabajo para que pueda leerse correctamente. Para ello, deberás arrastrar cada párrafo según el orden lógico de la estructura de una carta de solicitud de trabajo prototípica.",
        subtitle: null,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",

        list: [
          {
            id: 1,
            order: 3,
            text: "<p>Dear Ms Everly:</p>",
          },
          {
            id: 2,
            order: 0,
            text: `<p>Mary Martin</p>
            <p>221 Peachtree Street</p>
            <p>Seattle, WA 98105</p>
            <p>(206) 555-5555</p>
            <p>mlmartin@aol.net</p>`,
          },
          {
            id: 3,
            order: 4,
            text: `<p>I am writing to express my keen interest in the editorial assistant position you advertised with the University of Washington's Career Center. I will be receiving my bachelor of arts degree in English in June 2012, and I am eager to join a small publishing house where I can use my skills in writing, editing, proofreading, research, and critical anaylsis. Based on my knowledge of Dickinson Press publications and objectives, I believe that my educational background and abilities would be an excellent match for the editorial position.</p>`,
          },
          {
            id: 4,
            order: 1,
            text: `<p>April 22, 2013</p>`,
          },
          {
            id: 5,
            order: 6,
            text: "<p>I hope you'll agree that the combination of my academic training and my internship work in publishing has provided me with excellent preparation for the demands of a literary editorial position with Dickinson Press. Thank you for your time and consideration. I look forward to meeting with you to discuss this opportunity in greater detail.</p>",
          },
          {
            id: 6,
            order: 7,
            text: "<p>Sincerely,</p>",
          },
          {
            id: 7,
            order: 2,
            text: `<p>Ms Stephanie Everly</p>
            <p>Managing Editor</p>
            <p>Dickinson Press</p>
            <p>12 Main Street</p>
            <p>Amherst, MA 11001</p>`,
          },
          {
            id: 8,
            order: 8,
            text: `<p>Mary L. Martin</p>`,
          },
          {
            id: 9,
            order: 5,
            text: `<p>Through my academic work in English language, literature, and writing, I am prepared to make meaningful contributions to editorial discussions and to function as a member of your editorial team. In addition to my university training, I have held editorial positions with Bricolage, the University of Washington's undergraduate literary journal, and with Steubing Press, a small publishing house specializing in non fiction and regional publications in the Pacific Northwest. These intern positions have provided me with experience in editing, proofreading, fact checking, production scheduling, working with off-site vendors, sales, marketing, and customer service. My positions with a small publication and a small press have taught me to manage my time effectively, adapt readily to new responsibilities, work as a team member, and function well under pressure. The writing skills I developed through my background as an English major have been further refined in both of these positions, where I learned to write concise, persuasive prose for press releases, catalog statements, and website content. Both positions afforded me an in-depth understanding of the important and varied behind-the-scenes work involved in book publishing.</p>`,
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.theguardian.com/careers/covering-letter-examples",
        title:
          "Selecciona la respuesta correcta (a, b, c, d) haciendo clic en los huecos que faltan en esta carta de solicitud de trabajo. Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<p>Mary Martin</p>
        <p>221 Peachtree Street</p>
        <p>Seattle, WA 98105</p>
        <p>(206) 555-5555</p>
        <p><a data-fr-linked="true" href="mailto:mlmartin@aol.net">mlmartin@aol.net</a></p>
        <p><br></p>
        <p class="m-left">March 20, 2020</p>
        <p><br></p>
        <p class="m-left">Ms. John Brown</p>
        <p class="m-left">CEO</p>
        <p class="m-left">Managing Solutions</p>
        <p class="m-left">12 Main Street</p>
        <p class="m-left">Amherst, MA 11001</p>
        <p><br></p>
        <p>Dear Mr. Brown,</p><br/>
        <p>I am writing to enquire if you have any _______ in your company. I enclose my CV for your information.</p><br/>
        <p>As you can see, I have had extensive work experience in office environments, the _______ and service industries, giving me varied _______ and the ability to work with many different types of people. I believe I could fit easily into your team.</p><br/>
        <p>I am a _______ person who works hard and pays attention to detail. I'm flexible, quick to pick up new skills and eager to learn from others. I also have lots of ideas and enthusiasm. I'm keen to work for a company with a great reputation and high profile like Managing Solutions.</p><br/>
        <p>I have excellent _______ and would be delighted to discuss any possible vacancy with you at your convenience. In case you do not have any _______ at the moment, I would be grateful if you would keep my CV on file for any future possibilities.</p><br/>

        <p>Yours sincerely,</p>
        <p>M. Martin</p>`,
        title2: null,
        type: "popups",
        typeName: "Léxico",
        list: [
          {
            id: 1,
            options: "vacancies, open, references, skills",
            correct: "vacancies",
            selected: "",
          },
          {
            id: 2,
            options: "retail sector, skills, services sector, sectors",
            correct: "retail sector",
            selected: "",
          },
          {
            id: 3,
            options: "skills, references, suitable, vacancies",
            correct: "skills",
            selected: "",
          },
          {
            id: 4,
            options: "conscientious, open, openings, suitable",
            correct: "conscientious",
            selected: "",
          },
          {
            id: 5,
            options: "references, reference, vacancies, possibilities",
            correct: "references",
            selected: "",
          },
          {
            id: 6,
            options:
              "suitable openings, workers, possibilities, suitable openings",
            correct: "suitable openings",
            selected: "",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.theguardian.com/careers/covering-letter-examples",

        title:
          "Selecciona la respuesta correcta (a, b, c, d). El siguiente párrafo es un fragmento de una carta de solicitud de trabajo. Todos los verbos que contiene están en presente simple. ¿Para qué se utiliza este tiempo verbal en este caso?",
        subtitle: `I <span class="m-underlined">am</span> a fast and accurate writer, with a keen eye for detail. I <span class="m-underlined">am</span> able to take on the responsibility of this position immediately, and <span class="m-underlined">have</span> the enthusiasm and determination to ensure that I <span class="m-underlined">make</span> a success of it.`,
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 1,
        list: [
          {
            id: 0,
            text: "a) Para introducir los motivos por los que se envía la carta.",
          },
          {
            id: 1,
            text: " b) Para narrar las cualificaciones del solicitante de empleo.",
          },
          {
            id: 2,
            text: " c) Para introducir citas textuales.",
          },
          {
            id: 3,
            text: " d) Para exponer hechos probados.",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://7esl.com/how-to-write-a-cover-letter/",

        title:
          "Selecciona la respuesta correcta (a, b, c, d) haciendo clic en los huecos que faltan en esta carta de solicitud de trabajo. Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<p>As mentioned previously, I have _______ completed a variety of management courses including the following:</p>
        <ul>
            <li>Advanced hospitality management</li>
            <li>Management in the hotel industry</li>
        </ul>
        <p>For both of these courses, I passed with merit and _______ have qualifications in IT and Telecommunications, _______ a Diploma in Spanish. I _______ worked within a 4 star establishment, and worked my way up from cleaner, to assistant manager. _______, my previous employer put me forward for the training which I have just completed.</p>`,
        title2: null,
        type: "popups",
        typeName: "Recursos discursivos",
        list: [
          {
            id: 1,
            options: "also, previously, as well as, recently",
            correct: "recently",
            selected: "",
          },
          {
            id: 2,
            options: "previously, recently, at that point, also",
            correct: "also",
            selected: "",
          },
          {
            id: 3,
            options: "at that point, also, recently, as well as",
            correct: "as well as",
            selected: "",
          },
          {
            id: 4,
            options: "recently, at that point, also, previously",
            correct: "previously",
            selected: "",
          },
          {
            id: 5,
            options: "As well as, Recently, Previously, At that point",
            correct: "At that point",
            selected: "",
          },
        ],
      },
    ],
  },
  {
    id: "4",
    menu: 1,
    title: "Carta informal",
    options: [
      { tutorial: "643fe33d3056d537986eb6a6" },
      { goldText: "carta_informal" },
    ],
    exercices: [
      {
        adaptedSRC: true,
        font: "https://www.inesem.es/revistadigital/idiomas/carta-informal-en-ingles/",
        title:
          "A continuación, verás el orden de los elementos de una carta informal. La lista siguiente incluye el orden correspondiente a una carta informal:",
        subtitle: `
        <ol><li>Dirección del receptor de la carta</li><li>Fecha</li><li>Saludo</li><li>Introducción: preguntas y comentarios de carácter personal</li><li>Cuerpo: motivo por el que se escribe la carta</li><li>Conclusión: resumen y petición de respuesta</li><li>Despedida</li>
        <li>Firma</li>
        </ol>
        `,
        title2:
          "Ordena de manera lógica cada uno de los párrafos que verás a continuación. Para ello, deberás arrastrarlos.",
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",

        list: [
          {
            id: 1,
            order: 4,
            text: `<p>Firstly, I have some exciting news to share with you. I recently got accepted into a master’s program at a university in the UK! I'm thrilled to have the opportunity to further my education and explore a new country. Of course, I’m also a bit nervous about the move, but overall,
 I'm really looking forward to this new adventure.</p><p> Aside from that, things have been pretty busy at work. We've been working on a new project, and I've been putting in a lot of extra hours to make sure everything runs smoothly. It's been challenging, but also really rewarding to see the project come together.</p>`,
          },
          {
            id: 2,
            order: 6,
            text: `<p>Take care,</p>`,
          },
          {
            id: 3,
            order: 0,
            text: `<p>Martha Stuart</p>
            <p>103 Fore Street</p>
            <p>London</p>
            <p>United Kingdom</p>`,
          },
          {
            id: 4,
            order: 1,
            text: `<p>February 16, 2022</p>`,
          },
          {
            id: 5,
            order: 5,
            text: "<p>What about you? What have you been up to lately? I'd love to hear all about it. Anyway, I won’t ramble on for too long. I just wanted to check in and say hello. Let's catch up properly soon, maybe over a video call or something? I miss our chats!</p>",
          },
          {
            id: 6,
            order: 7,
            text: "<p>Lisa</p>",
          },
          {
            id: 7,
            order: 2,
            text: `<p>Dear Martha,</p>`,
          },
          {
            id: 8,
            order: 3,
            text: `<p>How are you doing? I hope this letter finds you well. It feels like
            it's been ages since we last caught up. I thought I'd drop you a line
            to fill you in on what's been going on with me lately.</p>`,
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "https://www.inesem.es/revistadigital/idiomas/carta-informal-en-ingles/",
        title:
          "Selecciona la respuesta correcta (a, b, c, d) haciendo clic en los huecos que faltan en esta carta informal. Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<p>
          Of course, I’ll also _______ being away from home for a while. I’ll miss our chats and our family dinners. But I’ll be sure to _______
          while I'm away and send you plenty of updates and photos.
        </p>  <br/>
        <p>
          I just wanted to let you know how much I _______ your support and encouragement as I prepare for this trip. Your words of wisdom
          and your love _______ to me.
        </p><br/>
        <p>I’ll be sure to keep you posted on all my adventures. Take care of yourself and give my _______ to everyone.</p>`,
        title2: null,
        type: "popups",
        typeName: "Léxico",
        list: [
          {
            id: 1,
            options: "miss, love, missing, appreciate",
            correct: "miss",
            selected: "",
          },
          {
            id: 2,
            options: "love, keep in touch, miss, mean the world",
            correct: "keep in touch",
            selected: "",
          },
          {
            id: 3,
            options: "keep in touch, miss, appreciate, miss",
            correct: "appreciate",
            selected: "",
          },
          {
            id: 4,
            options: "miss, appreciate, mean the world, keep in touch",
            correct: "mean the world",
            selected: "",
          },
          {
            id: 5,
            options: "appreciate, mean the world, miss, love",
            correct: "love",
            selected: "",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.inesem.es/revistadigital/idiomas/carta-informal-en-ingles/",
        title:
          "Selecciona la respuesta correcta (a, b, c, d). En este fragmento de una carta informal, no se incluyen contracciones. ¿Cómo sería el fragmento si se incluyesen todas las contracciones posibles de forma correcta?",
        subtitle: null,
        text: null,
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 2,
        list: [
          {
            id: 0,
            text: "a) I'm feeling a mix of excitement and nerves about the trip. On the one hand, I cannot wait to explore Mumbai and immerse myself in the culture. On the other hand, I'm a bit anxious about navigating my way around a new place and trying to communicate in a language that is’nt my native tongue. I’ve been doing a lot of research about the city, and I'm really excited about all the things I’ll be able to see and do there. I’m especially looking forward to trying the local cuisine and visiting some historical landmarks.",
          },
          {
            id: 1,
            text: "b) I'm feeling a mix of excitement and nerves about the trip. On the one hand, I can’t wait to explore Mumbai and immerse myself in the culture. On the other hand, I'm a bit anxious about navigating my way around a new place and trying to communicate in a language that is not my native tongue. I have been doing a lot of research about the city, and I'm really excited about all the things I’l be able to see and do there. I’m especially looking forward to trying the local cuisine and visiting some historical landmarks.",
          },
          {
            id: 2,
            text: "c) I'm feeling a mix of excitement and nerves about the trip. On the one hand, I can’t wait to explore Mumbai and immerse myself in the culture. On the other hand, I'm a bit anxious about navigating my way around a new place and trying to communicate in a language that isn't my native tongue. I’ve been doing a lot of research about the city, and I'm really excited about all the things I’ll be able to see and do there. I’m especially looking forward to trying the local cuisine and visiting some historical landmarks.",
          },
          {
            id: 3,
            text: "d) I am feeling a mix of excitement and nerves about the trip. On the one hand, I can’t wait to explore Mumbai and immerse myself in the culture. On the other hand, I am a bit anxious about navigating my way around a new place and trying to communicate in a language that isn’t my native tongue. I’ve been doing a lot of research about the city, and I am really excited about all the things I’ll be able to see and do there. I am especially looking forward to trying the local cuisine and visiting some historical landmarks.",
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "https://www.inesem.es/revistadigital/idiomas/carta-informal-en-ingles/        ",
        title:
          "Selecciona la respuesta correcta (a, b, c, d) haciendo clic en los huecos que faltan en esta carta informal. Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<p>
          Firstly, I have some exciting news to share with you. I _______ got accepted into a master’s program at a university in the UK! I'm thrilled to have the
          opportunity to further my education and explore a new country. _______, I’m _______ a bit nervous about the move, _______, I'm
          really looking forward to this new adventure.
        </p><br/>
        <p>
        _______, things have been pretty busy at work. We've been working on a new project, and I've been putting in a lot of extra hours to make sure
          everything runs smoothly. It's been challenging, _______ really rewarding to see the project come together.
        </p>`,
        title2: null,
        type: "popups",
        typeName: "Recursos discursivos",
        list: [
          {
            id: 1,
            options: "recently, also, of course, aside from that",
            correct: "recently",
            selected: "",
          },
          {
            id: 2,
            options: "Of course, Recently, But also, But overall",
            correct: "Of course",
            selected: "",
          },
          {
            id: 3,
            options: "also, of course, recently, of course",
            correct: "also",
            selected: "",
          },
          {
            id: 4,
            options: "but overall, aside from that, recently, of course",
            correct: "but overall",
            selected: "",
          },
          {
            id: 5,
            options: "Aside from that, But overall, Also, Recently",
            correct: "Aside from that",
            selected: "",
          },
          {
            id: 6,
            options: "but also, of course, aside from that, also",
            correct: "but also",
            selected: "",
          },
          {
            id: 7,
            options: "edge, hampered, confirmed, missing",
            correct: "hampered",
            selected: "",
          },
        ],
      },
    ],
  },
  {
    id: "5",
    menu: 1,
    title: "Email formal",
    options: [
      { tutorial: "644a579b3056d50abd3db582" },
      { goldText: "email_formal" },
    ],
    exercices: [
      {
        adaptedSRC: true,
        font: "https://www.aplustopper.com/sample-inquiry-letters/",
        title:
          "Ordena los párrafos de forma coherente para la redacción de un email de solicitud de información. Para ello,deberás arrastrar cada uno de los párrafos según el orden lógico.",
        subtitle: null,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",
        list: [
          {
            id: 1,
            order: 4,
            text: `<p>I would be grateful if you let me know the details or forward your company brochure. If it is required I can meet you personally. You can contact me on 0123456789.
            </p>`,
          },
          {
            id: 2,
            order: 5,
            text: `<p>Thank you in advance for your help.</p><br/><p>Mary Ann Smith-Thomas</p>`,
          },
          {
            id: 3,
            order: 0,
            text: `<p>Subject: inquiry regarding the purchase of the real estate
            </p>`,
          },
          {
            id: 4,
            order: 1,
            text: `<p>Dear Mr. Park,
            </p>`,
          },
          {
            id: 5,
            order: 3,
            text: "<p>I am planning to buy a four-bedroomed apartment for investment purposes. My budget for the same would be around $75000. I would like to know if you have any project which is within my budget. Additionally, please let me know about any other option for investment purposes.</p>",
          },
          {
            id: 6,
            order: 2,
            text: "<p>This letter is with regard to an inquiry about your new project in your real estate.</p>",
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "",
        title:
          "Elige la forma más apropiada para la redacción de un email formal.",
        subtitle: null,
        title2: null,
        type: "multipleabc",
        typeName: "Léxico",
        exercices: [
          {
            id: 1,
            title: "Hi Sarah,",
            correct: [1],
            list: [
              {
                id: 0,
                text: "a) Hello Sarah,",
              },
              {
                id: 1,
                text: "b) Dear Sarah,",
              },
              {
                id: 2,
                text: "c) Hey Sarah,",
              },
              {
                id: 3,
                text: "d) How’s it going, Sarah?,",
              },
            ],
          },
          {
            id: 2,
            title: "It was great to chat earlier,",
            correct: [0, 1],
            list: [
              {
                id: 0,
                text: "a) It was lovely to speak to you on the phone earlier,",
              },
              {
                id: 1,
                text: "b) It was GREAT speaking to you earlier,",
              },
              {
                id: 2,
                text: "c) Loved talking to you earlier,",
              },
              {
                id: 3,
                text: "d) Great talking to you earlier,",
              },
            ],
          },
          {
            id: 3,
            title: "Thanks for offering me the job in your company",
            correct: [1],
            list: [
              {
                id: 0,
                text: "a) Thank you for the job offer",
              },
              {
                id: 1,
                text: "b) I am very grateful for the job offer",
              },
              {
                id: 2,
                text: "c) WOW! You’ve offered me the job! Thanks!",
              },
              {
                id: 3,
                text: "d) I can’t thank you enough for the job offer",
              },
            ],
          },
          {
            id: 4,
            title: "Of course, I’ll accept",
            correct: [2],
            list: [
              {
                id: 0,
                text: "a) I’d love to accept!",
              },
              {
                id: 1,
                text: "b) I’m really excited to accept",
              },
              {
                id: 2,
                text: "c) I would be delighted to accept",
              },
              {
                id: 3,
                text: "d) I am certainly accepting the job – who wouldn’t?!",
              },
            ],
          },
          {
            id: 5,
            title: "Can’t wait to join the team!",
            correct: [0],
            list: [
              {
                id: 0,
                text: "a) I am very much looking forward to working with you",
              },
              {
                id: 1,
                text: "b) I am over the moon about working in your company",
              },
              {
                id: 2,
                text: "c) I’m really excited about working with you",
              },
              {
                id: 3,
                text: "d) Would absolutely LOVE to join the team!",
              },
            ],
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "",
        title: "Elige la forma más apropiada para un email formal.",
        subtitle: null,
        title2: null,
        type: "multipleabc",
        typeName: "Gramática",
        exercices: [
          {
            id: 1,
            title: "Introduction",
            correct: [0],
            list: [
              {
                id: 0,
                text: "a) I am writing to you to inquire about your leisure facilities",
              },
              {
                id: 1,
                text: "b) I write to ask about your leisure facilities",
              },
              {
                id: 2,
                text: "c) I write to inquire about your leisure facilities",
              },
              {
                id: 3,
                text: "d) I am going to inquire about your leisure facilities",
              },
            ],
          },
          {
            id: 2,
            title: "Context",
            correct: [2],
            list: [
              {
                id: 0,
                text: "a) As I am teacher, it worries me the safety of my students",
              },
              {
                id: 1,
                text: "b) As for me, I am a teacher and is very important the safety of my students",
              },
              {
                id: 2,
                text: "c) As a teacher, I am concerned about the safety of my students",
              },
              {
                id: 3,
                text: "d) Regarding me, as a teacher, my students’ safety is important",
              },
            ],
          },
          {
            id: 3,
            title: "Context and request",
            correct: [2],
            list: [
              {
                id: 0,
                text: "a) Anyway ... before I can make a decision, I need to know the prices and availability",
              },
              {
                id: 1,
                text: "b) Although, before I decide, you need to send me the prices and availability",
              },
              {
                id: 2,
                text: "c) However, before making a decision, I would like to know the prices and availability",
              },
              {
                id: 3,
                text: "d) As for me ... before I make a move, I would like to know the prices and availability",
              },
            ],
          },
          {
            id: 4,
            title: "Request 2",
            correct: [0],
            list: [
              {
                id: 0,
                text: "a) Kindly send me your catalogue",
              },
              {
                id: 1,
                text: "b) Send me you catalogue as soon as possible",
              },
              {
                id: 2,
                text: "c) I ask that you send me a catalogue",
              },
              {
                id: 3,
                text: "d) I appreciate that you send me your catalogue",
              },
            ],
          },
          {
            id: 5,
            title: "Appreciation",
            correct: [1],
            list: [
              {
                id: 0,
                text: "a) Thanks a million for your help",
              },
              {
                id: 1,
                text: "b) Thank you in advance for your help",
              },
              {
                id: 2,
                text: "c) Thank you for helping me",
              },
              {
                id: 3,
                text: "d) I look forward to your reply",
              },
            ],
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "",
        title:
          "A continuación, verás el orden de los elementos de un email formal. La lista siguiente incluye el orden correspondiente a un email formal:",
        subtitle: `<ol>
        <li>INTRODUCCIÓN-JUSTIFICACIÓN</li>        
        <li>CONTEXTO-SOLICITUD 1</li>
        <li>CONTEXTO-SOLICITUD 2</li>
        <li>CONTEXT-SOLICITUD 3</li>
        <li>AGRADECIMIENTO Y DESPEDIDO</li>
      </ol>`,
        title2:
          "Ordena de manera lógica cada uno de los párrafos que verás a continuación. Para ello, deberás arrastrarlos.",
        type: "drag",
        typeName: "Recursos discursivos",
        list: [
          {
            id: 1,
            order: 3,
            text: "Lastly/finally/to finish my enquiries, and before I can fully commit to the project, I would like you to confirm whether ...",
          },
          {
            id: 2,
            order: 2,
            text: "Given my role as supervisor of the project, secondly/in second place/to follow, I also need to know ...",
          },
          {
            id: 3,
            order: 0,
            text: "I am writing this email to ...",
          },
          {
            id: 4,
            order: 1,
            text: "Firstly/in the first place/ to start with, I am writing to enquire ...",
          },
          {
            id: 5,
            order: 4,
            text: "I appreciate your help and look forward to hearing your proposals.",
          },
        ],
      },
    ],
  },
  {
    id: "6",
    menu: 1,
    title: "Email informal",
    options: [
      { tutorial: "644a579c3056d50abd3db591" },
      { goldText: "carta_informal" },
    ],
    exercices: [
      {
        adaptedSRC: false,
        font: "https://learnenglishteens.britishcouncil.org/skills/writing/b2-writing/informal-email",
        title:
          "Ordena los siguientes párrafos siguiendo una estructura coherente para la redacción de un email informal. Para ello,deberás arrastrar cada uno de los párrafos según el orden lógico.",
        subtitle: null,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",

        list: [
          {
            id: 1,
            order: 4,
            text: `<p>So, you’re learning Italian? I’m impressed! Why don’t you try and find an Italian to practice speaking with? If you find someone who’s learning English, you could do a language exchange. It’s the best way to learn. You should try to read some books in Italian as well.</p>
            `,
          },
          {
            id: 2,
            order: 3,
            text: `<p>On Saturday, I’d prefer to go on the boat trip on the river. I’m not really a football fan to be honest. The boat trip sounds much more chilled out!</p>
            `,
          },
          {
            id: 3,
            order: 8,
            text: `<p>Sira</p>`,
          },
          {
            id: 4,
            order: 6,
            text: `<p>I’m really looking forward to seeing you!</p>
            `,
          },
          {
            id: 5,
            order: 5,
            text: `<p>You asked about my skateboard. I’m sorry but I can’t bring it with me to Scotland. I’ve got loads of stuff and it’s too big to fit in my suitcase.</p>
            `,
          },
          {
            id: 6,
            order: 2,
            text: `<p>So, about Friday…I’d love to see your friend’s band! What kind of music do they play? I’m thinking of starting my own band so it’ll be good to meet your friends and ask them about it.</p>
            `,
          },
          {
            id: 7,
            order: 7,
            text: `<p>Best wishes,</p>
            `,
          },
          {
            id: 8,
            order: 1,
            text: `<p>Hi Chris,</p><br/>

            <p>Thanks for your email. It’s great to hear from you. I can’t wait to come and visit!</p>
            `,
          },
          {
            id: 9,
            order: 0,
            text: `<p>To: Chris Stewart</p><br/>
            <p> Subject: Re: Coming to Glasglow</p>
            `,
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "",
        title:
          "Sustituye las palabras señaladas entre paréntesis por un phrasal verb o expresión idiomática.",
        subtitle: null,
        text: `<p>Sorry I haven’t been in touch lately but I've been totally _______ (overwhelmed) at work, plus all the problems I have had with moving house and all that.</p><br/>
        <p>Anyhow ... what have you _______ (been doing)? I say your vrother a few weeks ago and he mentioned that you were lookin for a new job. WOW, you’ve finally decided to leave that place. About time!</p><br/>        
        <p>Any, as you know, George and I _______ (separated) a while ago, as you know, but we're getting on much better now and it is _______ (a better solution) for the kids. I havn’t met anyone else yet but when the summer comes, I’m _______ (going) to Barbados for a long holiday.</p>`,
        title2: null,
        type: "popups",
        typeName: "Léxico",
        list: [
          {
            id: 1,
            options: "snowed under, put down, blown away",
            correct: "snowed under",
            selected: "",
          },
          {
            id: 2,
            options: "put across to, stood up to, been up to",
            correct: "been up to",
            selected: "",
          },
          {
            id: 3,
            options: "cracked up, broke up, finished up",
            correct: "broke up",
            selected: "",
          },
          {
            id: 4,
            options:
              "working out better, finishing up better, getting out better",
            correct: "working out better",
            selected: "",
          },
          {
            id: 5,
            options: "in, out, off",
            correct: "off",
            selected: "",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://oxfordhousebcn.com/en/how-to-write-a-b2-first-informal-email-letter/",
        title:
          "Elige el registro informal más apropiado para la redacción de los siguientes enunciados.",
        subtitle: null,
        title2: null,
        type: "multipleabc",
        typeName: "Gramática",
        exercices: [
          {
            id: 1,
            title: "I am not sure if you know the procedures.",
            correct: [0],
            list: [
              {
                id: 0,
                text: "a) Not sure.",
              },
              {
                id: 1,
                text: "b) I am unsure.",
              },
              {
                id: 2,
                text: "c) I am unaware.",
              },
              {
                id: 3,
                text: "d) I do not know.",
              },
            ],
          },
          {
            id: 2,
            title: "With regard to me ...",
            correct: [1],
            list: [
              {
                id: 0,
                text: "a) Regarding my view ...",
              },
              {
                id: 1,
                text: "b) As for me, ...",
              },
              {
                id: 2,
                text: "c) With regard to me ...",
              },
              {
                id: 3,
                text: "d) As far as I am concerned ...",
              },
            ],
          },
          {
            id: 3,
            title: "I have no reason to complain",
            correct: [1],
            list: [
              {
                id: 0,
                text: "a) I must not complain",
              },
              {
                id: 1,
                text: "b) Can’t complain",
              },
              {
                id: 2,
                text: "c) There is no complaint",
              },
              {
                id: 3,
                text: "d) I should not complain",
              },
            ],
          },
          {
            id: 4,
            title: "I would like to suggest that we meet for a coffee",
            correct: [0, 1],
            list: [
              {
                id: 0,
                text: "a) Would it be possible for us to meet for a coffee?",
              },
              {
                id: 1,
                text: "b) Let’s meet up for a coffee, OK?",
              },
              {
                id: 2,
                text: "c) I wonder if you could find time in your agenda to meet for a coffee",
              },
              {
                id: 3,
                text: "d) I am formally inviting you for a coffee",
              },
            ],
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://oxfordhousebcn.com/en/how-to-write-a-b2-first-informal-email-letter/",
        title:
          "Elige las opciones que se utilizarían en un email informal. Puede haber más de una opción.",
        subtitle: null,
        title2: null,
        type: "multipleabc",
        typeName: "Expresiones informales",
        exercices: [
          {
            id: 1,
            title: "Opening",
            correct: [1, 2, 3],
            list: [
              {
                id: 0,
                text: "a) Dear David,",
              },
              {
                id: 1,
                text: "b) Hi David,",
              },
              {
                id: 2,
                text: "c) Hello David,",
              },
              {
                id: 3,
                text: "d) Hey David,",
              },
            ],
          },
          {
            id: 2,
            title: "Introductory sentence",
            correct: [0, 1],
            list: [
              {
                id: 0,
                text: "a) How ARE you?",
              },
              {
                id: 1,
                text: "b) How are things?",
              },
              {
                id: 2,
                text: "c) I hope this email finds you well.",
              },
              {
                id: 3,
                text: "d) I apologise for the delay in responding",
              },
            ],
          },
          {
            id: 3,
            title: "Main body",
            correct: [1, 2],
            list: [
              {
                id: 0,
                text: "a) If permitted, I would like to suggest ...",
              },
              {
                id: 1,
                text: "b) Re: your new job, that’s FANTASTIC news",
              },
              {
                id: 2,
                text: "c) Don’t know if you know, but ...",
              },
              {
                id: 3,
                text: "d) However, if my request is not possible ...",
              },
            ],
          },
          {
            id: 4,
            title: "Ending",
            correct: [0, 2],
            list: [
              {
                id: 0,
                text: "a) Really looking forward to seeing you",
              },
              {
                id: 1,
                text: "b) I am looking forward to hearing from you",
              },
              {
                id: 2,
                text: "c) Lots of love",
              },
              {
                id: 3,
                text: "d) Regards",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "7",
    menu: 1,
    title: "Ensayo de opinión",
    options: [
      { tutorial: "643fe33d3056d537986eb6b0" },
      { goldText: "ensayo_de_opinion" },
    ],
    exercices: [
      {
        adaptedSRC: true,
        font: "https://www.123helpme.com/essay/School-Uniforms-Should-Not-Be-Persuasive-Essay-FCPJ55ZB8NR",
        title:
          "A continuación, verás el orden de los elementos de un ensayo de opinión. La lista siguiente incluye el orden correspondiente a un ensayo de opinión:",
        subtitle: `
        <ol><li>Dirección del receptor de la carta</li><li>Fecha</li><li>Saludo</li><li>Introducción: preguntas y comentarios de carácter personal</li><li>Cuerpo: motivo por el que se escribe la carta</li><li>Conclusión: resumen y petición de respuesta</li><li>Despedida</li>
        <li>Firma</li>
        </ol>
        `,
        title2:
          "Ordena de manera lógica cada uno de los párrafos que verás a continuación. Para ello, deberás arrastrarlos.",
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",
        list: [
          {
            id: 1,
            order: 1,
            text: `a) Should school uniforms be required? Some parents and educators say that
            uniforms help students focus on academics instead of fashion. Others believe that kids
            should have the freedom to choose what they wear to school. After considering both
            sides of the issue, I strongly believe that uniforms should not be required in school.`,
          },
          {
            id: 2,
            order: 0,
            text: `b) Some parents and teachers think that letting kids wear what they want is a
            recipe for trouble. Without uniforms, they say, kids will focus on clothes instead of
            schoolwork. There will be pressure to buy expensive clothes, which many families
            cannot afford. For these reasons, some people insist that uniforms are necessary.`,
          },
          {
            id: 3,
            order: 3,
            text: `c) I disagree. There will always be distractions. Kids should be encouraged and
            trusted to focus on their schoolwork, or they will never learn to be responsible.
            Uniforms are not free. If families are spending money, they should be able to choose
            the clothes they buy. Also, uniforms interfere with self-expression. Requiring uniforms
            sends a message that all kids are the same. If schools want kids to be themselves, they
            should not force all students to dress alike.`,
          },
          {
            id: 4,
            order: 2,
            text: `d) For all these reasons, school uniforms should not be required. If teachers and
            principals want to have a say about students’ clothes, they should consider a school
            dress code. That way, kids would have an opportunity to choose what to wear while
            adults would have an opportunity to set limits.`,
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.123helpme.com/essay/School-Uniforms-Should-Not-Be-Persuasive-Essay-FCPJ55ZB8NR",
        title:
          "Selecciona la respuesta más apropiada (a, b, c, d): ¿qué verbo utiliza el autor para presentar su opinión?",
        subtitle: null,
        title2: null,
        type: "abc",
        typeName: "Léxico",
        correct: 1,
        list: [
          {
            id: 0,
            text: "a) Some parents and teachers think that letting kids wear what they want is a recipe for trouble.",
          },
          {
            id: 1,
            text: "b) I disagree. Kids should be encouraged and trusted to focus on their schoolwork, or they will never learn to be responsible.",
          },
          {
            id: 2,
            text: "c) Others believe that kids should have the freedom to choose what they wear to school.",
          },
          {
            id: 3,
            text: "d) For all these reasons, school uniforms should not be required.",
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "",
        title:
          "Selecciona la respuesta correcta (a, b, c, d). Identifica los verbos modales que se utilizan en el texto para dar apoyo a la opinión del autor y escoge el enunciado correspondiente de los que aparecen a continuación.",
        subtitle: null,
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 0,
        list: [
          {
            id: 0,
            text: "a) The writers includes the modal verb “should” in the title suggesting what he/she believes should be the way to address the issue of wearing school uniforms.",
          },
          {
            id: 1,
            text: "b) The writes makes a suggestion about what parents must do.",
          },
          {
            id: 2,
            text: "c) The writes makes a suggestion about what has to be done to address the issue.",
          },
          {
            id: 3,
            text: "d) The writes makes a suggestion about what parents can do.",
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "",
        title:
          "Selecciona la respuesta correcta (a, b, c, d). ¿Dónde aparece el gancho en el ensayo de opinión que sirve de enunciado principal para el punto de vista sobre el que se va a debatir en el texto?",
        subtitle: null,
        title2: null,
        type: "abc",
        typeName: "Recursos discursivos",
        correct: 2,
        list: [
          {
            id: 0,
            text: "a) The writer wonders whether this is a good title for the text as part of the concluding remarks.",
          },
          {
            id: 1,
            text: "b) The writer does not have an opinion and therefore avoids answering the question in the main arguments of the text.",
          },
          {
            id: 2,
            text: "c) The writer rewrites the title as a question and summarizes different options on the topic in the introduction.",
          },
          {
            id: 3,
            text: "d) The writer makes a final suggestion about how to deal with parents.",
          },
        ],
      },
    ],
  },
  {
    id: "8",
    menu: 1,
    title: "Informe",
    options: [
      { tutorial: "6436843a3056d57ab8661721" },
      { goldText: "informe" },
    ],
    exercices: [
      {
        adaptedSRC: false,
        font: "https://global-exam.com/blog/es/report-cae-en-que-consiste-un-reporte-del-c1-advanced/",
        title:
          "Ordena de manera lógica los siguientes párrafos correspondientes a un informe para su correcta lectura. Para ello, deberás arrastrarlos.",
        subtitle: null,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",

        list: [
          {
            id: 1,
            order: 4,
            text: `<h5>Training</h5>`,
          },
          {
            id: 2,
            order: 3,
            text: `<p>Due to a lack of funding during the economic recession, many care facilities are in urgent need of repair. Many modern private care homes have sprung up around the city in recent years but they are more expensive and unaffordable for many. Amongst care staff, there are a quite a few assistants suffering from low morale and lack of training.</p>`,
          },
          {
            id: 3,
            order: 0,
            text: `<h1>The effectiveness of nursing homes in the local area</h1>`,
          },
          {
            id: 4,
            order: 7,
            text: `<p>There does seem to be a lack of resources at the care homes in general. This is probably due to lack of funding but also ineffective allocation of funds. In some care homes the patients are not being fed adequately and there is not enough spent on social activities for patients.</p>`,
          },
          {
            id: 5,
            order: 1,
            text: "<p>The main purpose of this report is to assess the effectiveness of nursing homes in the local area. It will look at what care is available and make some recommendations for improvements.</p>",
          },
          {
            id: 6,
            order: 5,
            text: `<p>The training methods used by many care organisations has not changed over the last 30 years or so and needs to be improved. Carers need the correct type of training that is practical and enables the carer to hit the ground running. Some carers don’t seem to have the required patience and empathy to work in a nursing home.</p>`,
          },
          {
            id: 7,
            order: 8,
            text: `<h5>Recommendations</h5>`,
          },
          {
            id: 8,
            order: 9,
            text: "<p>Taking all of the factors of this report into account, the best plan of action would be to increase funding to provide training and resources to run the care homes better. This funding must get used effectively. I would suggest that training be given to managers who have the responsibility to allocate resources.</p>",
          },
          {
            id: 9,
            order: 2,
            text: `<h5>Facilities</h5>`,
          },
          {
            id: 10,
            order: 6,
            text: `<h5>Resources</h5>`,
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "https://engxam.com/handbook/how-to-write-a-report-c1-advanced-cae/",
        title:
          "Selecciona la respuesta correcta (a, b, c, d): ¿Qué sinónimos se corresponden con las palabras que aparecen entre paréntesis en el texto? Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<p>Report on the Staff Development Programme &ndash; a description of the training course carried out to improve the performance of our staff at work.</p><br/>
        <p>Organization of the training modules</p><br/>
        <p>I have recently attended the training course organized by the company in order to _______ (workers) get more information and develop some skills required in this activity.</p><br/>
        <p>I have to say that the information given in the course is hugely useful, but it should be _______ (scheduled) in other date. At this moment, everybody is really busy and workers find it difficult to set aside time to not _______ (miss) the course.</p><br/>
        <p>There is no point _______ (arguing) that the sessions are too long and dense, what makes even harder to get the most of the training.</p><br/>
        
        <p>Alternative course</p><br/>
        <p>I would recommend dividing the content is several shorter sessions according to the main topics to deal with.</p><br/>
        <p>Perhaps, not everybody needs to receive the whole information. So, there can be some specific sessions to people _______ (involved) in that area, whereas who is not working on that subject would only receive a short session just to get some knowledge.</p><br/>
        <p>Recommendations</p><br/>
        <p>I would like to make the following recommendations:</p>
        <ol>
            <li>Schedule the training course in a less busy time.</li>
            <li>Divide the sessions into shorter modules to ease people to attend.</li>
            <li>Ensure the content is targeted to the people who are meant to attend them.</li>
        </ol>
        <p>If these recommendations are implemented, the training programme will be much more successful.</p>`,
        title2:
          "Ordena de manera lógica cada uno de los párrafos que verás a continuación. Para ello, deberás arrastrarlos.",
        type: "popups",
        typeName: "Léxico",
        list: [
          {
            id: 1,
            options: "employers, staff, employed, take",
            correct: "employers",
            selected: "",
          },
          {
            id: 2,
            options: "chose, changed, told, changes",
            correct: "changed",
            selected: "",
          },
          {
            id: 3,
            options: "take, skip, choice, put",
            correct: "skip",
            selected: "",
          },
          {
            id: 4,
            options: "say, complaining, choosing, skipping",
            correct: "complaining",
            selected: "",
          },
          {
            id: 5,
            options: "experts, specialized, employed, employees",
            correct: "specialized",
            selected: "",
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "https://inglesensagunto.es/2015/05/22/muestra-de-report-para-el-b2-first/",
        title:
          "Selecciona la respuesta correcta (a, b, c, d). Teniendo en cuenta el párrafo extraído de la sección de introducción de un informe, ¿qué verbos completarían el sentido del párrafo? Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<p>First of all, it _______ that my area _______ in the North of the town, so it is the main way into Sagunto. Consequently, roads are severely _______ and there are lots of holes. This _______ so dangerous that they _______ an accident soon. As far as I'm concerned, the repair is compulsory.</p>`,
        title2: null,
        type: "popups",
        typeName: "Gramática",
        list: [
          {
            id: 1,
            options: "must be said, says, will say, said",
            correct: "must be said",
            selected: "",
          },
          {
            id: 2,
            options: "is located, stayed, stayed, stay",
            correct: "is located",
            selected: "",
          },
          {
            id: 3,
            options: "burned, damaged, burned, will be located, rain",
            correct: "damaged",
            selected: "",
          },
          {
            id: 4,
            options: "can’t be, rained, might be, cause",
            correct: "might be",
            selected: "",
          },
          {
            id: 5,
            options: "will cause, rain, causing, located",
            correct: "will cause",
            selected: "",
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "https://engxam.com/handbook/how-to-write-a-report-c1-advanced-cae/       ",
        title:
          "Selecciona la respuesta correcta (a, b, c, d). ¿Qué conectores reemplazarían a los que aparecen entre paréntesis en el texto? Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<h5>Report on six-month progress as a teacher</h5>
        <p>The purpose of this report is to evaluate the progress following the first half year in my current position as an English Teacher as well as enumerate achievements outline issues and make suggestions for professional development measures.</p>
        <br/>
        <h5>Achievements</h5>
        <p>_______ (Throughout) the first six months of employment at XYZ School, I have made important strides in professional development by incorporating several new methods such as the task-based approach in daily teaching practice, which has improved my students’ experience tremendously. _______ (Apart from) personal progress, numerous students have achieved their goals and advanced to higher education providers under my guidance.</p><br/>
        
        <h5>Problems</h5>
        <p>_______ (Despite of) the fact that this organization of internal exams is underdeveloped as there does not seem to exist policy and students have expressed their displeasure with other groups at the same level receiving easier or more difficult questions in their tests. _______ (Additionally), my mentor has not always been available even though a certain level of guidance in specific areas, for example developing teaching materials, is still required.</p><br/>
        
        <h5>Future opportunities</h5>
        <p>_______ (Considering all of the above), two main areas of possible improvement can be identified. Firstly, student and teacher satisfaction could be increased by establishing a resource bank for tests and exams and by training all the teachers to use them so as to ensure a consistent experience for our students. Secondly, regular meetings with a mentor should be mandatory in order to provide guidance and to help teachers become independent and confident with teaching materials.</p><br/>
        
        <h5>Conclusion</h5>
        <p>In the final analysis, the situation of the teachers and students at our school is very likely to improve and overall satisfaction will probably increase due to more efficient work processes if ideas included in the report are implemented.</p>
        `,
        title2: null,
        type: "popups",
        typeName: "Recursos discursivos",
        list: [
          {
            id: 1,
            options: "Thus, For example, Despite all of, All over",
            correct: "All over",
            selected: "",
          },
          {
            id: 2,
            options: "For example, But now, Additionally, Along with",
            correct: "Along with",
            selected: "",
          },
          {
            id: 3,
            options: "That is why, That, As well as, In spite of the fact that",
            correct: "In spite of the fact that",
            selected: "",
          },
          {
            id: 4,
            options: "But now, Alluding to, That is why, Furthermore",
            correct: "Furthermore",
            selected: "",
          },
          {
            id: 5,
            options:
              "As well as, Thus, That is why, Taking all the issues stated above as a starting point",
            correct: "Taking all the issues stated above as a starting point",
            selected: "",
          },
        ],
      },
    ],
  },
  {
    id: "9",
    menu: 1,
    title: "Narración de historia",
    options: [
      { tutorial: "643684393056d57ab866171c" },
      { goldText: "narracion_de_historia" },
    ],
    exercices: [
      {
        adaptedSRC: false,
        font: "https://www.yourdictionary.com/articles/examples-narrative-essay",
        title:
          "Ordena de manera lógica los siguientes párrafos correspondientes a una narración de historia para su correcta lectura. Para ello, deberás arrastrarlos.",
        subtitle: null,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",

        list: [
          {
            id: 1,
            order: 3,
            text: `<p>First, you have to stand on your own two feet. As much as our parents love and support us, they can’t go to our school and confess to the principal that we stole a candy bar from Sara. We have to do that. Neither can they walk into the Condé Nast office and nail a job interview for us. At some point, we have to put on our “big girl pants” and be brave, even if we’re not.</p>`,
          },
          {
            id: 2,
            order: 6,
            text: `<p>Next week, I’m off to Istanbul to explore their art scene. As soon as I read the email from my editor, I picked up my phone to call Dad. Then, I realized he’ll never answer my calls again. I fought back the tears, got up to make a cup of peppermint tea, and added a new note to my iPhone titled, “Istanbul Packing List.”</p>`,
          },
          {
            id: 3,
            order: 7,
            text: `<p>In the end, life goes on. I’m not sure why he had to leave during the single most poignant chapter in my life. So, I won’t dwell on that. Instead, I’ll hold tightly to these three ideals and write about Karaköy in Istanbul’s Beyoğlu district. Dad will be with me every step of the way.</p>`,
          },
          {
            id: 4,
            order: 2,
            text: `<p>When my phone rang, and it was Mom telling me Dad had a heart attack. He didn’t make it. I felt as though the perfectly carpeted floors had dropped out from under me. Now that I’ve come out the other side, I realize Dad left me with a hefty stack of teachings. Here are three ideals I know he would’ve liked for me to embrace.</p>`,
          },
          {
            id: 5,
            order: 0,
            text: "<h1>He Left So I Could Learn</h1>",
          },
          {
            id: 6,
            order: 5,
            text: `<p>Finally, memories are, perhaps, the only item that cannot be taken away from us. Will I miss my father? Every single day. What can I do in those times? I can open up our suitcase of memories, pick out my favorite one, and dream about it, talk about it, or write about it. Maybe I can’t pick up the phone and call him anymore, but that doesn’t mean he’s gone.</p>`,
          },
          {
            id: 7,
            order: 4,
            text: `<p>Also, there’s a difference between love and co-dependence. Being grateful to have someone to turn to for love and support is not the same as needing someone to turn to for love and support. With the loss of my father, I’ve also lost my sounding board. All I can glean from that is it’s time to look within myself and make proper assessments. If I can’t make sound decisions with the tools already in my kit, then I risk falling for anything.</p>`,
          },
          {
            id: 8,
            order: 1,
            text: "<p>It was my second day on the job. I was sitting in my seemingly gilded cubicle, overlooking Manhattan, and pinching my right arm to make sure it was real. I landed an internship at Condé Nast Traveler. Every aspiring writer I’ve ever known secretly dreamt of an Anthony Bourdain lifestyle. Travel the world and write about its most colorful pockets.</p>",
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "https://kseacademy.com/cambridge/b1-preliminary-pet/writing/",
        title:
          "Selecciona la respuesta correcta (a, b, c, d) haciendo clic en los huecos que faltan en esta narración de historia. Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<h5>The Broken Train</h5><br/>
        <p>Tim _______ angry as he got off the train. The train had broken down and they _______ in the middle of the countryside.</p><br/>
        <p>Everyone was getting off the train, speaking to each other. But Tim was tired and he _______ to talk much. So he sat under a tree and decided to take a nap. Tim was sleeping when, without a warning, he heard the sound of a train moving. All of a sudden, Tim _______ and saw the train moving away. He shouted, ’’Stop the train, please!’’, but nobody heard him. And although he ran and ran, Tim couldn't reach the train.</p><br/>
        <p>In the end, he gave up running and _______ his parents, who picked him up a few hours later.</p>`,
        title2: null,
        type: "popups",
        typeName: "Léxico",
        list: [
          {
            id: 1,
            options: "stopped, cried, felt, edge",
            correct: "felt",
            selected: "",
          },
          {
            id: 2,
            options: "cried, wanted, had stopped, cried",
            correct: "had stopped",
            selected: "",
          },
          {
            id: 3,
            options: "didn’t want, didn’t see, didn’t eat, didn’t drink",
            correct: "didn’t want",
            selected: "",
          },
          {
            id: 4,
            options: "woke up, woke up, got up, drank",
            correct: "woke up",
            selected: "",
          },
          {
            id: 5,
            options: "hampered, closed, opened, called",
            correct: "called",
            selected: "",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "http://www.english-practice.at/b1/grammar/tenses/b1-tenses-index.htm",

        title:
          "Selecciona la respuesta correcta (a, b, c, d). En base al texto introductorio de esta narración de historia donde los verbos de cada oración están en forma no personal (infinitivo): ¿cómo escribirías los verbos de forma correcta en pasado simple, pasado continuo o pasado perfecto según corresponda en cada caso? Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<p>Mary was one of 185 passengers on a British Airways flight to Warsaw. She _______ (FEEL) very excited when she _______ (GET) on the plane at Gatwick Airport. She _______ (WAIT) a long time for this day. She _______ (FINALLY LEAVE) England to start her new job in the eastern European capital.</p>`,
        title2: null,
        type: "popups",
        typeName: "Gramática",
        list: [
          {
            id: 1,
            options: "felt, was feeling, was, got",
            correct: "was feeling",
            selected: "",
          },
          {
            id: 2,
            options: "had got, got, was getting, felt",
            correct: "got",
            selected: "",
          },
          {
            id: 3,
            options: "had waited, waited, was waiting, got",
            correct: "had waited",
            selected: "",
          },
          {
            id: 4,
            options: "waited, left, was, was finally leaving",
            correct: "was finally leaving",
            selected: "",
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "https://english-practice.net/english-writing-exercises-for-b1-a-narrative/",
        title:
          "Selecciona la respuesta correcta (a, b, c, d) haciendo clic en los huecos que faltan en esta narración de historia. Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<p>_______, I was having a coffee with my cousin at a caf&eacute; in town. We were talking about my eighteenth birthday party. Ellie mentioned a friend of mine, Tom, and asked if he was going to be there. ''I haven’t seen him for months,'' I replied. ''I’m not even sure that I’ve got his number.'' While we were chatting, I took out my phone and checked.</p><br/>
        <p>About two weeks _______, it was the day of my party and I was getting everything ready. To my surprise, I had a call from Tom. Naturally, I told him about the party. ''Why don’t you come along?'' I suggested. He accepted the invitation.</p><br/>
        <p>_______ Ellie arrived for the party, I told her what had happened. ’It’s such a coincidence,'' I said to her. ''I hadn’t spoken to Tom for months, but he phoned today. And he’s coming to the party!'' At the party that evening, Tom and Ellie got on really well together.</p><br/>
        <p>_______, Tom explained what had happened. ''You left a long message on my voicemail,'' he said. ''You must have dialled my number by mistake when you
        were in the café. You were talking to Ellie about me. I heard everything!''</p>`,
        title2: null,
        type: "popups",
        typeName: "Recursos discursivos",
        list: [
          {
            id: 1,
            options: "Tomorrow, Last summer, Next summer, About two months ago",
            correct: "About two months ago",
            selected: "",
          },
          {
            id: 2,
            options: "after, before, finally, later",
            correct: "later",
            selected: "",
          },
          {
            id: 3,
            options: "In the end, After that, When, Finally",
            correct: "When",
            selected: "",
          },
          {
            id: 4,
            options: "Later, In the end, Before, Finally",
            correct: "In the end",
            selected: "",
          },
        ],
      },
    ],
  },
  {
    id: "10",
    menu: 1,
    title: "Propuesta",
    options: [
      { tutorial: "643fe33c3056d537986eb697" },
      { goldText: "propuesta" },
    ],
    exercices: [
      {
        adaptedSRC: true,
        font: "https://ca.indeed.com/career-advice/career-development/how-to-write-proposal#:~:text=How%20to%20write%20a%20proposal%201%201.%20Plan,budget%20...%206%206.%20Write%20a%20conclusion%20",
        title:
          "Ordena de manera lógica los siguientes párrafos correspondientes a una propuesta para su correcta lectura. Para ello, deberás arrastrarlos.",
        subtitle: null,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",

        list: [
          {
            id: 1,
            order: 4,
            text: `<h3>Conclusion</h3>
            <p>I hope that my proposal has included all the necessary information to explain the benefits of this software and why it's an excellent solution for your needs.</p>`,
          },
          {
            id: 2,
            order: 2,
            text: `<h3>Statement of the problem
            </h3>
            <p>I know that your company needs a better server interface because of the ad you put out sometime last year. With my research, I gathered that your company has over 1000 employees all over the world who all make use of your server.</p>`,
          },
          {
            id: 3,
            order: 0,
            text: `<p>To: DownUp Inc.</p>
            <p>From: Ingrid March</p>
            <p>Date: January 1, 2022</p>
            <p>Subject: A proposal to develop new software for DownUp Inc.</p>`,
          },
          {
            id: 4,
            order: 1,
            text: `<h3>Introduction</h3>
            <p>I'm writing this proposal to propose the development of new software for your company that enables your employees to interact with your system servers better. This software makes use of a new technology that isn't common in tech spaces.</p>`,
          },
          {
            id: 5,
            order: 3,
            text: `<h3>Solution</h3>
            <p>I'm suggesting my new software because I have just concluded beta testing and I can assure you it's exactly what your company needs. With my software, I can solve the current issues with bugs and lagging.</p>`,
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://ca.indeed.com/career-advice/resumes-cover-letters/project-proposal-sample",
        title:
          "Selecciona la respuesta correcta (a, b, c, d) haciendo clic en los huecos que faltan en esta propuesta comercial. Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<h5>Executive summary</h5>
        <p>Bob and Abby Co. is one of the most popular supermarkets in Ontario and the only
        store in the region that accepts payment in any currency. If your company is looking to
        grow further throughout Canada, you _______ a business website. Real World Solutions
        is ready to develop a website that promotes your business in Canada and globally.
        Since your store accepts payment in any international currency, adding this to the
        website has the potential to grow your sales by 250% in just 18 months.</p><br/>
        <h5>Project background</h5>
<p>
  Real World Solutions is a tech company in Ontario with _______ work history with banks,
  insurance firms, and other organizations in Canada and the U.S.
</p><br/>
<h5>Solutions and approach</h5>
<p>
  _______ develop a functional website that represents the values and cultures of Bob and
  Abby Co. This website _______ in user-friendliness, customer relations, and content
  creation and management.
</p><br/>
<h5>Conclusion</h5>
<p>
  Creating a website for Bob and Abby Co. _______ your business from Ontario to the
  global market. Implementing the website can increase your sales by over 250% in 18
  months.
</p>`,
        title2: null,
        type: "popups",
        typeName: "Léxico",
        list: [
          {
            id: 1,
            options: "request, need, want, suggest",
            correct: "need",
            selected: "",
          },
          {
            id: 2,
            options: "fantastic, remarkable, certain, cool",
            correct: "remarkable",
            selected: "",
          },
          {
            id: 3,
            options:
              "You want to, You have to, It is important for you, The idea is to",
            correct: "The idea is to",
            selected: "",
          },
          {
            id: 4,
            options:
              "aims to be efficient, will be good, should be efficient, need",
            correct: "aims to be efficient",
            selected: "",
          },
          {
            id: 5,
            options:
              "can help grow, should improve, must increase, will be good",
            correct: "can help grow",
            selected: "",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.indeed.com/career-advice/career-development/how-to-write-a-project-proposal",
        title:
          "Selecciona la respuesta correcta (a, b, c, d) haciendo clic en los huecos que faltan en esta propuesta comercial. Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 0,
        list: [
          {
            id: 0,
            text: `a) <strong>Executive summary.</strong> Rose Boutique is the leading women's retro apparel store in the Bay Area. However, without a website, the store <span class="m-underlined">cannot sell</span> beyond this area. The team at Everbright Technology Solutions <span class="m-underlined">would love to create</span> a website for you that <span class="m-underlined">can help you</span> expand your reach to the rest of California and even an international market. We anticipate this website <span class="m-underlined">would help you increase</span> your sales by 500% in the next two years.`,
          },
          {
            id: 1,
            text: `b) <strong>Executive summary</strong> Rose Boutique is the leading women's retro apparel store in the Bay Area. However, without a website, the store <span class="m-underlined">can sell</span> beyond this area. The team at Everbright Technology Solutions <span class="m-underlined">must create</span> a website for you that <span class="m-underlined">could help you</span> expand your reach to the rest of California and even an international market. We anticipate this website <span class="m-underlined">could help you</span> increase your sales by 500% in the next two years.`,
          },
          {
            id: 2,
            text: `c) <strong>Executive summary</strong> Rose Boutique is the leading women's retro apparel store in the Bay Area. However, without a website, the store <span class="m-underlined">can’t sell</span> beyond this area. The team at Everbright Technology Solutions <span class="m-underlined">should love to create</span> a website for you that <span class="m-underlined">would help you</span> expand your reach to the rest of California and even an international market. We anticipate this website <span class="m-underlined">must help you</span> increase your sales by 500% in the next two years.`,
          },
          {
            id: 3,
            text: `d) <strong>Executive summary</strong> Rose Boutique is the leading women's retro apparel store in the Bay Area. However, without a website, the store <span class="m-underlined">couldn’t</span> sell beyond this area. The team at Everbright Technology Solutions <span class="m-underlined">would love to create</span> a website for you that <span class="m-underlined">must help you</span> expand your reach to the rest of California and even an international market. We anticipate this website <span class="m-underlined">should help you</span> increase your sales by 500% in the next two years.`,
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://templates.proposify.com/proposal-template/Painting-Services/4209735",
        title:
          "Selecciona la respuesta correcta (a, b, c, d). ¿Qué recursos propios de la persuasión se observan en el extracto siguiente?",
        subtitle: `<h5>Overview</h5>
        <p>Why hire a professional painting company? The answer is threefold: time, quality, and value. Most of us feel that our time is worth more than our money. A pro can complete your residential or commercial paint job in a fraction of the time, using the safest and most appropriate materials, and adding the latest in design and finishing touches. Now THAT'S value!
        </p>
        <p>You and your team spend most of your waking hours at the office. That space should be fresh, bright, and welcoming, we deliver the goods, with personal service and the highest quality work and attention to detail-all at a very reasonable price. We leave the worksite clean and dust free, and all our paint jobs are guaranteed against peeling, cracking, and chipping for two years.
        </p>`,
        title2: null,
        type: "abc",
        typeName: "Recursos discursivos",
        correct: 2,
        list: [
          {
            id: 0,
            text: `a) Preguntas retóricas, estructuras impersonales, lenguaje neutral`,
          },
          {
            id: 1,
            text: `b) Exageración, listas, mayúsculas`,
          },
          {
            id: 2,
            text: `c) Hipérbole, preguntas retóricas, enumeración`,
          },
          {
            id: 3,
            text: `d) Exclamaciones, exageración, vocabulario profesional`,
          },
        ],
      },
    ],
  },
  {
    id: "11",
    menu: 1,
    title: "Reseña de libro o película",
    options: [
      { tutorial: "643fe33c3056d537986eb692" },
      { goldText: "resenia_libro" },
    ],
    exercices: [
      {
        adaptedSRC: true,
        font: "https://www.bbc.com/culture/article/20230207-is-federico-fellinis-8-12-the-coolest-film-ever-made",
        title:
          "Ordena de manera lógica los siguientes párrafos correspondientes a una reseña de una película para su correcta lectura. Para ello, deberás arrastrarlos.",
        subtitle: null,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",

        list: [
          {
            id: 1,
            order: 4,
            text: `
            <p>With autobiographical intent, 8 1/2 follows Guido (Mastroianni), a film director in Rome who has hit a creative block on his latest project, a science-fiction film. He is surrounded by a flurry of hangers-on, critics, producers, performers and socialites, all clucking away while his marriage to Luisa (Anouk Aimée) falls apart. Close to calling off the film entirely, Guido slips between reality, fantasies and memories. The central conundrum he wrestles with is: would a more openly personal film be a catharsis for his creative block, or would such a project simply be seen as indulgent egotism?</p><br/><p>At the same time, Guido embodies a calm cosmopolitanism with his made-to-measure Brioni suit, occasional cigarette and a nonchalant flitting between chattering people demanding things of him. Failing to make a film never seemed so cool.</p>`,
          },
          {
            id: 2,
            order: 5,
            text: `8 1/2 is arguably as much a portrait about simply being alive as it is about creativity. Perhaps Fellini's final trick is portraying life as a kind of film of our own making, one we slip in and out of like a dream. And what illusion could really be cooler than that?`,
          },
          {
            id: 3,
            order: 1,
            text: `<p>What counts as "cool" exactly? It's not easy to pin down: as a notion, "coolness" is both frustratingly intangible and constantly in flux. In fashion, music and cinema, the genesis of "cool" feels heavily tied to the emergence of popular culture in the 20th Century – from the US jazz scene that first popularised the term, to the fashion world's post-war development of ready-to-wear clothing aimed at the newly emerging teenage market, as well as mass media such as pop music and cinema that became the dominant modes of creative expression in the same period.</p>`,
          },
          {
            id: 4,
            order: 0,
            text: `<h1>Is Federico Fellini's 8 1/2 the coolest film ever made?
            </h1>`,
          },
          {
            id: 5,
            order: 3,
            text: `
            <p>In one particular scene in the film, filmmaker Guido and an illusionist are chatting on a terrace. "There are many tricks but part of it is real somehow," suggests the magician when discussing his art. He may as well be talking about the director's art too. Guido, played by the endlessly cool Marcello Mastroianni, is the protagonist in 8 1/2 and a fictional alter-ego for the Italian maestro. And, true to the magician's adage, 8 1/2 is both a film of fantastical trickery, and one that retains a beating heart beneath its stylish exterior. Turning 60 this week, it is still one of the coolest cinematic illusions of the 20th Century.</p>`,
          },
          {
            id: 6,
            order: 2,
            text: `
            <p>If there's any one element that defines "cool", it's perhaps a sense of calm confidence – a quality desirable enough to encourage emulation. This feels especially true in cinema, where films, filmmakers and performers labelled "cool" over the years are heavily copied. From the films of Jean-Luc Godard to classic Hollywood stars such as James Dean and Marlon Brando, "cool" in cinema has often been a mixture of fashion, flair and finesse. If considering what might constitute the ultimate example of cinematic "cool", however, then it's not a stretch of the imagination to believe it would look something like Federico Fellini's 1963 masterpiece 8 1/2 – all of which brings us to a film director and a magician.</p>`,
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.publishersweekly.com/978-0-86154-527-8",
        title:
          "Selecciona la respuesta correcta (a, b, c, d) haciendo clic en los huecos que faltan en esta reseña de un libro. Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<h3>Sleeping Beauties: The Mystery of Dormant Innovations in Nature and Culture</h3><br/>
        <h5>Andreas Wagner. Oneworld, $28.95 (352p) ISBN 978-0-86154-527-8</h5><br/>
        <p>Evolutionary “innovations”; can lie dormant for millions of years before they become _______, according to this _______ study. Wagner (Life Finds a Way), a biology professor at the University of Zurich, explains that as environments change, gene or protein mutations that previously had no functional value can become transformational. Bacteria, for example, can resist antibiotics neither they nor their ancestors have ever encountered. The _______ prose ensures even excursions into molecular biology are _______, and Wagner finds _______ depth in evolutionary history, as when he suggests that the independent discovery of agriculture by human communities across the globe—;as well as by ants, which cultivate fungi—;casts doubt on individual-centric notions of genius and innovation. This is the _______ volume that general readers will enjoy as much as specialists.</p>`,
        title2: null,
        type: "popups",
        typeName: "Léxico",
        list: [
          {
            id: 1,
            options: "excellent, accessible, dormant, useful",
            correct: "useful",
            selected: "",
          },
          {
            id: 2,
            options: "rare, excellent, dormant, big",
            correct: "excellent",
            selected: "",
          },
          {
            id: 3,
            options: "useful, surprising, accessible, big",
            correct: "accessible",
            selected: "",
          },
          {
            id: 4,
            options: "surprising, genuine, comprehensible, crucial",
            correct: "comprehensible",
            selected: "",
          },
          {
            id: 5,
            options: "ideal, evolutionary, surprising, rare",
            correct: "surprising",
            selected: "",
          },
          {
            id: 6,
            options: "individual, adapted, rare, surprising",
            correct: "rare",
            selected: "",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://engxam.com/es/handbook/como-escribir-una-resena-c2-proficient-cpe/",

        title:
          "Selecciona la respuesta correcta (a, b, c, d). Teniendo en cuenta los apartados de Antecedentes y Resumen General de la Obra de la siguiente reseña de libro, ¿cómo podrías reformular las oraciones declarativas en oraciones interrogativas sin modificar el tiempo verbal?",
        subtitle: `
        <h1>Childhood Book</h1><br/>
        <p>One of my favourite childhood books is the ‘Six Bullerby Children’ [...] The story is set in a three-farm remote village of Bullerby.</p><br/>
        <p>The narrator is a seven-year-old girl [...] Lisa describes the daily life of the children in Bullerby [...] The children have plenty of funny ideas, ...</p>
        `,
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 2,
        list: [
          {
            id: 0,
            text: `a) Will one of my favourite childhood books be the ‘Six Bullerby Children’? / Will the story be set in a three-farm remote village of Bullerby? / Will the narrator be a
            seven-year-old girl? / Will Lisa describe the daily life of the children in Bullerby? / Will the children have plenty of funny ideas?
            `,
          },
          {
            id: 1,
            text: `b) ‘Six Bullerby Children’ is not one of my favourite childhood books / Has the story been set in a three-farm remote village of Bullerby? / Will the narrator be a seven-
            year-old girl? / How did Lisa describe the daily life of the children in Bullerby? / The children had plenty of funny ideas`,
          },
          {
            id: 2,
            text: `c) Is Six Bullerby Children one of my favourite childhood books? / Is the story set in a three-farm remote village of Bullerby? Is the narrator a seven-year-old girl? Does Lisa
            describe the daily life of the children in Bullerby? / Do the children have plenty of funny ideas?`,
          },
          {
            id: 3,
            text: `d) Could Six Bullerby Children have been one of my favourite childhood books? / Was the story set in a three-farm remote village? / Was the narrator a seven-year-old
            girl? / Did Lisa describe the daily life of the children in Bullerby? / The children had plenty of funny ideas.`,
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.bbc.com/culture/article/20221118-baba-yaga-the-greatest-wicked-witch-of-all",
        title:
          "Selecciona la respuesta correcta (a, b, c, d). ¿Qué conectores faltan en los huecos del fragmento seleccionado de esta reseña de un libro? Puedes modificar las respuestas tantas veces como quieras, antes de hacer clic en “Continúa”.",
        subtitle: null,
        text: `<p>
          A key figure from Slavic folklore, Baba Yaga certainly fulfils the requirements of the wicked witch – she lives in a house that walks through the forest on chicken legs, and
          sometimes flies around (close to ground level) in a giant mortar and pestle. She usually appears as a hag or crone, and she is known in most witch-like fashion to feast
          upon children.
        </p>
        <p>
          _______, she is also a far more complex character than that synopsis suggests. Cunning, clever, helpful as much as a hindrance, she could indeed be the most feminist
          character in folklore [...] Baba Yaga appears in many Slavic and especially Russian folk tales, with the earliest recorded written mention of her coming in 1755, as part of a
          discourse on Slavic folk figures in Mikhail V Lomonosov's book Russian Grammar. _______ that, she had appeared in woodcut art at least from the 17th Century, and
          then made regular appearances in books of Russian fairy tales and folklore.
        </p>
        <p>
          Into the Forest is edited by Lindy Ryan, a writer and full-time professor of data science and visual analytics at Rutgers University, New Brunswick, New Jersey who is also
          the founder of Into the Forest's publisher Black Spot Books, a small press dedicated to female horror writers. _______ how did an American end up fascinated by this
          Slavic myth? [...]While most girls my age were growing up with nicely sanitised Disney version princesses, I preferred the stories by Brothers Grimm, Charles Perrault, and
          Hans Christian Andersen – and, of course, in the books of Slavic fairytale and folklore that talked of Baba Yaga."
        </p>
        <p>
          _______ the origins of Baba Yaga might go back far further than the 17th Century — there's a school of scholarly thought that says she's a Slavic analogue of the Greek
          deity Persephone, goddess of spring and nature. She's certainly associated with the woods and forests, and the wildness of nature.
        </p>
        <p>
          For Lindy Ryan, the concept of a women-centric horror anthology came before the idea of a Baba Yaga-themed book _______ the great witch of the forest exerted her
          influence. "I wanted the anthology to feature the voices of women around the world who were unafraid to tell their stories, to tap into their own wildness and
          wickedness”.
        </p>
        <p>
          Izzy's contribution, The Story of a House, explores one of the unique facets of the Baba Yaga myth – her ramshackle cottage that walks on chicken legs, recounting its
          creation and construction. "I was just charmed thinking of the little chicken legs of this big old house. This directly led me to wanting to write something about the house.
          _______ I had just come off translating The Shadow Book of Ji Yun...
        </p>`,
        title2: null,
        type: "popups",
        typeName: "Recursos discursivos",
        list: [
          {
            id: 1,
            options: "However, Before, Further, Also",
            correct: "However",
            selected: "",
          },
          {
            id: 2,
            options: "So, Before, Of course, Further",
            correct: "Before",
            selected: "",
          },
          {
            id: 3,
            options: "So, author, Before, As well",
            correct: "So",
            selected: "",
          },
          {
            id: 4,
            options: "Further, As well, Before, In fact",
            correct: "In fact",
            selected: "",
          },
          {
            id: 5,
            options: "but, as well, of course, also",
            correct: "but",
            selected: "",
          },
          {
            id: 6,
            options: "So, Of course, Further, As well",
            correct: "As well",
            selected: "",
          },
          {
            id: 7,
            options: "flames, flames, flames, flames",
            correct: "flames",
            selected: "",
          },
        ],
      },
    ],
  },
  /* Listado de precios */
  {
    id: "12",
    menu: 2,
    title: "Listado de precios",
    options: [
      { tutorial: "662766c5a35b6250fc642df7" },
      { goldText: "listado_de_precios" },
    ],
    exercices: [
      {
        adaptedSRC: false,
        font: "https://www.pinterest.es/pin/654640495816364710/",
        title:
          "Ordena de manera lógica las imágenes correspondientes en la lista de precios a continuación. Para ello, deberás arrastrar cada imagen según el orden lógico de su estructura.",
        subtitle: null,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",
        list: [
          {
            id: 1,
            order: 1,
            text: "<img src='/images/listadoprecios/image1.png'/>",
          },
          {
            id: 2,
            order: 4,
            text: "<img src='/images/listadoprecios/image4.png'/>",
          },
          {
            id: 3,
            order: 3,
            text: "<img src='/images/listadoprecios/image3.png'/>",
          },
          {
            id: 4,
            order: 2,
            text: "<img src='/images/listadoprecios/image2.png'/>",
          },
          {
            id: 5,
            order: 0,
            text: "<img src='/images/listadoprecios/image0.png'/>",
          },
          {
            id: 6,
            order: 5,
            text: "<img src='/images/listadoprecios/image5.png'/>",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.pinterest.es/pin/423760646203140697/",
        title:
          "Selecciona la opción correcta (a, b, c, d) para los huecos en la siguiente lista de precios.",
        subtitle:
          "<div class='subtitle-flex'><p class='flex-between'>GRAPHIC DESIGN</p><p class='flex-between'><span>_______</span> LIST</p><p class='flex-between my-1'>GRAPHIC DESIGN SERVICES PRICING LIST</p><p class='flex-between'>WordPress Theme Design <strong>230€</strong></p><p class='flex-between'>Postcard Design. <strong>40€</strong></p><p class='flex-between'>Product <span>_______</span> <strong>250€</strong></p><p class='flex-between'>Infographic <strong>330€</strong></p><p class='flex-between'>Clothing <strong>210€</strong></p><p class='flex-between'>Book Covel <strong>1,230€</strong></p><p class='flex-between'>Landing Page <strong>235€</strong></p><p class='flex-between'>Illustrations <strong>240€</strong></p><p class='flex-between'>Flyer <strong>89€</strong></p><p class='flex-between'>Email <span>_______</span> <strong>15€</strong></p><p class='flex-between'>Menu <span>_______</span> <strong>230€</strong></p></div>",
        title2: null,
        type: "abc",
        typeName: "Léxico",
        correct: 2,
        list: [
          {
            id: 0,
            text: "a) Taxes, Payment, Items, Quality.",
          },
          {
            id: 1,
            text: "b) Quantity, Subtotal, Tax, Payment.",
          },
          {
            id: 2,
            text: "c) Pricing, Packaging, Templates, Design.",
          },
          {
            id: 3,
            text: "d) Total, Items, Taxes, Payment.",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://imenupro.com/templates",
        title:
          "Selecciona la opción correcta (a, b, c, d) para los huecos del fragmento seleccionado de esta lista de precios.",
        subtitle:
          "<div class='subtitle-flex'><p class='my-1'><strong>SUPER FOODS RESTAURANT- HAYMARKET VIRGINIA SUPERFOOD MENU</strong><p>GOAT CHEESE SPREAD - <strong>$6.95</strong></p><p><span>_______</span> GOAT CHEESE WITH FARM FRESH BAKED WHOLE GRAIN BREAD</p><p>PORTOBELLO REDHEAD - <strong>$7.75</strong></p><p>ROASTED PORTOBELLO MUSHROOMS, <span>_______</span> ONIONS, AND TOPPED WITH FETA CHEESE</p><p>SOUP/SALAD COMBO - <strong>$7.25</strong></p><p>A SMALLER VERSION OF THE SALAD OF YOUR CHOICE SERVED WITH SMALL SOUP OF THE WEEK.</p><p>MEXICAN BEAN - <strong>$6.85</strong></p><p>BLACK BEAN PUREE AND PEPPER JACK CHEESE TOPPED WITH OUR <span>_______</span> SALSA AND SPRINKLED WITH FRIED GARLIC + Make it vegan by substituting soy cheese <strong>$0.50</strong></p><p>GRILLED CHICKEN PANINI - <strong>$11.25</strong></p><p><span>_______</span> MOZZARELLA, ROASTED RED PEPPERS, SLICED</p></div>",
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 0,
        list: [
          {
            id: 0,
            text: "a) LOCALLY SOURCED, CARAMELIZED, HOMEMADE, FRESH.",
          },
          {
            id: 1,
            text: "b) HOMEMADE, RED, LOCAL, GREEN.",
          },
          {
            id: 2,
            text: "c) GREEN, HOMEMADE, LOCAL, ENGLISH.",
          },
          {
            id: 3,
            text: "d) FRESH, RED, SCOTTISH, HOMEMADE.",
          },
        ],
      },
      {
        adaptedSRC: false,
        font: "https://www.pinterest.es/search/pins/?q=price%20list&rs=typed",
        title: "Selecciona la opción correcta (a, b, c, d) para los apartados de cada sección de servicios",
       /*  subtitle: null, */
        subtitle: `
          <div class='subtitle-flex'>
            <h4>PRICING AND PACKAGES</h4>
            <h5><span>_______</span> — $999</h5>
            <ul>
              <li class="f-14">4 hours of Wedding Day Coverage</li>
              <li class="f-14">1 photographer</li>
              <li class="f-14">Engagements</li>
              <li class="f-14">Online Gallery to view and order prints</li>
              <li class="f-14">DVD with high-resolution Images</li>
            </ul>
            
            <h5><span>_______</span> = $1,750</h5>
            <ul>
              <li class="f-14">+ 6 hours of wedding Day Coverage</li>
              <li class="f-14">2 photographers</li>
              <li class="f-14">Engagements de Bridal</li>
              <li class="f-14">Online Gallery to view and order prints.</li>
              <li class="f-14">$200 Print Credit</li>
              <li class="f-14">DVD with high-resolution images</li>
              <li class="f-14">11x14 Engagement and 16x20 Bridals</li>
              <li class="f-14">Custom wedding guest album</li>
              <li class="f-14">8x8 custom wedding album</li>
            </ul>
            
            <h5><span>_______</span> - $1,320</h5>
            <ul>
              <li class="f-14">5 hours of Wedding Day Coverage</li>
              <li class="f-14">1 photographer</li>
              <li class="f-14">Engagements & Bridals</li>
              <li class="f-14">Online Gallery to view and order prints.</li>
              <li class="f-14">$100 Print Credit</li>
              <li class="f-14">DVD with high-resolution images</li>
              <li class="f-14">Custom wedding guest album.</li>
            </ul>
            
            <h5><span>_______</span> = $2,945</h5>
            <ul>
              <li class="f-14">8 hours of wedding Day Coverage</li>
              <li class="f-14">2 photographers</li>
              <li class="f-14">Engagements & Bridals</li>
              <li class="f-14">Photo CD with all Wedding/Engagement images</li>
              <li class="f-14">Online Gallery to view and order prints</li>
              <li class="f-14">16x20 Canvas of favorite photo</li>
              <li class="f-14">$300 Print Credit</li>
              <li class="f-14">12x12 Photo Album (10 Pages - 20 Sides)</li>
              <li class="f-14">8x10 Parent album</li>
              <li class="f-14">DVD with high-resolution images.</li>
              <li class="f-14">Additional Coverage/hour $150</li>
              <li class="f-14">Additional Photographer $100</li>
            </ul>
            
            <h5><span>_______</span> = Additional Coverage/hour $150</h5>
            <h5>Additional Photographer $100 </h5>
            <ul>
              <li class="f-14">Boudoir session with album $400</li>
              <li class="f-14">First Look 2 hour session $500</li>
              <li class="f-14">16x20 Canvas Wall Mount $250</li>
              <li class="f-14">Additional Parent Album $300</li>
              <li class="f-14">Additional DVD with Images — $150</li>
              <li class="f-14">Rehearsal coverage $250</li>
            </ul>
          </div>
        `,
        title2: null,
        type: "abc",
        typeName: "Recursos discursivos",
        correct: 3,
        list: [
          {
            id: 0,
            text: "a) The Essential / The Basics / Additional Services and Add-Ons / The Premium / The Deluxe.",
          },
          {
            id: 1,
            text: "b) The Premium / The Deluxe / The Essential / The Basics / Additional Services and Add-Ons.",
          },
          {
            id: 2,
            text: "c) Additional Services and Add-Ons / The Premium / The Deluxe / The Essential.",
          },
          {
            id: 3,
            text: "d) The Basics / The Premium / The Essential / The Deluxe / Additional Services and Add Ons.",
          },
        ],
      },
    ],
  },
  /* Guia de destinos */
  {
    id: "13",
    menu: 2,
    title: "Guía de destinos",
    options: [
      { tutorial: "663b46afa35b6206065822d1" },
      { goldText: "guia_de_destinos" },
    ],
    exercices: [
      {
        adaptedSRC: false,
        font: "https://www.pinterest.es/pin/654640495816364710/",
        title:
          "Ordena de manera lógica un artículo sobre destinos turísticos para su correcta lectura. Para ello, deberás hacer ordenar cada párrafo según el orden lógico de la estructura de un artículo prototípico sobre destinos turísticos.",
        subtitle: null,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",
        list: [
          {
            id: 1,
            order: 7,
            text: "If you'd rather escape the crowds, Medewi in West Bali is one of Bali’s least-known waves. The point itself is covered in boulders, so it’s best for intermediate or experienced surfers, but you'll find 10km (6.2mi) of sandy-bottom beach breaks on either side of Medewi that rarely see another surfer.",
          },
          {
            id: 2,
            order: 5,
            text: "Canggu and Kuta Beach are the best hubs on the island for novice surfers. Canggu has plenty of board rental stands and surf schools offering lessons; check out Echo Beach for relatively mellow waves where you can find your feet. More board rental places and surf schools can be found along Kuta Beach, and the waves here are – under normal circumstances – even more chilled for learning.However, a lot of beginners find out the hard way that high tide at Kuta brings steep, heavy shore-breakers that are often challenging even for experienced surfers. Aim to have your lessons close to low tide and save the tougher waves for later in your surfing career.",
          },
          {
            id: 3,
            order: 2,
            text: "Whether you like to surf, practice yoga, trek on volcanoes, dive pristine reefs or go white-water rafting, Bali offers all sorts of dynamic and fascinating experiences. On the “island of the gods,” you’ll find an opportunity for relaxation and pampering, too – it's not all about adventure and adrenaline.",
          },
          {
            id: 4,
            order: 3,
            text: "To get you started, here's our pick of the best things to do on a trip to Bali.",
          },
          {
            id: 5,
            order: 1,
            text: "Mark Eveleigh</br>Oct 29, 2023",
          },
          {
            id: 6,
            order: 6,
            text: "With its unique Hindu culture, dramatic tropical landscapes and long tradition of delightful hospitality, Bali is one of the most exciting places for activities on the planet.",
          },
          {
            id: 7,
            order: 0,
            text: "<h3>The best of Bali: top ways to find your own piece of paradise</h3>",
          },
          {
            id: 8,
            order: 4,
            text: "<h6>Grab a surfboard and catch the Bali pipeline</h6></br> Bali is celebrated as one of the top spots for surfing on the planet. While the island is legendary for world-class waves such as Uluwatu, Padang Padang (“the Balinese Pipeline”) and the aptly named Impossibles, countless other spots are perfect for intermediate surfers or beginners.",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.worldnomads.com/explore/southeast-asia/indonesia",
        title:
          "Selecciona la respuesta correcta (a, b, c, d): ¿Qué palabras faltan en este artículo sobre un destino turístico?",
        subtitle:
            `
            <h3 class="my-1">A Traveler’s Guide to Ubud, Bali’s Spiritual Center</h3>
            <h5 class="mb-10">Avoid the crowds and delve into<span>_______</span>culture. Join Mwende Mutuli Musau (aka Tuli) as she<span>_______</span>serene temples, learns about the Hindu religion, and explores Bali’s gorgeous landscapes.</h5>
            <p>As a winner of the World Nomads Travel Content Creator Scholarship 2022, I received a paid travel opportunity to a country of my choice. I chose the island of Bali in Indonesia for its<span>_______</span>charm, rich culture, and ancient traditions. I spent five days in the town of Ubud – known as Bali’s spiritual and cultural centre – venturing off the<span>_______</span>trail in search of the truth behind the Balinese way of life.</p>
            <p>On my odyssey, I interacted with a Hindu priest named Gede who taught me about the gods and goddesses. I learned about the Hindu religion and how it entwines with Balinese<span>_______</span>language, and its outlook of life. Spirituality is entrenched in its society and is shown by individuals in the way they talk and act. The most important truth that I discovered was to live in harmony with all around me and see spiritual meanings in everything.</p>
            <h5 class="my-1">Crowd-free<span>_______</span>to visit in Ubud</h5>
            <p>A collection of ancient shrines chiselled into a rock cliff about 9mi (14km) northeast of Ubud, this striking temple complex is largely ignored by tourists who are missing out on its authentic and aesthetic architecture. Set deep in a ravine, the temple overlooks the Pakerisian River which also flows past the<span>_______</span>Titra Empul water temple about 2.5mi (4km) away.</p>
            `,
        title2: null,
        type: "abc",
        typeName: "Léxico",
        correct: 1,
        list: [
          {
            id: 0,
            text: "a) temples, alluring, discovers, busy, culture, tourist, Balinese.",
          },
          {
            id: 1,
            text: "b) Balinese, discovers, alluring, tourist, culture, temples, busy.",
          },
          {
            id: 2,
            text: "c) discovers, culture, Balinese, temples, tourist, busy, alluring.",
          },
          {
            id: 3,
            text: "d) alluring, Balinese, temples, discovers, busy, culture, tourist.",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.worldnomads.com/explore/southeast-asia/vietnam/top-things-to-see-and-do-in-ho-chi-minh-vietnam",
        title:
          "En este fragmento de un artículo sobre un destino turístico, las propuestas se formulan con verbos en imperativo. ¿Cuál de las opciones (a, b, c, d), donde los tiempos verbales se han modificado, es gramaticalmente correcta?",
        subtitle:
            `
            <h3 class="my-1">Beyond the Ben Thanh Market</h3>
            <p>The Ben Thanh Market is only for tourists. For a local experience, <u>head</u> to Xóm Chiếu Market in District 4. It’s noisy, smelly and crowded, but it also serves a delicious variety of street food, including one of my favourites, bún mắm (seafood noodle soup). This is an evening market, so <u>visit</u> it after 4pm.</p>
            <p>For a mid-morning market, <u>check out</u> Võ Duy Ninh Street, in Bình Thạnh District. It’s a vibrant neighbourhood where travellers are rare, and many of Vietnam’s wonderful culinary treats are on display, including frogs, duck and all kinds of fruits and vegetables.</p>
            `,
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 0,
        list: [
          {
            id: 0,
            text: `<p>a. The Ben Thanh Market is only for tourists. For a local experience, <strong><u>you can head</u></strong> to Xóm Chiếu Market in District 4. It’s noisy, smelly and crowded, but it also serves a delicious variety of street food, including one of my favourites, bún mắm (seafood noodle soup). This is an evening market, so <strong><u>you would better visit</u></strong> it after 4pm.</p>
                  <p>For a mid-morning market, <strong><u>you may check out</u></strong> Võ Duy Ninh Street, in Bình Thạnh District. It’s a vibrant neighbourhood where travellers are rare, and many of Vietnam’s wonderful culinary treats are on display, including frogs, duck and all kinds of fruits and vegetables.</p>
                  `,
          },
          {
            id: 1,
            text: `<p>b. The Ben Thanh Market is only for tourists. For a local experience, <strong><u>headed</u></strong> to Xóm Chiếu Market in District 4. It’s noisy, smelly and crowded, but it also serves a delicious variety of street food, including one of my favourites, bún mắm (seafood noodle soup). This is an evening market, so <strong><u>visited</u></strong> it after 4pm.</p>
                  <p>For a mid-morning market, <strong><u>checked out</u></strong> Võ Duy Ninh Street, in Bình Thạnh District. It’s a vibrant neighbourhood where travellers are rare, and many of Vietnam’s wonderful culinary treats are on display, including frogs, duck and all kinds of fruits and vegetables.</p>
                  `,
          },
          {
            id: 2,
            text: `<p>c. The Ben Thanh Market is only for tourists. For a local experience, <strong><u>will head</u></strong> to Xóm Chiếu Market in District 4. It’s noisy, smelly and crowded, but it also serves a delicious variety of street food, including one of my favourites, bún mắm (seafood noodle soup). This is an evening market, so will visit it after 4pm.</p>
                  <p>For a mid-morning market, <strong><u>will</u></strong> check out Võ Duy Ninh Street, in Bình Thạnh District. It’s a vibrant neighbourhood where travellers are rare, and many of Vietnam’s wonderful culinary treats are on display, including frogs, duck and all kinds of fruits and vegetables.</p>
                  `,
          },
          {
            id: 3,
            text: `<p>d. The Ben Thanh Market is only for tourists. For a local experience, <strong><u>has been heading</u></strong> to Xóm Chiếu Market in District 4. It’s noisy, smelly and crowded, but it also serves a delicious variety of street food, including one of my favourites, bún mắm (seafood noodle soup). This is an evening market, so <strong><u>has been visiting</u></strong> it after 4pm.</p>
                    <p>For a mid-morning market, <strong><u>have been checking out</u></strong> Võ Duy Ninh Street, in Bình Thạnh District. It’s a vibrant neighbourhood where travellers are rare, and many of Vietnam’s wonderful culinary treats are on display, including frogs, duck and all kinds of fruits and vegetables.</p>
                  `,
          }
        ],
      },
      {
        adaptedSRC: true,
        font: " https://www.worldnomads.com/explore/southeast-asia/cambodia/top-things-to-do-in-northwest-cambodia-from-siem-reap-to-battambang",
        title:
          "Selecciona la respuesta correcta (a, b, c, d). ¿Qué adjetivos faltan en los huecos del fragmento seleccionado de esta reseña de un libro?",
        subtitle:
            `
            <h3 class="my-1">Ancient temples hidden in the jungle, crumbling ruins on mountain tops, <span>_______</span> lakeside villages, vibrant floating markets, scenic waterfalls in <span>_______</span> national parks – Cambodia’s northwest has a lot to offer beyond Siem Reap.</h3>
            <p>The <span>_______</span> city of Siem Reap is home to a lot more than the temples of Angkor Wat. It is often the jump-off spot for most visitors to the Kingdom, who use the city as a home base to explore the nearby city of Battambang or to take a day trip to visit the stilted villages and <span>_______</span> markets on Tonle Sap.</p>
            <p>There are also several other Angkorian temple complexes nearby, including Koh Ker and Beng Mealea, where the <span>_______</span> ruins lie shrouded in <span>_______</span> jungle. Although you may have to hire a car or Tuk Tuk to get out of the city, once you’re there you can explore these long-abandoned temples in peace, walking in the footsteps of <span>_______</span> kings from years gone by.</p>
            `,
        title2: null,
        type: "abc",
        typeName: "Recursos discursivos",
        correct: 3,
        list: [
          {
            id: 0,
            text: "great, lush, ancient, thriving, floating, colorful, untouched",
          },
          {
            id: 1,
            text: "untouched, floating, colorful, lush, great, thriving, ancient",
          },
          {
            id: 2,
            text: "ancient, great, colorful, untouched, floating, thriving, lush",
          },
          {
            id: 3,
            text: "colorful, untouched, thriving, floating, ancient, lush, great",
          }
        ],
      },
    ],
  },
  /* Menu */
  {
    id: "14",
    menu: 2,
    title: "Menú",
    options: [
      { tutorial: "662b5bfba35b62014959abec" },
      { goldText: "menu" },
    ],
    exercices: [
      {
        adaptedSRC: false,
        font: "https://www.madisonlondon.net/menus/",
        title:
          "Ordena de manera lógica los siguientes fragmentos de un menú para que pueda leerse correctamente. Para ello, deberás hacer clic en cada párrafo según el orden lógico de la estructura de un menú prototípico.",
        subtitle: null,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",
        list: [
          {
            id: 1,
            order: 2,
            text: "<h4>Snacks</h4>",
          },
          {
            id: 2,
            order: 6,
            text: `
            <p class="text-center">Salted caramel chocolate brownie ........................ <strong>£7.50</strong></p>
            <p class="text-center">Lime leaf panna cotta ........................ <strong>£7.50</strong></p>
            <p class="text-center">Strawberry ice cream sundae ........................ <strong>£7.50</strong></p>
            <p class="text-center">Honeycomb cheescake ........................ <strong>£7.50</strong></p>
            `,
          },
          {
            id: 3,
            order: 3,
            text: "All our snacks are vegetarian.",
          },
          {
            id: 4,
            order: 1,
            text: "Opening at 11:30h.",
          },
          {
            id: 5,
            order: 5,
            text: "<h4>Desserts</h4>",
          },
          {
            id: 6,
            order: 0,
            text: "<h3>Madison London</h3>",
          },
          {
            id: 7,
            order: 7,
            text: "<h4>Drinks</h4>",
          },
          {
            id: 8,
            order: 8,
            text: `
            <p class="text-center">Sparkling water ........................ <strong>£5.50</strong></p>
            <p class="text-center">Still water ........................ <strong>£5.50</strong></p>
            <p class="text-center">Diet coke ........................ <strong>£4.50</strong></p>
            <p class="text-center">Apple juice ........................ <strong>£4.50</strong></p>
            `,          
          },
          {
            id: 9,
            order: 4,
            text: `
            <p class="text-center">Falafel with apricot harissa ........................ <strong>£11.50</strong></p>
            <p class="text-center">Houmous and roasted vegetables ........................ <strong>£11.50</strong></p>
            <p class="text-center">Giant Gordal olives ........................ <strong>£6.00</strong></p>
            <p class="text-center">Truffle and parmesan fries ........................ <strong>£8.00</strong></p>
            <p class="text-center">Wasabi nuts ........................ <strong>£6.00</strong></p>
            `, 
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.upmenu.com/blog/menu-descriptions/",
        title:
          "Selecciona la respuesta correcta (a, b, c, d): ¿Qué palabras y expresiones faltan para describir los siguientes platos de un menú?",
        subtitle:
            `
            <p>Italian tomato soup made with <span>_______</span> tomatoes and <span>_______</span> aromatic herbs ........... <strong>$6.50</strong></p>
            <p>Seafood soup, including shrimp, calamari and white fish, all <span>_______</span> a savory tomato broth <span>_______</span> spices<span> ........... </span><strong>$12.00</strong></p>
            `,
        title2: null,
        type: "abc",
        typeName: "Léxico",
        correct: 0,
        list: [
          {
            id: 0,
            text: "a) juicy, a blend of, simmered in, infused with.",
          },
          {
            id: 1,
            text: "b) crispy, marinated, seasoned with, available with.",
          },
          {
            id: 2,
            text: "c) simmered in, infused with, drizzled with, battered in.",
          },
          {
            id: 3,
            text: "d) marinated, juicy, on a bed of, simmered in.",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.upmenu.com/blog/menu-descriptions/",
        title:
          "Selecciona la respuesta correcta (a, b, c, d): ¿Qué palabras y expresiones faltan para describir los siguientes platos de un menú?",
        subtitle: "The sandwiches <u>are served</u> with pickle and side of the day. Also, our burgers <u>are hand pressed</u> daily from fresh lean ground beef.",
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 1,
        list: [
          {
            id: 0,
            text: "a) Para exponer los beneficios de los alimentos que se mencionan.",
          },
          {
            id: 1,
            text: "b) Para describir los platos del menú y su acompañamiento, en caso de haberlo.",
          },
          {
            id: 2,
            text: "c) Para narrar la elaboración de los platos.",
          },
          {
            id: 3,
            text: "d)  Para alertar a los clientes sobre la procedencia de los productos.",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://scholarsarchive.jwu.edu/menu_collection/972/",
        title:
          "Selecciona la respuesta correcta (a, b, c, d). ¿Cómo se deben expresar los nombres de los platos y los precios correspondientes en un menú? Fíjate en el ejemplo.",
        subtitle: `
          <h3 class="my-1">Rebecca’s Cafe</h3>
          <p>Lunch: served 12:30 – 15:00 p.m.</p>
          <h5 class="my-1"><strong>Sandwiches</strong></h5>
          <p>Ham and brie ................................................ £6.00</p>
          <p>Sardine, roasted pepper and sliced egg ........ £4.50</p>
          <p>Roast beef, cheddar and chutney .................... £5.00</p>
          <p>Roast chicken .................................................. £5.50</p>
          <h5 class="my-1"><strong>Desserts</strong></h5>
          <p>Individual fruit tartes .................................... £3.00</p>
          <p>Chocolate mousse cake .............................. £2.00</p>
          <p>Banana cake .................................................. £2.50</p>
          <h5 class="my-1"><strong>Drinks</strong></h5>
          <p>Soda ............................................................ £1.00</p>
          <p>Coffee ......................................................... £1.50</p>
          <p>Tea .............................................................. £1.50</p>
          <p>Orange juice .............................................. £2.00</p>
        `,
        title2: null,
        type: "abc",
        typeName: "Recursos discursivos",
        correct: 3,
        list: [
          {
            id: 0,
            text: "a) En una narración.",
          },
          {
            id: 1,
            text: "b) En una imagen.",
          },
          {
            id: 2,
            text: "c) En un esquema.",
          },
          {
            id: 3,
            text: "d) En una lista.",
          },
        ],
      },

    ],
  },
  /* Viaje */
  {
    id: "15",
    menu: 2,
    title: "Guia de viajes",
    options: [
      { tutorial: "663b46afa35b6206065822db" },
      { goldText: "guia_de_viajes" },
    ],
    exercices: [
      {
        adaptedSRC: false,
        font: "https://www.lonelyplanet.com/articles/getting-around-tokyo",
        title:
          "Ordena los párrafos que aparecen a continuación siguiendo el esqueleto que se presenta más abajo, donde aparece el título y los subtítulos del texto original.",
        subtitle: null,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",
        list: [
          {
            "id": 1,
            "order": 11,
            "text": "Tokyo’s “water buses” look like robotic beetles skimming across the placid waters of its eastern river networks. Thanks to their glass-walled exteriors, they’re a great option for taking in the sights of Tokyo Bay while traveling between Asakusa and the likes of Odaiba, Toyosu and Hama-rikyū Gardens. Journeys will cost between ¥460 and ¥1720 yen ($3.40–12.50), depending on distance, and are generally more comfortable than their terrestrial public transport counterparts."
          },
          {
            "id": 2,
            "order": 15,
            "text": "Considering the traffic, the confusing and often excruciatingly narrow roads, and the ridiculous cost of parking, it’s best not to use a car for getting around Tokyo; even most Tokyoties don’t bother owning a vehicle. Day trips can easily be done by public transport, though renting a car will expand your options and allow you to explore regions rarely visited by the touring masses."
          },
          {
            "id": 3,
            "order": 1,
            "text": "The world’s most populous city, Tokyo has a metropolitan area that sprawls over more than 5,000 sq miles. Fortunately, thanks to a public transport system that’s efficient, reliable, clean and generally safe, getting around the region is easy. Of most use to travelers is the train and subway system, which is simple to navigate thanks to English signage and color-coded lines. But don’t feel obliged to use trains for every journey. In spite of unpredictable traffic patterns, buses tend to be equally punctual and can be useful for short journeys in quieter suburbs. Tokyo’s relatively flat topography also means cycling and walking don’t require too much of a sweat, and allow you to explore neighborhoods you’d otherwise bypass on the underground. Here is all you need to know about getting around Tokyo."
          },
          {
            "id": 4,
            "order": 13,
            "text": "Getting a taxi in Tokyo only makes economic sense for short distances or when in groups of four – unless you’re stranded during the lull in nightly train operations. All cabs run by the meter, with fares starting at ¥500 ($3.60) for the first 1km (0.6 miles) and then rising by ¥100 (73 cents) for every 255m (836ft) you travel or for every 90 seconds spent in traffic. When traveling longer distances, this starts to add up at a rather alarming rate, especially when you factor in nighttime surcharges of 20-30% between 10pm and 5am, and potential highway tolls."
          },
          {
            "id": 5,
            "order": 10,
            "text": "Tokyo’s extensive rail network includes Japan Railways (JR) lines, a subway system and private commuter lines that depart in every direction for the suburbs like spokes on a wheel. Trains arrive and depart precisely on time and are generally clean and pleasant, though they get uncomfortably crowded during rush hours and late at night. For a short window in the morning and early evenings, many trains have women-only carriages."
          },
          {
            "id": 6,
            "order": 12,
            "text": "At first glance, Tokyo doesn’t seem like a bicycle-friendly city: dedicated lanes are almost nonexistent on major thoroughfares, cyclists often come up against pedestrian overpasses that need to be scaled (though a few have ramps for walking bikes up and down), and you’ll see no-parking signs for bicycles everywhere. Despite all this, you’ll also see plenty of pedaling locals. This is because Tokyo is a largely flat city, and much bike-friendlier if you’re cycling through city parks, in residential neighborhoods, along the river promenades near Tokyo Bay or around the maze of backstreets."
          },
          {
            "id": 7,
            "order": 9,
            "text": "Toei runs an extensive bus network in Tokyo, though it’s only more convenient than the subway when you’re in the outer suburbs or making short inner city jaunts. Fares are ¥210 ($1.50) for adults and ¥110 (80c) for children. Pay by IC pass or deposit your fare into the box as you enter the bus; if your pass is out of credit, you can charge it at the front of the bus. There’s also a change machine by the driver’s seat that accepts ¥1000 notes. Most buses have digital signage that switches between Japanese and English."
          },
          {
            "id": 8,
            "order": 0,
            "text": "How to get around Tokyo"
          },
          {
            "id": 9,
            "order": 2,
            "text": "David McElhinney </br> Apr 1, 2021"
          },
          {
            "id": 10,
            "order": 3,
            "text": "Go further, faster, by train"
          },
          {
            "id": 11,
            "order": 4,
            "text": "See more of Tokyo on a bus"
          },
          {
            "id": 12,
            "order": 5,
            "text": "Get a fresh view of Tokyo from a water bus"
          },
          {
            "id": 13,
            "order": 6,
            "text": "After a late night out consider a taxi"
          },
          {
            "id": 14,
            "order": 7,
            "text": "Cycling in Tokyo"
          },
          {
            "id": 15,
            "order": 8,
            "text": "Think twice about that car"
          }
        ]
      ,
      },
      {
        adaptedSRC: true,
        font: "https://www.lonelyplanet.com/articles/getting-around-fiji",
        title: "Selecciona la opción correcta (a, b, c, d) para rellenar los huecos (vocabulario) que aparecen a continuación.",
        subtitle:
            `
            <h2 class="my-1">How to get around Fiji whatever your budget</h2>
            <p>Scattered amid the azure waters of the South Pacific, Fiji is made up of 300 or so islands. Sailing is undoubtedly one of the best ways of navigating this paradisiacal archipelago (with the best time to visit being between May and October), but there are plenty of ways to get around if you don’t have your own <span>_______</span>. Viti Levu is Fiji’s main and largest island and it is also where you’ll start your journey after <span>_______</span> at the international airport just outside the city of Nadi. If you’re wondering what’s the best mode of transport to go from there, here is a selection of options to get your Fijian adventure underway.</p>
            <h3 class="my-1">Navigate bigger islands by car or motorcycle</h3>
            <p>If you want to do things at your own pace and you’re confident about being behind the wheel in a foreign country, one of the best ways of touring Viti Levu is by <span>_______</span> a car. Driving is on the left and the main <span>_______</span> are paved. The road network is also largely devoid of traffic other than some minor congestion in the cities and during bad weather.</p>
            <h3 class="my-1">Save money with public transport</h3>
            <p>If you’re traveling on a budget or fancy getting a taste of local life, buses are the best way to get around Fiji’s main islands. Around 95% of the country’s roads are found on Viti Levu and the neighboring island of Vanua Levu with frequent bus services running around both. <span>_______</span> start from just 70 Fijian cents and run up to around $20 (US$10) for the longest multistop <span>_______</span> on offer.</p>
            <h3 class="my-1">Upgrade for more comfort with a taxi</h3>
            <p>There is a variety of taxi companies <span>_______</span> across the Fijian islands of Viti Levu, Vanua Levu, Taveuni, and Ovalau. Taxis can usually be found next to bus depots in the main towns, and hotels will be able to help you arrange <span>_______</span> for excursions or airport services. Some taxis run on meters while others don't, so it’s wise to agree on a fare before setting off.</p>
            `,
        title2: null,
        type: "abc",
        typeName: "Léxico",
        correct: 2,
        list: [
          {
            id: 0,
            text: "a) roads, operating, landing, fares, roads, transfers, hiring, boat.",
          },
          {
            id: 1,
            text: "b) journey, hiring, operating, transfers, journey, boat, landing, fares.",
          },
          {
            id: 2,
            text: "c) boat, landing, hiring, roads, fares, journey, operating, transfers.",
          },
          {
            id: 3,
            text: "d) landing, transfers, boat, journey, operating, fares, transfers, roads.",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.lonelyplanet.com/articles/getting-around-dublin",
        title: "Selecciona la opción correcta (a, b, c, d) para rellenar los huecos (tiempos verbales) que aparecen a continuación.",
        subtitle: `<p><span>_______</span> around Dublin without a car <span>_______</span> easy. The compact city <span>_______</span> well served by an efficient network of buses, trams and trains that <span>_______</span> the city and the suburbs. No more than a few square kilometers, the flat city center <span>_______</span> highly walkable and cyclable too, with public bike-sharing schemes <span>_______</span> bicycles accessible to everyone. Comfortable shoes and rain gear <span>_______</span> a smart choice at any time of year.</p>`,
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 1,
        list: [
          {
            id: 0,
            text: "a) To get, is being, will be, is connecting, will be, make, is.",
          },
          {
            id: 1,
            text: "b) Getting, is, is, connect, is, making, are.",
          },
          {
            id: 2,
            text: "c) Getting, is being, is, is connecting, is, make, are.",
          },
          {
            id: 3,
            text: "d) To get, is, will be, connect, will be, making, is.",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.lonelyplanet.com/articles/getting-around-the-bahamas",
        title: "Selecciona la opción correcta (a, b, c, d) para rellenar los huecos (conectores) que aparecen a continuación.",
        subtitle: `
          <h5 class="my-1 text-center">How to get around in the Bahamas: Everything you need to know </h5>
          <p><span>_______</span> around the Bahamas requires a bit of planning, you'll definitely be rewarded with white-sand beaches and fun water activities. The food, the drinks, the amenities – <span>if</span> you're staying in a resort in the Bahamas, it's likely you won't want to leave once you land. <span>To</span> take full advantage of all the 700-plus islands have to offer, you'll have to head out. <span>Admittedly</span>, getting around the Bahamas is a little tricky with inter-island flights or ferries, but not a major hassle if you plan ahead.</p>
          <p class="mt-10"><strong>To see more of the country, go by car</strong></p>
          <p>Hitting the road in the Bahamas is a great way to cover more ground and explore the Out Islands. Driving in the Bahamas, <span>especially</span> in Nassau, is filled with one-way streets, narrow roads and heavy traffic. <span>If</span> you drive in the Bahamas, a valid US passport is required, but if your stay is longer than three months, an international driver's license is needed. Major rental-car companies, <span>such as</span> Hertz, Alamo, National, Avis, Budget, Dollar and Thrifty, have offices on most islands. Taxis are another car option for getting around, and there's no shortage of licensed drivers. Most taxis have a fixed rate, <span>though</span> some are metered, <span>so</span> double-check before hopping in one.</p>
        `,
        title2: null,
        type: "abc",
        typeName: "Recursos discursivos",
        correct: 2,
        list: [
          {
            id: 0,
            text: "a) Although, especially, So, If, such as, But, admittedly, though, in order to.",
          },
          {
            id: 1,
            text: "b) So, admittedly, In order to, But, though, Especially, if, such as, although.",
          },
          {
            id: 2,
            text: "c) Though, if, But, Admittedly, especially, In order to, such as, although, so.",
          },
          {
            id: 3,
            text: "d) Admittedly, but, If, Especially, in order to, Such as, although, so, though.",
          },
        ],
      },
    ],
  },
  /* Receta */
  {
    id: "16",
    menu: 2,
    title: "Receta",
    options: [
      { tutorial: "662b5bfaa35b62014959abe2" },
      { goldText: "receta" },
    ],
    exercices: [
      {
        adaptedSRC: true,
        font: "https://www.bbcgoodfood.com/recipes/luxury-houmous",
        title: "A continuación, verás el orden de los elementos de una receta. La lista siguiente incluye el orden correspondiente a una receta:",
        subtitle: `
        <ol>
          <li>Título de la receta</li>
          <li>Indicación del tiempo de preparación y cocinado</li>
          <li>Nivel de dificultad</li>
          <li>Número de raciones</li>
          <li>Ingredientes</li>
          <li>Instrucciones</li>
          <li>Consejos y trucos</li>
        </ol>
        `,
        title2: "Ordena de manera lógica cada uno de los párrafos que verás a continuación. Para ello, deberás arrastrarlos.",
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",
        list: [
          {
            id: 1,
            order: 4,
            text: `
              <div class="w-full">
                <strong>Ingredients</strong>
                <ul>
                  <li>700g chickpeas, drained</li>
                  <li>135ml extra virgin olive oil, plus extra for drizzling</li>
                  <li>2 garlic cloves, roughly chopped</li>
                  <li>1 tbsp tahini</li>
                  <li>1½ lemons, juiced</li>
                </ul>            
                <strong>For the toppings</strong>
                <ul>
                  <li>½ tsp smoked paprika</li>
                  <li>½ tsp sumac</li>
                  <li>½ small pack parsley, roughly chopped</li>
                  <li>40g pomegranate seeds</li>
                  <li>crudités and warm pittas, to serve</li>
                </ul>            
              </div>
            `,
          },
          {
            id: 2,
            order: 3,
            text: `Number of servings: 8`,
          },
          {
            id: 3,
            order: 1,
            text: `Prep: 20 mins <br><br>Cook: 5 mins`,
          },
          {
            id: 4,
            order: 0,
            text: `<h3>Luxury hummus</h3>`,
          },
          {
            id: 5,
            order: 6,
            text: `
              <div class="w-full">
                <strong>Recipe tips</strong>
                <ul>
                  <li>Use good-quality chickpeas to give this vegan hummus a really silky feel.</li>
                </ul>
              </div>
            `,
          },
          {
            id: 6,
            order: 5,
            text: `
              <div class="w-full">
                <strong>Method</strong>
                <p><div class="mt-10">STEP 1</div><br> Blitz ¾ of the chickpeas and 120ml of the oil with the rest of the hummus ingredients and a good amount of seasoning in a food processor. Add a little water if it is too thick. Spoon the hummus into a serving bowl or spread it onto a plate. Can be made up to two days in advance and kept in the fridge.</p>
                <p><div class="mt-10">STEP 2</div><br> Dry the rest of the chickpeas on kitchen paper as much as possible. Heat the remaining oil in a frying pan over a medium heat. Add the chickpeas and a large pinch of salt, and fry until golden, around 4 mins. Drain on kitchen paper.</p>
                <p><div class="mt-10">STEP 3</div><br> Drizzle some oil over the hummus, then sprinkle with the spices, parsley and pomegranate seeds. Scatter the fried chickpeas on top and serve with crudités and warm pitta breads.</p>
              </div>
            `,
          },
          {
            id: 7,
            order: 2,
            text: `Difficulty: easy`,
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.bbcgoodfood.com/recipes/creamy-spinach-chicken",
        title: "Selecciona la respuesta correcta (a, b, c, d): ¿Qué palabras faltan en esta receta?",
        subtitle:
            `
              Creamy spinach chicken <br>
              Preparation and cooking time <br>
              Prep: 10 mins <br>
              Cook: 30 mins <br>
              Difficulty: Easy <br>
              Number of servings: 4 <br>

              <div class="mt-10"><strong>Ingredients</strong></div>
              <ul>
                <li>30g unsalted butter</li>
                <li>240g baby spinach</li>
                <li>4 chicken breasts, skin removed, sliced crosswise into strips</li>
                <li>4 garlic cloves, finely chopped or grated</li>
                <li>200ml double cream</li>
                <li>40g Parmigiano Reggiano, finely grated</li>
                <li>cooked rice or potatoes, to serve (optional)</li>
              </ul> 
              <div class="mt-10"><strong>Method </strong></div>

              <div class="my-1"><span class="my-10">STEP 1</span></div>
              <p> Melt half the butter in a large frying pan over a medium heat and gently wilt the spinach, about 2-3 mins. Stir and season well. Remove to a colander to drain.</p>
              <div class="my-1"><span class="my-10">STEP 2</span></div>
              <p>If any liquid remains in the pan, <span>_________</span> and <span>_________</span>. Melt the rest of the butter in the same pan over a medium heat, swirling it so the base of the pan is covered. Fry the chicken for 1-2 mins, stirring until lightly golden. Season well. Add the garlic and fry for 1 min more, then <span>_________</span> the cream and simmer for 15 mins, stirring often until the chicken has cooked through – be careful not to let the cream boil.</p>
              <div class="my-1"><span class="my-10">STEP 3</span></div>
              <p><span>_________</span> 30g of the cheese and all the spinach, squeezing out any excess liquid first. Simmer for another 5 mins until the cheese has melted and the chicken is cooked through. To check the chicken is ready, pierce the thickest part of the meat to ensure the juices run clear.</p>
              <div class="my-1"><span class="my-10">STEP 4</span></div>
              <p>Scatter with the remaining cheese and serve the chicken with plenty of the sauce, alongside rice or potatoes, if you like.</p>
            `,
        title2: null,
        type: "abc",
        typeName: "Léxico",
        correct: 2,
        list: [
          {
            id: 0,
            text: "a) thaw it out, wipe it out, chop up, Plate up.",
          },
          {
            id: 1,
            text: "b) simmer it up, bring it up, shake up, Eat out.",
          },
          {
            id: 2,
            text: "c) pour it away, wipe it out, pour in, Stir in.",
          },
          {
            id: 3,
            text: "d) pick it down, sear it up, peel off, Wipe out.",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.bbcgoodfood.com/recipes/cheese-oat-spring-onion-soda-bread",
        title: "Selecciona la respuesta correcta (a, b, c, d). El siguiente párrafo es un fragmento de una receta. ¿En qué tiempo verbal están los verbos?",
        subtitle: `<u>Transfer</u> the dough to the prepared tray and flatten it slightly into a thick disc using your palm. <u>Use</u> a sharp knife to cut a very deep cross into the top, then <u>scatter</u> with the remaining cheese and a small handful of oats. <u>Bake</u> for 35-45 mins until deeply browned and the loaf sounds hollow when tapped on the bottom. <u>Leave</u> to cool on a wire rack before slicing and serving.`,
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 1,
        list: [
          {
            id: 0,
            text: "a) En infinitivo.",
          },
          {
            id: 1,
            text: "b) En imperativo.",
          },
          {
            id: 2,
            text: "c) En presente simple.",
          },
          {
            id: 3,
            text: "d) En participio.",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.bbcgoodfood.com/recipes/double-cheese-spring-vegetable-tart",
        title: " Selecciona la respuesta correcta (a, b, c, d). ¿Qué conectores faltan en los huecos de este fragmento de una receta?",
        subtitle: `
          <p>Roll the pastry out into a rectangle on a work surface lightly dusted with flour.<span>_________</span>, scatter over the cheese, fold the pastry in half and roll out again into a circle that fits a 25cm tart tin with an overhang. Chill for 20 mins. <span>_________</span>, cook the asparagus in boiling water for 3 mins, <span>_________</span> drain and refresh under cold water. Cook the fresh peas the same way for a minute, or simply defrost the frozen peas.</p>
        `,
        title2: null,
        type: "abc",
        typeName: "Recursos discursivos",
        correct: 3,
        list: [
          {
            id: 0,
            text: "a) Previously, Meanwhile, therefore",
          },
          {
            id: 1,
            text: "b) Meanwhile, Previously, as well as",
          },
          {
            id: 2,
            text: "c) Yet, Finally, then",
          },
          {
            id: 3,
            text: "d) Next, Meanwhile, then",
          },
        ],
      },
    ],
  },
  /* Factura */
  {
    id: "17",
    menu: 2,
    title: "Factura",
    options: [
      { tutorial: "662766c5a35b6250fc642df2" },
      { goldText: "factura" },
    ],
    exercices: [
      {
        adaptedSRC: true,
        font: "https://www.beginner-bookkeeping.com/sales-invoice-template.html",
        title: " Ordena de manera lógica las imágenes correspondientes en el ejemplo de factura (invoice) a continuación. Para ello, deberás hacer clic en cada párrafo según el orden previsto.",
        subtitle: null,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",
        list: [
          {
            id: 1,
            order: 3,
            text: "<img src='/images/factura/image0.png'/>",
          },
          {
            id: 2,
            order: 4,
            text: "<img src='/images/factura/image1.png'/>",
          },
          {
            id: 3,
            order: 1,
            text: "<img src='/images/factura/image2.png'/>",
          },
          {
            id: 4,
            order: 2,
            text: "<img src='/images/factura/image3.png'/>",
          },
          {
            id: 5,
            order: 0,
            text: "<img src='/images/factura/image4.png'/>",
          }
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.deskera.com/blog/invoicing-101-for-small-business-with-free-invoice-templates/ ",
        title: "Selecciona la respuesta correcta (a, b, c, d): ¿Qué palabras faltan en los huecos de esta factura (invoice)?",
        subtitle:
            `
            <div class="mb-10">Invoice 000023  January 27 2020</div>
            <p>ATTN: Lee Hummings | Procurement</p>
            <p>412 Cherry Street, Dodge City, KS 12312</p>
            <p>+1 231-123-4125</p>
            <div class="my-1">Lee.hummings@maplemattresses.com</div>

            <table class="w-full mb-10">
              <tr>
                <th class="text-start pb-10">ITEM</th>
                <th class="text-start pb-10">_________</th>
                <th class="text-start pb-10">UNIT PRICE</th>
              </tr>
              <tr>
                <td>Flamingo Print Pillow Case</td>
                <td>2 unit</td>
                <td>$123.45</td>
              </tr>
              <tr>
                <td>Gold Foil Pillow Case</td>
                <td>1 unit</td>
                <td>$12.34</td>
              </tr>
              <tr>
                <td class="pb-10">Polka Dots Fitted Sheet</td>
                <td class="pb-10">1 unit</td>
                <td class="pb-10">$1.23</td>
              </tr>
              <tr class="separador">
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="pt-10">_________</td>
                <td class="pt-10"></td>
                <td class="pt-10">$567.89</td>
              </tr>
              <tr>
                <td>APPLIED DISCOUNT</td>
                <td></td>
                <td>12%</td>
              </tr>
              <tr>
                <td>SALES _________ PAYABLE</td>
                <td></td>
                <td>N/A</td>
              </tr>
              <tr>
                <td>FREIGHT/DELIVERY</td>
                <td></td>
                <td>FREE</td>
              </tr>
              <tr>
                <td class="pb-10">TOTAL DUE</td>
                <td class="pb-10"></td>
                <td class="pb-10">$1901.23</td>
              </tr>
              <tr class="separador">
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
            <div class="mb-10">
              <p><span>_________</span> can be made via:</p>
              <p>Paypal: payment@maplemattresses.com</p>
              <p>Direct Deposit: Maple Mattresses</p>
            </div>
            <p>Acc. No. 1234 5678 910</p>
            <p>Please make payment within 30 <span>_________</span> of invoice issued.</p>
            <p>Maple Mattresses | +1 123-456-7891 | sales@maplemattresses.com</p>
            `,
        title2: null,
        type: "abc",
        typeName: "Léxico",
        correct: 1,
        list: [
          {
            id: 0,
            text: "a) TAX , PAYMENT, ITEMS, Quality, Bizum",
          },
          {
            id: 1,
            text: "b) QUANTITY, SUBTOTAL, TAX, Payment, days",
          },
          {
            id: 2,
            text: "c) PAYMENT, SUBTOTAL, DEADLINE, Paypal, months",
          },
          {
            id: 3,
            text: "d) TOTAL, ITEMS, TAXES, Payment, number",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: null,
        title: "Elige la opción correcta (a, b, c, d) para cada hueco en la siguiente factura (invoice).",
        subtitle: `
          <div class="mt-10">
            <p>Hi Nicole, we <span>_________</span> (APPLY) 15% discount as a thank-you for referring your neighbour, Pat. Thank you for your ongoing business and we <span>_______</span> forward to working with you again!</p>
          </div>
          <div class="mt-10">
            <p>DISCLAIMER: Payment <span>_________</span> (BE) accepted by cash, cheque, or credit card. If you <span>_________</span> (PAY) by credit card online, use Jobber client hub. Cheques must be made out to Service Provider LLC.</p>
          </div>
          <div class="mt-10">
            <p>Subtotal <strong>$300.00</strong></p>
          </div>
          <div class="mt-10">
            <p>Discount (15.0%) - <strong>$45.00</strong></p>
            <p>GST (5.0%) <strong>$12.75</strong></p>
            <p>Total <strong>$267.75</strong></p>
          </div>
        `,
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 2,
        list: [
          {
            id: 0,
            text: "a) was applied/ look/ is/paid",
          },
          {
            id: 1,
            text: "b) applied/ look/ are/are paying",
          },
          {
            id: 2,
            text: "c) have applied/ look/ is/are paying",
          },
          {
            id: 3,
            text: "d) apply/ looked/ is/are paying",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://squareup.com/ca/en/townsquare/invoice-examples",
        title: "Selecciona la respuesta correcta (a, b, c, d). ¿Qué datos faltan en los huecos del fragmento seleccionado de esta factura (invoice)?",
        subtitle: `
        <div class="mb-10">
          <p>Ellis Photography</p>
          <p>New Invoice</p>
          <p>$914.00 due on Mar. 1, 2017</p>
        </div>
        <div class="mb-10">
          <p>Invoice <span>_________</span></p>
          <p>February 6, <span>_________</span></p>
        </div>
        <div class="mb-10">
        <p>Customer</p>
          <p>Patricia Cass</p>
          <p>patriciacass@square.com</p>
        </div>
        <div class="mb-10">
          <p>Patricia, | can't wait to meet your fiancé and conduct your photo session! If you have any questions, please let me know.</p>
          <p>As a reminder, your deposit is due at least 30 days prior to your photoshoot which we have scheduled for <span>_________</span></p>
        </div>
        <table class="w-full mb-10">
          <tr>
            <td>Engagement Photo Session</td>
            <td>$800.00</td>
          </tr>
          <tr>
            <td>4 hours</td>
            <td>$200 per hour</td>
          </tr>
          <tr>
            <td>Travel Expenses - bridge toll, parking</td>
            <td><span>_________</span></td>
          </tr>
          <tr>
            <td>Subtotal</td>
            <td>$850.00</td>
          </tr>
          <tr>
            <td>Tax</td>
            <td>$64.00</td>
          </tr>
          <tr>
            <td>Total Due</td>
            <td>$914.00</td>
          </tr>
        </table>

        `,
        title2: null,
        type: "abc",
        typeName: "Recursos discursivos",
        correct: 0,
        list: [
          {
            id: 0,
            text: "a) #476, 2017, 4/1/17, $50.00",
          },
          {
            id: 1,
            text: "b) March 2016, 2018, $50.00, 6 hours",
          },
          {
            id: 2,
            text: "c) Next summer, #476, 2018, $50.00",
          },
          {
            id: 3,
            text: "d) $50.00, later, March 2016, 2016",
          },
        ],
      },
    ],
  },
  /* Catálogo de hotel */
  {
    id: "18",
    menu: 2,
    title: "Catálogo de hotel",
    options: [
      { tutorial: "662b5bfaa35b62014959abe7" },
      { goldText: "catalogo_de_hotel" },
    ],
    exercices: [
      {
        adaptedSRC: true,
        font: "https://www.booking.com/hotel/gb/four-seasons-london-at-park-lane.en-gb.html",
        title: "Ordena de manera lógica los siguientes fragmentos de un catálogo de hotel para que pueda leerse correctamente. Para ello, deberás arrastrar cada imagen según el orden lógico de su estructura.",
        subtitle: null,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",
        list: [
          {
            id: 0,
            order: 1,
            text: "<img src='/images/hotel/image0.png'/>",
          },
          {
            id: 1,
            order: 3,
            text: "<img src='/images/hotel/image1.png'/>",
          },
          {
            id: 2,
            order: 0,
            text: "<img src='/images/hotel/image2.png'/>",
          },
          {
            id: 3,
            order: 2,
            text: "<img src='/images/hotel/image3.png'/>",
          }
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.booking.com/hotel/gb/the-ritz-london.en-gb.html",
        title: "Selecciona la respuesta correcta (a, b, c, d): ¿Qué adjetivos faltan para describir el hotel del siguiente texto?",
        subtitle:
            `
              <p>Overlooking London’s Green Park is the world-famous The Ritz London. This <span>_________</span> Neoclassical building offers the height of opulence with <span>_________</span> bedrooms, <span>_________</span> British cuisine, and classic afternoon teas. Bedrooms at The Ritz London combine stunning period architecture and <span>_________</span> furnishings with <span>_________</span> amenities.</p>
            `,
        title2: null,
        type: "abc",
        typeName: "Léxico",
        correct: 2,
        list: [
          {
            id: 0,
            text: "a) luxurious, modern, good, old, modern",
          },
          {
            id: 1,
            text: "b) vibrant, luxurious, timeless, modern, old",
          },
          {
            id: 2,
            text: "c) stunning, luxurious, exquisite, antique, modern",
          },
          {
            id: 3,
            text: "d) modern, timeless, tasty, good, modern",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://www.booking.com/hotel/gb/four-seasons-london-at-park-lane.en-gb.html",
        title: "Selecciona la respuesta correcta (a, b, c, d). En la siguiente descripción de una habitación, ¿qué estructura gramatical es la predominante?",
        subtitle: `<img class="w-full" src='/images/hotel/subtitle.png'/>`,
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 0,
        list: [
          {
            id: 0,
            text: "a) Los sintagmas nominales",
          },
          {
            id: 1,
            text: "b) Las frases sencillas",
          },
          {
            id: 2,
            text: "c) El imperativo",
          },
          {
            id: 3,
            text: "d) Adjetivos positivos sobre el hotel",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://learnenglishkids.britishcouncil.org/read-write/writing-practice/level-2-writing/restaurant-menu",
        title: "Selecciona la respuesta correcta (a, b, c, d). ¿Qué estructura verbal es la más adecuada en cada caso?",
        subtitle: `<p>Guests <span>_________</span> to indulge in the culinary wonders of Pavyllon London, a testament to Chef Yannick Alléno’s modern French dining, while Bar Antoine <span>_________</span> a spirited escape. The hotel <span>_________</span> 196 beautifully designed rooms and suites, set against the backdrop of 11 stories. The exquisite rooftop spa, designed by Eric Parry, is a sleek, glass-walled haven <span>_________</span> panoramic views of Hyde Park and Westminster.</p>`,
        title2: null,
        type: "abc",
        typeName: "Recursos discursivos",
        correct: 3,
        list: [
          {
            id: 0,
            text: "a) invite/is offered/features/offered",
          },
          {
            id: 1,
            text: "b) are invited/is offering/featured/has offered",
          },
          {
            id: 2,
            text: "c) are invited/featured/is featuring/offering",
          },
          {
            id: 3,
            text: "d) are invited/offers/features/offering",
          },
        ],
      },
    ],
  },
  /* Folleto */
  {
    id: "19",
    menu: 2,
    title: "Folleto",
    options: [
      { tutorial: "663b46afa35b6206065822c2" },
      { goldText: "folleto" },
    ],
    exercices: [
      {
        adaptedSRC: true,
        font: "https://venngage.com/templates/brochures/utah-travel-tri-fold-brochure-321d55a2-5bf0-469f-b323-8e8212288058",
        title: "Ordena de manera lógica los siguientes fragmentos de un Brochure para que pueda leerse correctamente. Para ello, deberás arrastrar cada imagen según el orden lógico de su estructura.",
        subtitle: null,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",
        list: [
          {
            id: 0,
            order: 1,
            text: "<img src='/images/folleto/image0.png'/>",
          },
          {
            id: 1,
            order: 2,
            text: "<img src='/images/folleto/image1.png'/>",
          },
          {
            id: 2,
            order: 0,
            text: "<img src='/images/folleto/image2.png'/>",
          },
          {
            id: 3,
            order: 3,
            text: "<img src='/images/folleto/image3.png'/>",
          }
        ],
      },
      {
        adaptedSRC: true,
        font: "https://venngage.com/templates/brochures/tokyo-gradient-professional-travel-tri-fold-brochure-3f1ed75a-3de0-4e44-b095-74350cd525d7",
        title: "Selecciona la respuesta correcta (a, b, c, d): ¿Qué adjetivos faltan para describir el hotel del siguiente texto?",
        subtitle:
            `
            <p>Tokyo knows how to have fun. From the bright lights and bustle of downtown Shibuya to <span>____</span> art galleries, and <span>____</span> events. Tokyo’s night scene is about <span>____</span> new experiences. Yokocho is Japanese for ‘alleyway’ but now it simply refers to <span>____</span> streets with izakayas, bars and eateries tucked away in Japan’s busy streets. That’s where you want to be for an <span>____</span> taste of Japanese cuisine.</p>
            `,
        title2: null,
        type: "abc",
        typeName: "Léxico",
        correct: 2,
        list: [
          {
            id: 0,
            text: "a) modern, late-night, good, traffic, real",
          },
          {
            id: 1,
            text: "b) alfresco, narrow, amazing, big, authentic",
          },
          {
            id: 2,
            text: "c) late night, alfresco, thrilling, narrow, authentic",
          },
          {
            id: 3,
            text: "d) narrow, authentic, real, open, colourful",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://venngage.com/templates/brochures/tokyo-gradient-professional-travel-tri-fold-brochure-3f1ed75a-3de0-4e44-b095-74350cd525d7",
        title: "Selecciona la respuesta correcta (a, b, c, d). En la siguiente descripción de un brochure, ¿cuál es el tiempo verbal más apropiado?",
        subtitle: `
        <p>All you <span>_________</span> is a few hundred yen and a towel</p>
        <p>The station area <span>_________</span> hundreds of delicious food options from high-quality Chinese to upscale Italian fancy dining bars</p>
        <p>There is a Japanese comfort food favourite called okonomiyaki <span>_________</span> right before your eyes.</p>
        <p>Niijima is a scenic surfing haven and popular holiday spot easy to <span>_________</span> from Tokyo.</p>
        <p>Swim, surf, sunbathe, cycle or <span>_________</span> the local art – Niijima is an island with plenty of options.</p>
        `,
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 3,
        list: [
          {
            id: 0,
            text: "a) needed, offered, made, reaching, admiring",
          },
          {
            id: 1,
            text: "b) need, offer, make, reach, admired",
          },
          {
            id: 2,
            text: "c) needing, offering, has made, has admired",
          },
          {
            id: 3,
            text: "d) need, offers, made, reach, admire",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://venngage.com/templates/brochures/tokyo-gradient-professional-travel-tri-fold-brochure-3f1ed75a-3de0-4e44-b095-74350cd525d7",
        title: "Selecciona la respuesta correcta (a, b, c, d): Qué  adjetivos serían la más apropiadas para los siguientes sustantivos?",
        subtitle: `
        <h5><span>_________</span> waves</h5>
        <h5><span>_________</span> scenes</h5>
        <h5><span>_________</span> experiences</h5>
        <h5><span>_________</span> bars</h5>
        <h5><span>_________</span> food</h5>
        `,
        title2: null,
        type: "abc",
        typeName: "Recursos discursivos",
        correct: 1,
        list: [
          {
            id: 0,
            text: "a) colourful,   public,  cultural,  rolling,  comfort",
          },
          {
            id: 1,
            text: "b) rolling, colourful, cultural, dining, comfort",
          },
          {
            id: 2,
            text: "c) colourful, cultural, dining, public, rolling",
          },
          {
            id: 3,
            text: "d) cultural, dining, rolling, dining, colourful",
          },
        ],
      },
    ],
  },
  /* Prospecto */
  {
    id: "20",
    menu: 2,
    title: "Prospecto",
    options: [
      { tutorial: "663b46afa35b6206065822cc" },
      { goldText: "prospecto" },
    ],
    exercices: [
      {
        adaptedSRC: true,
        font: "https://programme2014-20.interreg-central.eu/Content.Node/Leaflet-for-Tourists---English.png",
        title: "Ordena de manera lógica los siguientes fragmentos del leaflet para que pueda leerse correctamente. Para ello, deberás arrastrar cada imagen según el orden lógico de su estructura.",
        subtitle: `
        <h5>Título: <span>_________</span></h5>
        <h5>Plantear pregunta: <span>_________</span></h5>
        <h5>Plantear argumento: <span>_________</span></h5>
        <h5>Proponer solución: <span>_________</span></h5>
        <h5>Conciliar argumento: <span>_________</span></h5>
        `,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",
        list: [
          {
            id: 0,
            order: 3,
            text: "<img src='/images/prospecto/image0.png'/>",
          },
          {
            id: 1,
            order: 1,
            text: "<img src='/images/prospecto/image1.png'/>",
          },
          {
            id: 2,
            order: 4,
            text: "<img src='/images/prospecto/image2.png'/>",
          },
          {
            id: 3,
            order: 2,
            text: "<img src='/images/prospecto/image3.png'/>",
          },
          {
            id: 4,
            order: 0,
            text: "<img src='/images/prospecto/image4.png'/>",
          }
        ],
      },
      {
        adaptedSRC: true,
        font: "https://programme2014-20.interreg-central.eu/Content.Node/Leaflet-for-Tourists---English.png",
        title: "Selecciona la respuesta correcta (a, b, c, d): ¿Qué sinónimo sería el adecuado para cada término?",
        subtitle:
            `
          <h5>Sustainable</h5>
          <h5>Responsible</h5>
          <h5>Conscious</h5>
          <h5>Environment</h5>
          <h5>Safeguard</h5>
            `,
        title2: null,
        type: "abc",
        typeName: "Léxico",
        correct: 1,
        list: [
          {
            id: 0,
            text: "a) temporary,	outstanding, in control,  space, danger",
          },
          {
            id: 1,
            text: "b) continuous, in control aware, surroundings, protect",
          },
          {
            id: 2,
            text: "c) continuous, protective, awake, area, cover",
          },
          {
            id: 3,
            text: "d) maintain, protective, in view, space, protect",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://programme2014-20.interreg-central.eu/Content.Node/Leaflet-for-Tourists---English.png",
        title: "Selecciona la respuesta correcta (a, b, c, d). ¿En el siguiente texto de un leaflet, cuál sería la forma verbal más apropiada?",
        subtitle: `
        <p>We try to use less resources by <span>_________</span>.</p>
        <p>We <span>_________</span> all those positive and responsible attitudes at home when we go on holiday.</p>
        <p>We can <span>_________</span> our holiday and <span>_________</span> more responsible and caring towards nature and the culture of our chosen destination.</p>
        <p><span>_________</span> local cultures and nature and look for ways to reduce your use of resources.</p>
        `,
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 0,
        list: [
          {
            id: 0,
            text: "a) recycling, don’t leave, enjoy, be, respect",
          },
          {
            id: 1,
            text: "b) recycle, doesn’t leave, enjoying, being, respecting",
          },
          {
            id: 2,
            text: "c) recycled, hasn’t left, enjoy, be, respecting",
          },
          {
            id: 3,
            text: "d) recycling, haven’t left, enjoying, respect",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://venngage.com/templates/brochures/tokyo-gradient-professional-travel-tri-fold-brochure-3f1ed75a-3de0-4e44-b095-74350cd525d7",
        title: "Selecciona la respuesta correcta (a, b, c, d):  Escoge los conectores más apropiados para cada párrafo.",
        subtitle: `
        <div class="mb-10">  
          <h5>Choose your transport wisely.</h5>
          <p>Transportation is an integral part of modern society, facilitating mobility, trade, and connectivity on a global scale.<p>
        </div>  
        <div class="mb-10">  
          <p><span>_________</span>, the convenience and efficiency of traditional transportation methods often come at a significant cost to the environment. The detrimental effects of transport on our planet are undeniable, ranging from air and noise pollution to habitat destruction and climate change.</p>
        </div>
        <div class="mb-10">  
          <p><span>_________</span>, there are a variety of eco-friendly transportation options available that can help mitigate the negative impacts of transport on the environment, these are not yet used to the extent that they should be by the population.</p>
        </div>
        <div">  
          <p><span>_________</span>, public transportation systems, such as buses, trains, and subways, play a crucial role in reducing traffic congestion, lowering emissions, and promoting sustainable urban development.</p>
        </div>
        `,
        title2: null,
        type: "abc",
        typeName: "Recursos discursivos",
        correct: 1,
        list: [
          {
            id: 0,
            text: "a) However,  Although,  Firstly",
          },
          {
            id: 1,
            text: "b) Although,  Furthermore, However",
          },
          {
            id: 2,
            text: "c) However,  Furthermore,  secondly",
          },
          {
            id: 3,
            text: "d) Moreover, nonetheless, but",
          },
        ],
      },
    ],
  },
  /* Blog */
  {
    id: "21",
    menu: 2,
    title: "Blog",
    options: [
      { tutorial: "663b46afa35b6206065822c7" },
      { goldText: "blog" },
    ],
    exercices: [
      {
        adaptedSRC: true,
        font: "https://programme2014-20.interreg-central.eu/Content.Node/Leaflet-for-Tourists---English.png",
        title: "Ordena de manera lógica los siguientes fragmentos del leaflet para que pueda leerse correctamente. Para ello, deberás arrastrar cada imagen según el orden lógico de su estructura.",
        subtitle: `
        <h5>1. Título del blog.</h5>
        <h5>2. Expectativas de la excursión.</h5>
        <h5>3. Lugares de compras encontrados.</h5>
        <h5>4. Restaurante para ese día.</h5>
        <h5>5. Conclusión sugerente del autor sobre su estancia y lo que debería hacerse el día siguiente. </h5>
        <h5>6. La principal visita cultural</h5>
        <h5>7. Expectativas de la excursión</h5>
        `,
        title2: null,
        type: "drag",
        typeName: "Estructura y contenido semántico prototípico",
        list: [
          {
            id: 0,
            order: 1,
            text: "We were expecting to find boutiques, boutique hotels, and cafes clustered around a few intersections. Instead, well, we did a lot of walking, sometimes back and forth down the same streets looking for places that didn't necessarily exist.",
          },
          {
            id: 1,
            order: 2,
            text: "Around the corner, we found brightly colored, polka-dotted trench coats at <strong>Sabrina Rey</strong> and octopus-embellished gold jewelry at the spanking new <strong>Sangre de Mi Sangre</strong> boutique.",
          },
          {
            id: 2,
            order: 3,
            text: "No disappointment can survive a good meal, so shortly after 3 p.m., we tumbled into Fonda el Refugio (Liverpool 166",
          },
          {
            id: 3,
            order: 0,
            text: "<h4>MEXICO CITY – This isn't much of a walking city. Day 2</h4>",
          },
          {
            id: 4,
            order: 5,
            text: "Our first destination in Roma was <strong>El Museo del Objeto del Objeto</strong>, recommended by Fathom contributor Rima Suqi, who wrote about it for the New York Times. The museum founder, businessman Bruno Newman, has amassed an immense collection of consumer goods over the course of his life, and he converted this beautiful Art Nouveau building into a temple of stuff.",
          },
          {
            id: 5,
            order: 4,
            text: "<div>On a funkier note, we were totally charmed by Red Tree House, a vibrant and friendly bed and breakfast where rooms run less than $100 a night.More on hanging out with Mexicans tomorrow.</div>",
          },
          {
            id: 6,
            order: 6,
            text: "What should have been a leisurely 45-minute stroll took two hours, even with my detailed map (okay, my two detailed maps)",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://fathomaway.com/mexico-city-first-impressions-2/",
        title: "Selecciona la opción correcta (a, b, c, d) para la forma verbal (persona y tiempo) que se utiliza con más frecuencia en los dos primeros párrafos en el blog.",
        subtitle:
            `
            <div class="mb-10"><p>Unlike New York, London, Paris or Rome, Mexico City is vastly spread out, like Los Angeles. Julie and I set out to visit the neighborhoods I'd heard so much about: Roma (edgy, emerging, Williamsburg-like) and Condesa (residential, chic, boho like SoHo). We were expecting to find boutiques, boutique hotels, and cafes clustered around a few intersections. Instead, well, we did a lot of walking, sometimes back and forth down the same streets looking for places that didn't necessarily exist. I was frustrated because I wanted to see a lot in a little time, and for all that I read about Mexico City, I hadn't been warned about that the city was so diffuse.</p></div>
            <p>Our first destination in Roma was El Museo del Objeto del Objeto. The museum founder, businessman Bruno Newman, has amassed an immense collection of consumer goods over the course of his life, and he converted this beautiful Art Nouveau building into a temple of stuff. On display on the ground floor is a whimsical fraction of his collection, which ran the gamut from a copper, hand-crank washing machine to an Iron Maiden cassette tape. In the rest of the museum he showcases the collections of other obsessives. Exhibits rotate every few months; we saw pencils, vintage skateboards, men's sneakers, women's hats, old-timey Mexico postcards. A gallery filled with nothing but pencils: total nerdy joy</p>
            `,
        title2: null,
        type: "abc",
        typeName: "Léxico",
        correct: 1,
        list: [
          {
            id: 0,
            text: "a) Presente sSimple (1ra persona singular o plural)",
          },
          {
            id: 1,
            text: "b) Pasado simple. (1ra persona singular o plural)",
          },
          {
            id: 2,
            text: "c) Futuro (1ra persona singular o plural)",
          },
          {
            id: 3,
            text: "d) Condicional (1ra persona singular o plural)",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://fathomaway.com/mexico-city-first-impressions-2/",
        title: "Selecciona la opción correcta (a, b, c, d) para identificar el verbo modal que se utiliza en el blog “Mexico city first impressions” para dar apoyo a la opinión del autor y escoge el enunciado correspondiente en el que aparece.",
        subtitle: `This all sounds totally efficient, right? Nope. What should have been a leisurely 45-minute stroll took two hours, even with my detailed map (okay, my two detailed maps). It didn't help that street numbers are an every-ten-buildings afterthought. Then again, the hunt made the finds that much better, as at Chic by Accident, a mod furniture showroom that's the stuff set designers dream of. Don't look for a sign on the door. Just buzz, and they'll let you in. "That's Roma," the guy at Chic told me. "Sometimes, the good things are hidden."`,
        title2: null,
        type: "abc",
        typeName: "Gramática",
        correct: 0,
        list: [
          {
            id: 0,
            text: "a) The blogger includes the modal verb “should” suggesting what one should expect for a 45-minute stroll.",
          },
          {
            id: 1,
            text: "b) The blogger makes an obligation about what visitors must do.",
          },
          {
            id: 2,
            text: "c) The blogger makes a suggestion about what has to be done to visit Mexico.",
          },
          {
            id: 3,
            text: "d) The blogger makes a suggestion about what Mexicans can do.",
          },
        ],
      },
      {
        adaptedSRC: true,
        font: "https://venngage.com/templates/brochures/tokyo-gradient-professional-travel-tri-fold-brochure-3f1ed75a-3de0-4e44-b095-74350cd525d7",
        title: "Selecciona la opción correcta (a, b, c, d) para indicar el párrafo que se utiliza en el blog “Mexico city first impressions” para el gancho que introduce el autor para su próximo blog.",
        subtitle: null,
        title2: null,
        type: "abc",
        typeName: "Recursos discursivos",
        correct: 2,
        list: [
          {
            id: 0,
            text: "a) The blogger wonders whether this was a good topic for the blog as part of the concluding remarks.",
          },
          {
            id: 1,
            text: "b) The blogger does not have an opinion and therefore avoids mentioning what to do the next day.",
          },
          {
            id: 2,
            text: "c) The blogger concludes with a sentence with the topic of the upcoming blog: “More on hanging out with Mexicans tomorrow”.",
          },
          {
            id: 3,
            text: "d) The blogger makes a final suggestion about how to deal with Mexicans.",
          },
        ],
      },
    ],
  },
];
