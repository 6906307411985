import React from "react";

export default function Confetti() {
  return (
    <svg
      className="m-icon"
      fill="#74bb30"
      height="200px"
      width="200px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <g>
            <path d="M344.356,404.041c-6.431-6.43-230.164-230.163-236.398-236.396c-6.527-6.528-17.111-6.528-23.639,0 c-6.528,6.528-6.528,17.111,0,23.639l16.193,16.193L0.981,489.696c-4.659,13.211,8.096,25.987,21.324,21.324l282.22-99.532 l16.193,16.193c6.527,6.528,17.111,6.529,23.639,0C350.884,421.153,350.884,410.569,344.356,404.041z M44.126,467.874 l20.959-59.429l38.469,38.469L44.126,467.874z M138.506,434.589l-61.095-61.095l18.491-52.429l95.033,95.033L138.506,434.589z M225.887,403.771L108.228,286.112l18.491-52.429l151.597,151.598L225.887,403.771z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M384.71,127.29c-6.528-6.528-17.112-6.528-23.639,0L256.72,231.64c-6.528,6.528-6.528,17.111,0,23.639 c6.527,6.528,17.111,6.529,23.639,0l104.35-104.35C391.238,144.401,391.238,133.818,384.71,127.29z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M367.318,280.947h-58.422c-9.232,0-16.716,7.484-16.716,16.716s7.484,16.716,16.716,16.716h58.422 c9.232,0,16.716-7.484,16.716-16.716S376.549,280.947,367.318,280.947z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M214.337,127.966c-9.232,0-16.716,7.483-16.716,16.716v58.422c0,9.232,7.484,16.716,16.716,16.716 s16.716-7.484,16.716-16.716v-58.422C231.053,135.451,223.569,127.966,214.337,127.966z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M497.336,262.203c-19.6-19.6-51.318-19.601-70.919,0c-19.552,19.552-19.552,51.366,0,70.918 c19.597,19.597,51.318,19.601,70.918,0C516.888,313.569,516.888,281.756,497.336,262.203z M473.697,309.482 c-6.532,6.532-17.106,6.534-23.639,0c-6.517-6.518-6.517-17.122,0-23.639c6.535-6.534,17.106-6.534,23.639,0 C480.23,292.377,480.229,302.948,473.697,309.482z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M249.797,14.664c-19.552-19.552-51.366-19.553-70.918,0c-19.597,19.597-19.601,51.318,0,70.919 c19.549,19.551,51.365,19.553,70.918-0.001C269.395,65.984,269.398,34.264,249.797,14.664z M226.156,61.943 c-6.517,6.517-17.121,6.517-23.639,0c-6.531-6.533-6.532-17.105,0-23.639c6.535-6.534,17.106-6.534,23.639,0 C232.69,44.837,232.69,55.408,226.156,61.943z"></path>
          </g>
        </g>
        <g>
          <g>
            <circle cx="427.097" cy="203.107" r="16.716"></circle>
          </g>
        </g>
        <g>
          <g>
            <circle cx="308.894" cy="84.905" r="16.716"></circle>
          </g>
        </g>
        <g>
          <g>
            <circle cx="427.097" cy="392.217" r="16.716"></circle>
          </g>
        </g>
        <g>
          <g>
            <circle cx="119.773" cy="84.905" r="16.716"></circle>
          </g>
        </g>
        <g>
          <g>
            <path d="M443.809,1.329c-9.232,0-16.716,7.484-16.716,16.716V34.76c0,9.232,7.484,16.716,16.716,16.716 s16.716-7.484,16.716-16.716V18.044C460.525,8.813,453.04,1.329,443.809,1.329z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M443.809,84.907c-9.232,0-16.716,7.484-16.716,16.716v16.716c0,9.232,7.484,16.716,16.716,16.716 s16.716-7.484,16.716-16.716v-16.716C460.525,92.391,453.04,84.907,443.809,84.907z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M410.378,51.475h-16.716c-9.232,0-16.716,7.484-16.716,16.716s7.484,16.716,16.716,16.716h16.716 c9.232,0,16.716-7.484,16.716-16.716S419.609,51.475,410.378,51.475z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M493.956,51.475H477.24c-9.232,0-16.716,7.484-16.716,16.716s7.484,16.716,16.716,16.716h16.716 c9.232,0,16.716-7.484,16.716-16.716S503.187,51.475,493.956,51.475z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
