import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import User from "../icons/user.jsx";
import ArrowL from "../icons/arrowL.jsx";
import "../style/header.css";
import InstallPWA from "./InstallPWA.jsx";
import {removeUser} from "../utils/authUtils.js"
import { AuthContext } from "../context/AuthContext";

const Header = (props) => {
  const { userLog } = useContext(AuthContext);

  const navigate = useNavigate();
  const handleBackToSubmenu = () => {
    navigate(-1); // Navigate back
  };  

  const isLocalhost = window.location.hostname === "localhost";

  return (
    <div className="m-header">
      <div className="mdl-container">
        {isLocalhost ? null : <InstallPWA />}
        {props.init ? (
          <Link to="/" className="m-title-header">RE-Write</Link>
        ) : (
          <div className="m-back" onClick={handleBackToSubmenu}>
            <ArrowL />
            <div>Atrás</div>
          </div>
        )}
        <div className={`profile-icon ${userLog ? 'show': 'hide'}`}>
          <User />
          <div className="dropdown">
            <Link className="profile" to="/profile">
              Mi perfil
            </Link>
            <div className="logout" onClick={() => removeUser()}>
              Cerrar sesión
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
