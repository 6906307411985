import React, { useEffect, useState } from "react";
import ProgressBar from "./ProgressBar.jsx";
import Counter from "./Counter.jsx";
import { getCoursesInfoUser } from "../service/courses.servide.js";
import { getLocalUser } from "../utils/authUtils.js";
import "../style/profilePage.css";
import { data as exercisesData } from "../data/data.jsx";
import ReactGA from 'react-ga4';

export default function ProfilePage(props) {
  const [userCoursesData, setUserCoursesData] = useState({
    totalCourses: 0,
    failures: 0,
    successes: 0,
  });
  const [userName, setsetUserName] = useState("");
  const [progress, setProgress] = useState(0);

  const { name } = getLocalUser();
  
  useEffect(() => {
    props.setInit(false);
    getUserCourseData();
    setsetUserName(name);

    ReactGA.event({
      category: 'Usuario',
      action: 'Accede a personal profile',
    });
  }, [name,props]);

  const getUserCourseData = async () => {
    const { userId } = getLocalUser();
    const { data } = await getCoursesInfoUser(userId);
    setUserCoursesData(data);

    let totalExercises = 0;
    exercisesData.forEach((element) => {
      totalExercises += element.exercices.length;
    });
    
    setProgress((data.successes * 100) / totalExercises);
  };

  return (
    <div className="m-profile">
      <div className="m-title">
        Hola, <span id="name">{userName}</span>,
      </div>
      <div className="m-subtitle">Aquí tienes un resumen de tus avances</div>
      <div className="m-awards">
        <div className="m-awards-items">
          <div className="m-award">
            <Counter
              time={500}
              num={userCoursesData.totalCourses}
              id={"counter1"}
              initCounters={props.initCounters}
              setLoaded={props.setLoaded}
            />

            <div className="m-text">actividades</div>
          </div>
          <div className="m-award">
            <Counter
              time={500}
              num={userCoursesData.successes}
              id={"counter2"}
              initCounters={props.initCounters}
              setLoaded={props.setLoaded}
            />

            <div className="m-text">aciertos</div>
          </div>
          <div className="m-award">
            <Counter
              time={500}
              num={userCoursesData.failures}
              id={"counter3"}
              initCounters={props.initCounters}
              setLoaded={props.setLoaded}
            />
            <div className="m-text">errores</div>
          </div>
        </div>
        <div className="m-awards-progress">
          <div className="m-subtitle">¡Felicidades! Has conseguido estas medallas:</div>
          <div className="m-progress">
            <img className="m-icon" src="/images/medal.svg" alt="medal" />
            <ProgressBar
              progress={progress}
              time={500}
              initCounters={props.initCounters}
              setLoaded={props.setLoaded}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
