import React, { useEffect } from "react";
import Selector from "./Selector.jsx";
import "../style/profilePage.css";
import ReactGA from "react-ga4";

export default function ExerciseOptions({ el: ExerciseOptions }) {

  useEffect(() => {
    ReactGA.event({
      category: "Usuario",
      action: `Accede a página: ${ExerciseOptions.title}`,
    });
  }, [ExerciseOptions.title]);
  
  return (
    <div className="m-selectors">
      <div className="m-breadcrumb">
        <div className="m-genre center">{ExerciseOptions.title}</div>
      </div>
      {ExerciseOptions.options.map((ex, i) => {
        const keyValue = Object.keys(ex)[0];
        let text = "";

        switch (keyValue) {
          case "tutorial":
            text = "Videotutorial";
            return (
              <Selector
                key={i}
                id={`sel-${i + 1}`}
                title={text}
                path={`/tutorial/${ExerciseOptions.id}`}
              />
            );
            break;
            case "goldText":
            text = "Texto de ejemplo";
            return (
              <Selector
                idPdf={true}
                key={i}
                id={`sel-${i + 1}`}
                title={text}
                path={`/pdf/${ex.goldText}.pdf`}
              />
            );
            break;
          
          default:
            return null;
        }
      })}

      <Selector
        key={ExerciseOptions.id}
        id={`sel-${ExerciseOptions.id}`}
        title="Actividades"
        path={`/exercices/submenu${ExerciseOptions.id}`}
      />
    </div>
  );
}
