import React, { Component } from "react";
import { Link } from "react-router-dom";
import Result from "./Result.jsx";
import "../style/exercices.css";
import { success, fail } from "../utils/coursesUtils.js";

class ExPopups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.el.text,
      listOptions: this.props.el.list,
      showPopup: false,
      activeMissingWord: null,
      result: false,
      finished: false,
    };
  }

  componentDidMount() {
    this.convertText(this.state.text);
  }

  convertText = (text) => {
    let idCounter = 1;
    const formattedText = text?.replace(/_______/g, () => {
      const spanId = `missingWord${idCounter}`;
      idCounter++;
      return `<span
        id="${spanId}"
        class="m-missingWord ${this.state.result ? "m-correct" : ""}"
      >
        _______
      </span>`;
    });

    this.setState({ formattedText: formattedText });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showPopup !== this.props.showPopup) {
      this.addSpanOnClicks();
    }
  }

  addSpanOnClicks = () => {
    const missingArray = document.getElementsByClassName("m-missingWord");
    Array.from(missingArray).forEach((span, i) => {
      span.addEventListener("click", this.showPopupMissingWord);
    });
  };

  showPopupMissingWord = (e) => {
    let id = parseInt(e.target.id.replace("missingWord", ""));
    this.setState({ showPopup: true, activeMissingWord: id });
  };

  hidePopupMissingWord = (selectedOption) => {
    const { activeMissingWord } = this.state;
    const updatedList = [...this.props.el.list];
    updatedList[activeMissingWord - 1].selected = selectedOption;

    // Fill the corresponding <span> with the selected word
    const missingWordElement = document.getElementById(`missingWord${activeMissingWord}`);
    if (missingWordElement) {
      missingWordElement.textContent = selectedOption;
    }

    this.setState({
      showPopup: false,
      activeMissingWord: null,
      list: updatedList,
    });
  };

  checkResults = () => {
    const { list } = this.state;

    list.forEach((item, index) => {
      this.setState({ result: true });
      const missingWordElement = document.getElementById(`missingWord${index + 1}`);

      if (item.selected === item.correct) {
        missingWordElement?.classList.add("m-correct");
        success(this);
      } else {
        fail(this);
        missingWordElement?.classList.add("m-incorrect");
        this.setState({ result: false });
      }
    });

    const body = document.querySelector("#content");
    body.scrollTo(0, 0);
    this.setState({ finished: true });
  };

  render() {
    const { el, genre } = this.props;

    const { result, finished, showPopup, activeMissingWord } = this.state;

    const allOptionsSelected = el.list.every((item) => item.selected.trim() !== "");

    return (
      <div>
        {" "}
        <div className="m-breadcrumb">
          <div className="m-genre">{genre}</div>
          <div className="m-exTitle">{el.typeName}</div>
        </div>
        <div className={`m-exercice ${finished ? "finished" : ""}`} data-type="completeText">
          {finished && <Result result={result} />}
          {el.title && <div className="m-title" dangerouslySetInnerHTML={{ __html: el.title }}></div>}
          {el.subtitle && <div className="m-subtitle" dangerouslySetInnerHTML={{ __html: el.subtitle }}></div>}
          {el.title2 && <div className="m-title" dangerouslySetInnerHTML={{ __html: el.title2 }}></div>}
          <div className="m-text m-completeText" dangerouslySetInnerHTML={{ __html: this.state.formattedText }}></div>
          {el.font && (
            <div className="m-font">
              FUENTE DEL TEXTO{el.adaptedSRC && <span> (adaptado)</span>}:{" "}
              <a href={el.font} target="_blank" rel="noreferrer">
                {el.font}
              </a>
            </div>
          )}
          <div className="m-buttons">
            {finished ? (
              <Link to="/exercices">
                <button className="m-button" id="buttonBack">
                  <span>vuelve a las actividades</span>
                </button>
              </Link>
            ) : (
              <button className={`m-button ${allOptionsSelected ? "" : "disabled"}`} onClick={this.checkResults}>
                <span>Continúa</span>
              </button>
            )}
          </div>
          {showPopup && (
            <div className="m-popupOptions">
              <div className="m-modal">
                <ol className="m-modalOptions" type="a">
                  {el.list[activeMissingWord - 1].options.split(", ").map((option, index) => (
                    <li key={index} onClick={() => this.hidePopupMissingWord(option)} data-id-word={activeMissingWord}>
                      {option}
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ExPopups;
