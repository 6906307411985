import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import Result from "./Result.jsx";
import "../style/exercices.css";
import { success, fail } from "../utils/coursesUtils.js";

class ExDrag extends Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
    this.state = {
      list: this.props.el.list,
      result: null,
      finished: false,
      soundType: "",
    };
  }

  checkResults = () => {
    const correct = this.state.list.every((item, i) => i === item.order);

    this.setState({ result: correct, finished: true }, () => {
      // Después de actualizar el estado, llama a la función en función de result
      if (correct) {
        success(this);
      } else {
        fail(this);
      }

      // Resto de la lógica de checkResults
      this.state.list.forEach((item, i) => {
        if (i !== item.order) {
          item.resultClass = "m-incorrect";
        } else {
          item.resultClass = "m-correct";
        }
      });

      const body = document.querySelector("#content");
      body.scrollTo(0, 0);
    });
  };

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.soundType !== this.state.soundType) {
  //     // Actualiza la referencia al elemento de audio cuando cambia soundType
  //     this.audioRef.current.src = `/sounds/${this.state.soundType}`;
  //   }
  // }

  render() {
    const { el, genre } = this.props;
    const { list, result, finished } = this.state;
    return (
      <>
      
        <div className="m-breadcrumb">
          <div className="m-genre">{genre}</div>
          <div className="m-exTitle">{el.typeName}</div>
        </div>
        <div className={`m-exercice ${finished ? "finished" : ""}`} data-type="sort">
          {finished && <Result result={result} />}
          {el.title && <div className="m-title" dangerouslySetInnerHTML={{ __html: el.title }}></div>} 
          {el.subtitle && <div className="m-subtitle" dangerouslySetInnerHTML={{ __html: el.subtitle }}></div>}
          {el.title2 && <div className="m-title" dangerouslySetInnerHTML={{ __html: el.title2 }}></div>} 
          <div className="m-draggableOptions" id="draggableOptions">
            <ReactSortable list={list} setList={(newList) => this.setState({ list: newList })}>
              {list?.map((item) => (
                <div className={`m-option ${item.resultClass}`} data-id={item.id} data-correct={item.order} key={item.id} dangerouslySetInnerHTML={{ __html: item.text }}></div>
              ))}
            </ReactSortable>
          </div>
          {el.font && (
            <div className="m-font">
              <div>FUENTE DEL TEXTO{el.adaptedSRC && <span> (adaptado)</span>}: </div>
              <a href={el.font} target="_blank" rel="noreferrer">
                {el.font}
              </a>
            </div>
          )}
          <div className="m-buttons">
            {finished ? (
              <Link to="/exercices">
                <button className="m-button" id="buttonBack">
                  <span>vuelve a las actividades</span>
                </button>
              </Link>
            ) : (
              <button className="m-button" onClick={this.checkResults}>
                <span>Continúa</span>
              </button>
            )}
          </div>
          <audio ref={this.audioRef} src=""></audio>
        </div>
      </>
    );
  }
}

export default ExDrag;
