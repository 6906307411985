import React, { useEffect } from "react";
import Selector from "./Selector.jsx";
import "../style/initialPage.css";
import ReactGA from 'react-ga4';

const ExsPage = (props) => {
  
  const selectorElements = props.data.map((item, index) => {
    const id = `sel-${index + 1}`;
    const path = `excercie-${item.id}-options`; 
    return <Selector key={id} id={id} title={item.title} path={path}/>;
  });

  useEffect(() => {
    props.setInit(false);
  }, [props]);

  useEffect(() => {
    ReactGA.event({
      category: 'Usuario',
      action: `Accede a página: Géneros textuales`,
    });
  }, [])
  
  return (
    <div className="m-initialPage">
      <div className="m-title">Géneros textuales</div>
      <div className="m-selectors">{selectorElements}</div>
    </div>
  );
};

export default ExsPage;
