import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { signup } from "../service/auth.servide";
import ReactGA from 'react-ga4';

export const RegisterForm = () => {

  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [passRequisits, setPassRequisits] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const [postErrors, setPostErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    repeatPassword: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {

    event.preventDefault();

    if (formData.password !== formData.repeatPassword) {
      setPostErrors("Las contraseñas no coindicen");
    } else if (isChecked === false) {
      setPostErrors("Debes aceptar las políticas generales");
    } else {
      document.querySelector(".m-userform").classList.add("loading");

      try {
        await signup(formData);
        document.querySelector(".m-userform").classList.remove("loading");

        ReactGA.event({
          category: 'Usuario',
          action: 'Crear nuevo registro',
        });
        
        navigate("/login");
      } catch (e) {
        document.querySelector(".m-userform").classList.remove("loading");

        e.response && setPostErrors(e.response.data.message);
      }
    }
  };

  return (
    <div className="m-userform">
      <div className="m-title">Regístrate</div>
      <div className="m-subtitle">
        Rellena estos campos para registrarte en nuestra app
      </div>
      <form className="m-form main-form">
        <div className="m-input-group">
          <label>Nombre *</label>
          <input
            type="text"
            name="username"
            required
            placeholder="Escribe aquí tu nombre"
            value={formData.nombre}
            onChange={handleInputChange}
          />
        </div>
        <div className="m-input-group">
          <label>Email *</label>
          <input
            required
            type="email"
            name="email"
            placeholder="Escribe aquí tu email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        <div className="m-input-group">
          {" "}
          <div className="m-eye" onClick={togglePasswordVisibility}></div>
          <label>Password * <span className="prereq">(1 mayúscula y 6 letras)</span></label>
          <input
            required
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder={passRequisits ? "1 mayúscula y 6 letras" : "Escribe aquí tu contraseña"}
            value={formData.password}
            onChange={handleInputChange}
            onFocus={setPassRequisits}
          />
        </div>
        <div className="m-input-group">
          <div className="m-eye" onClick={togglePasswordVisibility}></div>{" "}
          <label>Repite tu password</label>
          <input
            required
            type={showPassword ? "text" : "password"}
            name="repeatPassword"
            placeholder={passRequisits ? "1 mayúscula y 6 letras" : "Escribe aquí tu contraseña"}
            value={formData.repeatPassword}
            onChange={handleInputChange}
          />
        </div>
        <div className="m-switch-group">
          <label
            className={`switch ${isChecked ? "checked" : ""}`}
            htmlFor="agreement"
          >
            <input
              type="checkbox"
              id="agreement"
              onChange={() => setIsChecked(!isChecked)}
              checked={isChecked}
              required
            />
            <span className="slider"></span>
          </label>

          <span>
            Acepto la
            <a
              target="_blank"
              rel="noreferrer"
              href="https://descargas.uned.es/publico/pdf/Aviso_Legal_UNED.pdf"
            >
              {" "}
              nota legal
            </a>
            ,{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://descargas.uned.es/publico/pdf/Politica_privacidad_UNED.pdf"
            >
              política de privacidad{" "}
            </a>
            y la
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.uned.es/universidad/inicio/informacion-cookies.html"
            >
              {" "}
              política de cookies
            </a>
          </span>
        </div>{" "}
        <div className="m-switch-group">
          <label
            className={`switch ${isChecked2 ? "checked" : ""}`}
            htmlFor="newsletter"
          >
            <input
              type="checkbox"
              id="newsletter"
              onChange={() => setIsChecked2(!isChecked2)}
              checked={isChecked2}
            />
            <span className="slider"></span>
          </label>{" "}
          <span>
            Acepto recibir información sobre promociones, novedades etc{" "}
          </span>
        </div>
        <div className="m-link">
        {postErrors && <div className="m-error">{postErrors}</div>}{" "}
          ¿Ya eres usuario de Rewrite?
          <Link to="/login"> Accede aquí</Link>
        </div>
        <button className="m-button" type="submit" onClick={handleSubmit}>
          Enviar
        </button>


      </form>
    </div>
  );
};
