import React, { useEffect, useState } from "react";
import { AppRouter } from "./routes/AppRouter.jsx";
import { data } from "./data/data.jsx";
import "./App.css";
import { AuthContext } from "./context/AuthContext.jsx";
import { getLocalUser } from "./utils/authUtils.js"
import ReactGA from "react-ga4";

function App() {
  ReactGA.initialize('G-4G4FMWKV51', { debug: true });

  let isLoged = getLocalUser();
  const [userLog, setUserLog] = useState(isLoged);

  useEffect(() => {
    setUserLog(isLoged);
  }, [])
  

  return (
    <div className="mdl-app">
      <AuthContext.Provider value={{userLog,setUserLog}}>
        <AppRouter data={data} />
      </AuthContext.Provider>
    </div>
  );
}

export default App;
