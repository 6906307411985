import React, { useEffect, useState } from "react";

export default function Counter(props) {
  const [currentCount, setCurrentCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const countTo = parseInt(props.num, 10);
    const time = parseInt(props.time, 10);
    
    const step = () => {
      const progress = Math.min(1, (Date.now() - start) / time);
      setCurrentCount(Math.floor(progress * countTo));

      if (progress < 1) {
        requestAnimationFrame(step);
      } else {
        props.setLoaded(true);
      }
    };

    requestAnimationFrame(() => {
      start = Date.now();
      step();
    });
  }, [props]);

  return (
    <span
      className="m-count"
      id={props.id}
      data-count={props.num}
      data-time={props.time}
    >
      {currentCount}
    </span>
  );
}
