import React, { useEffect, useState } from "react";

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  const hidePwaModal = () => {
    const elements = document.querySelectorAll(`.PWA-modal`);

    elements.forEach((element) => {
      element.style.display = 'none';
    });
  }

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }
  return (
    <div className="PWA-modal">
      <div className="container">
        <div className="close" onClick={() => hidePwaModal()}>x</div>
        <div className="title">
          Instala nuestra app para disfrutar de las ventajas
        </div>
        <button
          className="link-button"
          id="setup_button"
          aria-label="Install app"
          title="Install app"
          onClick={onClick}
        >
          Instalar Aplicación
        </button>
      </div>
    </div>
  );
};

export default InstallPWA;
