import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
/* Documentación: https://www.npmjs.com/package/react-pdf */
import { useParams } from "react-router-dom";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { data } from "../data/data";

export default function PdfViewer() {
  const { pdfId } = useParams();

  const { options: [{ goldText }] } = data.find(item => item.id === pdfId);

  const pdfUrl = `/pdf/${goldText}.pdf`;

  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const goToNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <div id="pdfContainer">
      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Página {pageNumber} de {numPages}
      </p>
      <div className="pdf-paginator">
        <div className="m-subtitle" onClick={goToPreviousPage}>
          Anterior
        </div>
        <div className="m-subtitle" onClick={goToNextPage}>
          Siguiente
        </div>
      </div>
    </div>
  );
}
