import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { login } from "../service/auth.servide";
import { AuthContext } from "../context/AuthContext";
import { saveUser } from "../utils/authUtils";
import Logo from "./Logo.jsx";
import ReactGA from 'react-ga4';
import "../style/userForms.css";

export const LoginForm = () => {
  const navigate = useNavigate();
  const [postErrors, setPostErrors] = useState(false);
  const { setUserLog } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [formData, setFormData] = useState({
    nombre: "",
    email: "",
    password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    document.querySelector('.m-userform').classList.add('loading');
    event.preventDefault();
    try {
      
      const { data } = await login(formData);
      document.querySelector('.m-userform').classList.remove('loading');

      const localStorage = {
        email: formData.email,
        name: data.userName,
        userId: data.token,
      };
      
      saveUser(localStorage);
      setUserLog(true);

      ReactGA.event({
        category: 'Usuario',
        action: 'Acceder a través de login',
      });

      navigate("/");
    } catch (e) {
      document.querySelector('.m-userform').classList.remove('loading');
      (e.response) && setPostErrors(e.response.data.message);
    }
  };

  return (
    <div className="m-userform">
      <Logo />
      <div className="m-title">Login</div>
      <div className="m-subtitle">¿Preparado para empezar?</div>
      <form className="m-form main-form" onSubmit={handleSubmit}>
        <div className="m-input-group">
          <label>Email *</label>
          <input
            required
            type="email"
            name="email"
            placeholder="Escribe aquí tu email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        <div className="m-input-group">
          {" "}
          <div className="m-eye" onClick={togglePasswordVisibility}></div>
          <label>Password *</label>
          <input
            required
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Escribe aquí tu contraseña"
            value={formData.password}
            onChange={handleInputChange}
          />
        </div>
        <div className="m-link">
          Si aún no tienes cuenta, por favor
          <Link to="/register"> Regístrate aquí</Link>
        </div>
        {postErrors && <div className="m-error">{postErrors}</div>}{" "}
        <button className="m-button" type="submit">
          Enviar
        </button>
      </form>
    </div>
  );
};
