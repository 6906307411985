import React from "react";
import { Link } from "react-router-dom";

export default function SelectorInit(props) {
  return (
    <Link className={`m-selectorInit ${props.img ? '' : 'no-icon'}`} id={props.id} to={props.path}>
      {props.img && (
        <div className="m-sel-icon" style={{ backgroundColor: props.color }}>
          <img src={`/images/` + props.img} alt="" />
        </div>
      )}
      <div className="m-sel-cont">
        <div className="m-title">{props.title}</div>
        <div className="m-text">{props.text}</div>
        <div className="m-link">{`Acceder ahora >>`}</div>
      </div>
    </Link>
  );
}
