import React, {useEffect} from "react";
import Selector from "./Selector.jsx";
import "../style/profilePage.css";
import ReactGA from 'react-ga4';

export default function Submenu({el: submenu}) {

  useEffect(() => {
    ReactGA.event({
      category: 'Usuario',
      action: `Accede a página: ${submenu.title}`,
    });
  }, [submenu.title])


  return (
    <div className="m-selectors">
      <div className="m-breadcrumb">
        <div className="m-genre center">{submenu.title}</div>
      </div>
      {submenu.exercices.map((ex, i) => (
        <Selector
          key={i}
          id={`sel-${i + 1}`}
          title={`${ex.typeName}`}
          path={`ex-${submenu.id}${i + 1}`}
         
        />
      ))}
    </div>
  );
}
