import React from 'react'
import { Link } from 'react-router-dom'

export const Error404 = () => {
  return (
    <section id="error-404">
        <div className="container">
            <img src="images/404image.png" alt=""/>
            <div className="title">
              Oops...<br></br>
              error 404
            </div>
            <div className="m-subtitle">Página no encontrada</div>
        </div>
        <Link className='m-button' to="/" relative="path">
          VOLVER AL INICIO
        </Link>
    </section>
  )
}
