import React, { useEffect } from "react";
import ReactGA from 'react-ga4';
import { useParams } from "react-router-dom";
import { data } from "../data/data.jsx";

export const Iframe = ({ page, setInit = false }) => {
  const { tutorialId } = useParams();
  
  if (tutorialId) {    
    const { options: [{ tutorial }] } = data.find(item => item.id === tutorialId);
    page = `https://canal.uned.es/iframe/${tutorial}`;
  }

  useEffect(() => {
    setInit(false);
    
    ReactGA.event({
      category: 'Usuario',
      action: `Accede a página: ${page}`,
    });

  }, []);

  return (
    <div className="iframe">
      <iframe
        title="uned-blog"
        loading="lazy"
        src={page}
        frameBorder="0"
        allowFullScreen 
      />
    </div>
  );
};
