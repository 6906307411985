import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProfilePage from "../components/ProfilePage.jsx";
import ExsPage from "../components/ExsPage.jsx";
import ContactPage from "../components/ContactPage.jsx";
import InitialPage from "../components/InitialPage.jsx";
import Header from "../components/Header.jsx";
import Submenu from "../components/Submenu.jsx";
import ExerciseOptions from "../components/ExerciseOptions.jsx";
import ExMultipleabc from "../components/ExMultipleabc.jsx";
import ExDrag from "../components/ExDrag.jsx";
import ExAbc from "../components/ExAbc.jsx";
import ExPopups from "../components/ExPopups.jsx";
import Ex1popup from "../components/Ex1popup.jsx";
import { RegisterForm } from "../components/RegisterForm.jsx";
import { LoginForm } from "../components/LoginForm.jsx";
import PrivateRoute from "./PrivateRoute.jsx";
import IsloggedRoute from "./IsloggedRoute.jsx";
import { Error404 } from "../components/Error404.jsx";
import { Iframe } from "../components/Iframe.jsx";
import PdfViewer from "../components/PdfViewer.jsx";
import ExerciseCatsPage from "../components/ExerciseCatsPage.jsx";

export const AppRouter = ({ data }) => {
  const [init, setInit] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  // Generos textuales
  const [menu1Data, setMenu1Data] = useState([]);
  // Géneros textuales orientados a la industria turística
  const [menu2Data, setMenu2Data] = useState([]);

  useEffect(() => {
    if (data) {
      setJsonData(data);
      const menu1 = data.filter((item) => item.menu === 1);
      const menu2 = data.filter((item) => item.menu === 2);
      setMenu1Data(menu1);
      setMenu2Data(menu2);
    }
  }, [data]);

  return (
    <BrowserRouter>
      <Header init={init} setInit={setInit} />
      <div className="m-content" id="content">
        <div className="mdl-container">
          <Routes>
            {/* Rutas de logeo, si esta logeado redirige a / */}
            <Route exact path="/login" element={<IsloggedRoute />}>
              <Route
                path="/login"
                element={<LoginForm init={init} setInit={setInit} />}
              />
            </Route>
            <Route exact path="/register" element={<IsloggedRoute />}>
              <Route
                path="/register"
                element={<RegisterForm init={init} setInit={setInit} />}
              />
            </Route>

            {/* Rutas privadas, si no esta logeado redirige a /login */}
            {jsonData &&
              menu1Data.map((el, i) => (
                <React.Fragment key={i}>
                  <Route
                    key={el.id}
                    path={`/exercices/excercie-${el.id}-options`}
                    element={getComponentElements("ExerciseOptions", el)}
                    init={init}
                    setInit={setInit}
                  />
                  <Route
                    key={el.id}
                    path={`/exercices/submenu${el.id}`}
                    element={getComponentElements("Submenu", el)}
                    init={init}
                    setInit={setInit}
                  />
                  {el.exercices.map((e, j) => (
                    <Route
                      key={`ex-${el.id}${j + 1}`}
                      path={`/exercices/submenu${el.id}/ex-${el.id}${j + 1}`}
                      element={getComponentElements(
                        "Ex" + e.type.charAt(0).toUpperCase() + e.type.slice(1),
                        e,
                        el.title
                      )}
                      init={init}
                      setInit={setInit}
                    />
                  ))}
                </React.Fragment>
              ))}
            {jsonData &&
              /* exercices/submenu y exercices/submenu se ha nde respetar */
              /* El primer componente route si se puede modificar */
              menu2Data.map((el, i) => (
                <React.Fragment key={i}>
                  <Route
                    key={el.id}
                    path={`/exercices-turism/excercie-${el.id}-options`}
                    element={getComponentElements("ExerciseOptions", el)}
                    init={init}
                    setInit={setInit}
                  />
                  <Route
                    key={el.id}
                    path={`/exercices/submenu${el.id}`}
                    element={getComponentElements("Submenu", el)}
                    init={init}
                    setInit={setInit}
                  />
                  {el.exercices.map((e, j) => (
                    <Route
                      key={`ex-${el.id}${j + 1}`}
                      path={`/exercices/submenu${el.id}/ex-${el.id}${j + 1}`}
                      element={getComponentElements(
                        "Ex" + e.type.charAt(0).toUpperCase() + e.type.slice(1),
                        e,
                        el.title
                      )}
                      init={init}
                      setInit={setInit}
                    />
                  ))}
                </React.Fragment>
              ))}
            <Route
              path="/uned-blog"
              element={
                <Iframe
                  page="https://blogs.uned.es/herramientasautocorreccionescrituraingles/"
                  setInit={setInit}
                />
              }
            />
            <Route exact path="/uned-contacto" element={<PrivateRoute />}>
              {/* <Iframe
                  page="https://blogs.uned.es/herramientasautocorreccionescrituraingles/contacto/"
                  setInit={setInit}
                /> */}
              <Route
                path="/uned-contacto"
                element={
                  <ContactPage
                    init={init}
                    setInit={setInit}
                    loaded={loaded}
                    setLoaded={setLoaded}
                  />
                }
              />
            </Route>

            <Route exact path="/profile" element={<PrivateRoute />}>
              <Route
                path="/profile"
                element={
                  <ProfilePage
                    init={init}
                    setInit={setInit}
                    loaded={loaded}
                    setLoaded={setLoaded}
                  />
                }
              />
            </Route>
            <Route exact path="/exercices/category/" element={<PrivateRoute />}>
              <Route
                path="/exercices/category/"
                element={<ExerciseCatsPage init={init} setInit={setInit} />}
              />
            </Route>
            <Route exact path="/exercices" element={<PrivateRoute />}>
              <Route
                path="/exercices"
                element={
                  <ExsPage init={init} setInit={setInit} data={menu1Data} />
                }
              />
            </Route>
            <Route exact path="/exercices-turism" element={<PrivateRoute />}>
              <Route
                path="/exercices-turism"
                element={
                  <ExsPage init={init} setInit={setInit} data={menu2Data} />
                }
              />
            </Route>
            <Route exact path="/" element={<PrivateRoute />}>
              <Route
                path="/"
                element={<InitialPage init={init} setInit={setInit} />}
              />
            </Route>

            <Route
              path="/tutorial/:tutorialId"
              element={<Iframe setInit={setInit} />}
            />
            <Route
              path="/texto-de-oro/:pdfId"
              element={<PdfViewer setInit={setInit} />}
            />

            <Route path="*" element={<Error404 />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
};

const getComponentElements = (componentName, el, genre) => {
  const Component = componentsMap[componentName];
  if (Component && el) {
    return (
      <React.Fragment>
        <Component el={el} genre={genre} />
      </React.Fragment>
    );
  }
};
//? Objeto con los componentes que vamos a tener en la web
const componentsMap = {
  Submenu,
  ExerciseOptions,
  ExDrag,
  ExAbc,
  ExMultipleabc,
  ExPopups,
  Ex1popup,
};
