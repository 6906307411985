import React, { useEffect } from "react";
import SelectorInit from "./SelectorInit.jsx";
import "../style/initialPage.css";

const InitialPage = (props) => {
  useEffect(() => {
    props.setInit(true);
  }, [props]);
  return (
    <div className="m-initialPage">
      <div className="m-title">¡Te damos la bienvenida a RE-Write!</div>
      <div className="m-selectors">
        <SelectorInit
          id="sel-1"
          m-sel-cont
          title="Géneros textuales"
          text="Aprende con estos géneros textuales, de forma autodidacta"
          color="#48935D"
          img="exercices.png"
          path="/exercices/category/"
        />
        {/* !Aquest selector ha de portar al blog embebido */}
        <SelectorInit
          id="sel-2"
          title="Resuelve tus dudas"
          text="Autocorrige tus textos en inglés con herramientas en línea y de acceso gratuito"
          color="#1B6E70"
          img="dudas.png"
          path="/uned-blog"
        />
        {/* !Aquest selector ha de portar a un form de contacte amb només el contacte de la Silvia? */}
        <SelectorInit
          id="sel-2"
          title="Contáctanos"
          text="Para más información, puedes contactar con nosotros"
          img="logo.png"
          path="/uned-contacto"
        />
      </div>
    </div>
  );
};

export default InitialPage;
