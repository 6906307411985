export function getLocalUser() {
    let user = localStorage.getItem("user");
    return user = JSON.parse(user);
}

export function saveUser(user) {
    const strgyfiedUser = JSON.stringify({ ...user, isLogged: true });
    localStorage.setItem("user", strgyfiedUser);
}

export function removeUser() {
    localStorage.removeItem("user");
    window.location.href = '/login';
}