import { getLocalUser } from "./authUtils";
import { getLastPartOfURL } from "./globalUtils.js";
import { addCourse } from "../service/courses.servide";
import ReactGA from 'react-ga4';

export const success = async (component) => {
  const { userId } = getLocalUser();

  const exercice = {
    exercice: getLastPartOfURL(),
    successes: 1,
    failures: 0,
    userId: userId,
  };

  ReactGA.event({
    category: 'Usuario',
    action: `Usuario a superado un ejercicio`,
  });

  await addCourse(exercice)

  .catch((error) => {
    console.error(error);
  });
  
};

export const fail = async (component) => {
  const { userId } = getLocalUser();

  const exercice = {
    exercice: getLastPartOfURL(),
    successes: 0,
    failures: 1,
    userId: userId,
  };

  ReactGA.event({
    category: 'Usuario',
    action: `Usuario a fallado un ejercicio`,
  });

  await addCourse(exercice)

  .catch((error) => {
    console.error(error);
  });

};

//? nos piden que lo quitemos
/* export const reproducirSonido = (component) => {
  const audioElement = component.audioRef.current;

  audioElement.oncanplaythrough = () => {
    audioElement.play();
  };

  if (audioElement.readyState >= 2) {
    audioElement.play();
  }
}; */
