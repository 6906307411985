import axios from "axios";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const authApi = axios.create({
  baseURL: `${apiEndpoint}api-course`,
  /* withCredentials: true, */
});

export const getCoursesInfoUser = (userId) => authApi.get(`/courses-info/${userId}`);

export const getCoursesProgress = (userId) => authApi.get(`/courses-progress/${userId}`);

export const addCourse = (courseInfo) => authApi.post(`/add-course/`, courseInfo);