import React, { useState, useEffect } from "react";

const ProgressBar = ({ progress, time }) => {
  const [completedWidth, setCompletedWidth] = useState(0);
  const [textClass, setTextClass] = useState("");

  const initBar = (e) => {
    let startTime;

    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsedTime = timestamp - startTime;
      const progressWidth = (elapsedTime / time) * 100;
      setCompletedWidth(progressWidth);

      if (progressWidth < progress) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);

    if (progress > 55) {
      setTextClass("white");
    } else if (progress > 44) {
      setTextClass("dark");
    }

    return () => cancelAnimationFrame(step);
  };

  useEffect(() => {
    initBar();
  }, [progress, time]);

  return (
    <div className="m-progress-bar" data-progress={progress} data-time={time}>
      <div
        className="m-progress-completed"
        style={{ width: `${completedWidth}%` }}
      ></div>
      <div className={`m-progress-counter ${textClass}`}>
        <span>{Math.floor(completedWidth)}</span>/100
      </div>
      <div className="nota">NOTA: Las medallas se calculan teniendo en cuenta la siguiente fórmula: total de aciertos * 100 / total de ejercicios</div>
    </div>
  );
};

export default ProgressBar;
