import React, { Component } from "react";
import { Link } from "react-router-dom";
import Result from "./Result.jsx";
import "../style/exercices.css";
import { success, fail } from "../utils/coursesUtils.js";

class ExMultipleabc extends Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();

    this.state = {
      exercices: this.props.el.exercices,
      result: false,
      finished: false,
      allOptionsSelected: false,
      showButton: false,
      soundType: "false",
    };
  }

  setActiveOption = (exID, selectedID) => {
    const updatedExercices = [...this.state.exercices];
    updatedExercices[exID].selected = selectedID;
    this.setState({ exercices: updatedExercices });

    // Si todos los ejercicios tienen opcion selecionada activa button
    const allOptionsSelected = updatedExercices?.every((ex) => ex.selected !== undefined);
    if (allOptionsSelected) {
      this.setState({ allOptionsSelected: true });
    }
  };

  checkResults = () => {
    const result = this.state.exercices?.every((ex) => ex.correct.includes(ex.selected));
    this.setState({ result, finished: true }, () => {
      // Después de actualizar el estado, llamar a la función en función de result
      if (result) {
        success(this);
      } else {
        fail(this);
      }

      const body = document.querySelector("#content");
      body.scrollTo(0, 0);
    });
  };
  componentDidMount() {
    // Reinicia selected
    const newExercices = this.state.exercices.map((ex) => ({
      ...ex,
      selected: null,
    }));
    this.setState({ exercices: newExercices });
  }
  // componentDidUpdate(prevState) {
  //   if (prevState.soundType !== this.state.soundType) {
  //     // Actualiza la referencia al elemento de audio cuando cambia soundType
  //     this.audioRef.current.src = `/sounds/${this.state.soundType}`;
  //   }
  // }

  render() {
    const { el, genre } = this.props;
    const { exercices, result, finished, allOptionsSelected } = this.state;

    return (
      <div>
        <div className="m-breadcrumb">
          <div className="m-genre">{genre}</div>
          <div className="m-exTitle">{el.typeName}</div>
        </div>{" "}
        <div className={`m-exercice ${finished ? "finished" : ""}`} data-type="multiple-abc">
          {finished && <Result result={result} />}
          {el.title && <div className="m-title" dangerouslySetInnerHTML={{ __html: el.title }}></div>}
          {el.subtitle && <div className="m-subtitle" dangerouslySetInnerHTML={{ __html: el.subtitle }}></div>}
          {el.title2 && <div className="m-title" dangerouslySetInnerHTML={{ __html: el.title2 }}></div>}
          <div className="m-abcMultipleOptions" id="abcMultipleOptions">
            {exercices?.map((ex, i) => (
              <div className={`m-abc-group`} key={ex.id}>
                <div className="m-abc-title">
                  {ex.id}. {ex.title}
                </div>
                <div className="m-abc-options">
                  {ex.list?.map((item, j) => (
                    <div
                      className={`m-option ${item.id === ex.selected && " active "}
                      ${finished && item.id === ex.selected && ex.correct.includes(ex.selected) ? "m-correct" : ""} ${!result && item.id === ex.selected && !ex.correct.includes(ex.selected) ? "m-incorrect" : ""}
                      `}
                      id={`option` + item.id}
                      key={item.id}
                      onClick={() => this.setActiveOption(i, j)}
                    >
                      {item.text}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {el.font && (
            <div class="m-font">
              FUENTE DEL TEXTO{el.adaptedSRC && <span> (adaptado)</span>}:{" "}
              <a href={el.font} target="_blank" rel="noreferrer">
                {el.font}
              </a>
            </div>
          )}
          <div className="m-buttons">
            {finished ? (
              <Link to="/exercices">
                <button className="m-button" id="buttonBack">
                  <span>vuelve a las actividades</span>
                </button>
              </Link>
            ) : (
              <button className={`m-button ${allOptionsSelected ? "" : "disabled"}`} onClick={this.checkResults}>
                <span>Continúa</span>
              </button>
            )}
          </div>
          <audio ref={this.audioRef} src=""></audio>
        </div>
      </div>
    );
  }
}

export default ExMultipleabc;
