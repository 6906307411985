import React from "react";
import Confetti from "../icons/confetti.jsx";
import Wink from "../icons/wink.jsx";

const Result = (props) => {
  const resultClass = props.result ? "m-correct" : "m-incorrect";
  const resultText = props.result
    ? "¡Felicidades! la respuesta ha sido acertada"
    : "¡Oh! Tu respuesta no ha sido la más acertada. ¿Quieres volver a intentarlo?";

  return (
    <div className={`m-result ${resultClass}`}>
      <div className="m-result-txt">{resultText}</div>
      {props.result ? <Confetti /> : <Wink />}
    </div>
  );
};
export default Result;
