import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OpenBook from "../icons/openbook.jsx";
import ArrowR from "../icons/arrowR.jsx";
import { getCoursesProgress } from "../service/courses.servide.js";
import { getLocalUser } from "../utils/authUtils.js";

export default function Selector(props) {
  const [exerciceState, setExerciceState] = useState('');

  const getUserCourseData = async () => {
    const { userId } = getLocalUser();
    const { data } = await getCoursesProgress(userId);

    const currentExercice = data.exercisesInfo.find((elemento) => elemento.id === props.path);

    if (currentExercice) {
      (currentExercice.successes >= 1) ? setExerciceState('green') : setExerciceState('red');
    } else {

    }
  };

  useEffect(() => {
    getUserCourseData();
  }, []);
  
  return (
    <Link target={(props.idPdf) ? "_blank" : "_self"} className={`m-selector ${exerciceState}`} data-ex={props.path} id={props.id} to={props.path}>
      <div className="m-select-text">
        <OpenBook />
        <div>{props.title}</div>
      </div>
      <ArrowR />
    </Link>
  );
}
