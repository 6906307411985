import React, { Component } from "react";
import { Link } from "react-router-dom";
import Result from "./Result.jsx";
import "../style/exercices.css";
import { success, fail } from "../utils/coursesUtils.js";

class ExAbc extends Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();

    this.state = {
      list: this.props.el.list,
      correctOption: this.props.el.correct,
      result: false,
      finished: false,
      activeOptionId: null,
      showButton: false,
      soundType: "false",
    };
  }

  setActiveOption = (optionId) => {
    this.setState({ activeOptionId: optionId });
    this.setState({ showButton: true });
  };

  checkResults = () => {
    const correctOption = this.state.correctOption;
    const activeOptionId = this.state.activeOptionId;

    const result = correctOption === activeOptionId;

    this.setState({ result, finished: true }, () => {
      // Después de actualizar el estado, llamar a la función en función de result
      if (result) {
        success(this);
      } else {
        fail(this);
      }

      // Resto de la lógica de checkResults
      this.state.list.forEach((item, i) => {
        if (i === activeOptionId) {
          item.resultClass = "m-incorrect";
        } else {
          item.resultClass = "m-correct";
        }
      });

      const body = document.querySelector("#content");
      body.scrollTo(0, 0);
    });
  };

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.soundType !== this.state.soundType) {
  //     // Actualiza la referencia al elemento de audio cuando cambia soundType
  //     this.audioRef.current.src = `/sounds/${this.state.soundType}`;
  //   }
  // }

  render() {
    const { el, genre } = this.props;

    const { list, result, finished, correctOption, activeOptionId, showButton } = this.state;

    return (
      <div>
        <div className="m-breadcrumb">
          <div className="m-genre">{genre}</div>
          <div className="m-exTitle">{el.typeName}</div>
        </div>
        <div className={`m-exercice ${finished ? "finished" : ""}`} data-type="abc">
          {finished && <Result result={result} />}
          {el.title && <div className="m-title" dangerouslySetInnerHTML={{ __html: el.title }}></div>} {el.subtitle && <div className="m-subtitle" dangerouslySetInnerHTML={{ __html: el.subtitle }}></div>}
          {el.title2 && <div className="m-title" dangerouslySetInnerHTML={{ __html: el.title2 }}></div>}
          <div className="m-abcOptions" id="abcOptions" data-correct={correctOption}>
            {list?.map((item) => (
              <div className={`m-option ${item.id === activeOptionId && " active "} ${finished && item.id === correctOption ? "m-correct" : ""} ${!result && item.id === activeOptionId ? "m-incorrect" : ""}`} id={`option` + item.id} key={item.id} onClick={() => this.setActiveOption(item.id)} dangerouslySetInnerHTML={{ __html: item.text }}>
                {/* {item.text} */}
              </div>
            ))}
          </div>
          {el.font && (
            <div className="m-font">
              FUENTE DEL TEXTO{el.adaptedSRC && <span> (adaptado)</span>}:{" "}
              <a href={el.font} target="_blank" rel="noreferrer">
                {el.font}
              </a>
            </div>
          )}
          <div className="m-buttons">
            {finished ? (
              <Link to="/exercices">
                <button className="m-button" id="buttonBack">
                  <span>vuelve a las actividades</span>
                </button>
              </Link>
            ) : (
              <button className={`m-button ${!showButton && "disabled"}`} onClick={this.checkResults}>
                <span>Continúa</span>
              </button>
            )}
          </div>
          <audio ref={this.audioRef} src=""></audio>
        </div>
      </div>
    );
  }
}

export default ExAbc;
